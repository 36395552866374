import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Link, TextField } from '@mui/material';
import axios from 'axios';
import jwt from "jwt-decode";
import { getToken } from '../../../redux/Actions/Actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
// import { setLanguage } from "../../../redux/Actions/languageActions";

import img1 from "../../../images/111111111111/11.svg";
import img2 from "../../../images/111111111111/22.svg";
import img3 from "../../../images/111111111111/33.svg";
import img4 from "../../../images/111111111111/44.svg";
import img5 from "../../../images/111111111111/55.svg";

import Logo from "../../../assets/home/logo.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import ColorTextField from '../../atoms/TextFields/ColorTextField';
import CommonDesign from '../../atoms/CommonDesign';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { EndAdornmentTextField } from '../../atoms/TextFields/AdornmentTextField';
import { localstore } from '../../../localStore/localStore';
import URL, { PasswordpublicKey } from '../../../utility/apiurl';
import JSEncrypt from 'jsencrypt';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function Login() {
    const location = useLocation()
    const patientIbmId = location?.state?.patientIbmId
    // console.log(patientIbmId, "patientibmid")
    const images = [img1, img2, img3, img4, img5];
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [alertopen, setAlertopen] = useState(false);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [value, setValue] = useState(0);
    const [containerImage, setContainerImage] = useState(images[0]);
    const [t, i18n] = useTranslation("global");
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
    const token = localstore.getToken()
    const navigate = useNavigate()
    const handleChangeUsername = (e) => {
        setUsername(e.target.value)
        setAlertMessage('')
    }
    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleSubmit = async () => {
        // // setPid(pid)
        // axios.post(`https://devfhir.docsteth.com/fhir-server/api/v4/Patient`, { 'resourceType': 'Patient' }).then(res => {
        //     console.log(res.headers, "headerss")
        //     console.log(res, "headerss")
        // })
        setLoading(true);
        const publicKey = PasswordpublicKey;

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        const encryptusername = encrypt.encrypt(`${username}@mdx`);
        const encryptpassword = encrypt.encrypt(password);
        const data = {
            userName: encryptusername.replace(/\n/g, '').replace(/\r/g, ''),
            passWord: encryptpassword.replace(/\n/g, '').replace(/\r/g, '')
        }
        await axios.post(`${URL.login}`, data)
            .then((res) => {
                // console.log(JSON.stringify(res.data), "keyyyyy");
                const token = res.data.access_token;
                // dispatch(getToken(token));

                const user = jwt(token);
                const userid = user.sub
                const ibmid = user.ibmId
                handleLogin(token)
                // console.log(user, "user")
                localStorage.setItem('kctoken', token)
                localStorage.setItem('userId', userid)
                if (ibmid === undefined || ibmid === null) {
                    navigate('/completeprofile')
                } else {
                    const pid = user.patientId
                    let username = user.preferred_username;
                    let patientName = JSON.stringify(user.name);
                    const refreshtoken = res.data.refresh_token
                    localStorage.setItem('patientId', pid)
                    localStorage.setItem('patientName', patientName)
                    localStorage.setItem('primaryId', ibmid)
                    localStorage.setItem('primaryFixedid', ibmid);

                    setAlertstatus('success');
                    setAlertMessage('Successfully LogedIn!');
                    navigate('/')
                }

            }).catch(err => {
                if (err) {
                    console.log(err.message, 'login');
                    setLoading(false);
                    // if (err.error_description === 'Invalid user credentials') {
                    setAlertstatus('error');
                    setAlertMessage('Invalid username or password !');
                }
            }).finally(() => {
                setLoading(false)
            })
        setUsername('')
        setPassword('')

    }

    const handleLogin = (token) => {
        axios.get(` ${URL.medxpertsBaseApi}abdm/v3/ReqTokenId`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                console.log('Headers:', response.headers);
                const apireqid = response.headers["api-req-id"]
                localStorage.setItem('csrftoken', apireqid)

                axios.post(`${URL.medxpertsBaseApi}abdm/v3/Request`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-CSRF-TOKEN': apireqid
                    }
                }).then(res => console.log(res, "request idddd"))
            })
            .catch(error => {
                // Handle errors here
                console.error(error);
            });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setValue((v) => (v === 4 ? 0 : v + 1));
            setContainerImage(images[value]);
        }, 5000);
        return () => clearInterval(interval);
    }, [value]);

    return (
        <Box>
            <Box sx={{ height: { sm: "0px", md: '3px' } }}></Box>
            <CommonDesign>
                <Box
                    sx={{
                        mt: { xs: 15, sm: 14, md: 5 },
                        mx: { xs: 3, sm: 3, md: 4, },
                        // display: "flex",
                        // flexDirection: "column",
                        // alignItems: "center",
                        px: { xs: 3, sm: 4, md: 4 },
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography component="h1" variant="h5" sx={{ fontSize: { xs: 17 } }}>
                            Welcome to E-Health Services
                        </Typography>
                        <Typography sx={{ fontSize: "15px", color: '#737D89', fontWeight: 300 }}>Sign in with your MedXperts user Id and Password </Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Box sx={{ mt: 4 }}>
                            {/* <ColorTextField label="User Name" placeholder="Enter Your User Name" type="text" name="username" value={username} onChange={handleChangeUsername}
                                endAdornment="@mdx"
                            /> */}
                            <EndAdornmentTextField
                                label="User Name"
                                placeholder="Enter User Name"
                                type={'text'}
                                borderColor="#59D8FA"
                                icon={<Typography sx={{ fontWeight: 600 }}>@mdx</Typography>}
                                value={username}
                                onChange={handleChangeUsername}
                                autoFocus  // Add autofocus here
                            />
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            {/* <ColorTextField label="Password" placeholder="Enter Your Password" name="password" type="password"
                                value={password}
                                onChange={handleChangePassword} onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }} /> */}
                            <EndAdornmentTextField
                                label="Password"
                                placeholder="Enter Password"
                                borderColor="#59D8FA"
                                type={showPassword ? 'text' : 'password'}
                                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                                value={password}
                                onChange={handleChangePassword}
                                onClick={handleClickShowPassword}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }}
                            />
                        </Box>

                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <div></div>
                            <Typography sx={{ fontSize: '12px', cursor: 'pointer', "&:hover": { textDecoration: 'underline' } }} onClick={() => navigate("/forgotpassword")}
                            >Forgot Password?</Typography>
                        </Box>
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        {alertmessage && (
                            <Typography
                                sx={{
                                    color: alertstatus === "success" ? "green" : "red",
                                    mt: 1, textAlign: 'center'
                                }}
                            >
                                {alertmessage}
                            </Typography>
                        )}
                        <Box sx={{ mt: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                            <Button variant='outlined' sx={{ borderRadius: 5, color: '#7B55DF', border: '1px solid #7B55DF' }} onClick={() => navigate("/forgotusername")}
                            >Forgot Username?</Button>
                            <Button variant='contained' sx={{ width: '130px', bgcolor: '#025CEC' }} disabled={!username || !password} startIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} onClick={handleSubmit}> Login</Button>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "20px",
                            }}
                        >
                            <Checkbox {...label} defaultChecked />
                            <p style={{ fontSize: "12px", color: '#737D89' }}>
                                {t("credentialsData.commondata.checkbox")}
                            </p>
                        </Box>
                        <Typography sx={{ ml: 2, mt: 1 }}>Is this your first time here?</Typography>
                        <Button variant='outlined' sx={{ ml: 2, mt: 1, border: '2px solid #025CEC', "&:hover": { border: '2px solid #025CEC' }, borderRadius: 2, color: '#025CEC' }} onClick={() => navigate('/signup')}>Register Now</Button>

                    </Box>
                </Box >
            </CommonDesign >

        </Box >

    );
}

export default Login