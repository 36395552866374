import React from 'react'
import { Check, ExpandMore, Link } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import URL from '../../../../utility/apiurl'
import axios from 'axios'
import { localstore } from '../../../../localStore/localStore'
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions'
import { useTranslation } from "react-i18next";

import linkIcon from '../../../../assets/home/records_module/link-white.svg'
import { useNavigate } from 'react-router-dom'

function SelectPhrAddress(props) {
    const [t, i18n] = useTranslation("global");
    const phrsaddress = useSelector((state) => state.abha.mappedphrAddress)
    const result = useSelector((state) => state.phraddress.selectphrdetails)
    const AbhaAddresswithNumber = result.AbhaAddresswithNumber
    const AbhaNumber = result.AbhaNumber
    const mobile = result.nobile
    const transactionId1 = result.transactionId
    const authmethod = result.authmethod
    const EmailAddress1 = result.EmailAddress1
    const phrs = result.phrs
    console.log(result, "reuslt")
    // const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [abhatoken, setAbhatoken] = useState('')
    // console.log(phrs, "phrssssss")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [phradd, setPhradd] = useState('');
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const aadhar = useSelector((state) => state.abha.aadhartokenHin)
    // console.log(aadhar, "adharrrrrrrrrrrrrrrrrr")
    const xtoken = aadhar.xtoken

    const handleSelect = (e) => {
        // console.log(e, "eeeeeeeeeee")

        // if (phradd === Object.keys(e)[0]) {
        // setPhradd('');
        // } else {
        setPhradd(Object.keys(e)[0]);
        // }
        setErrorMessage('')
    }

    const allLinked = Array.isArray(phrs) && phrs.every((e) => {
        const firstKey = Object.keys(e)[0]; // Get the first key of the object
        return e[firstKey] === 'not available';
    });

    console.log(allLinked);

    const handleLink = async (jwtResponsetoken) => {
        let d = {};
        try {
            const response = await axios.post(
                `${URL.abha}link/phrAddress/${primaryId}`,
                d,
                {
                    headers: {
                        'X-Token': `Bearer ${jwtResponsetoken}`,
                        'gatewayToken': 'medxperts',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            console.log(response.data, 'linking successful');
            dispatch(
                getPatientdatafromibm(
                    'Patient',
                    primaryId,
                    token,
                ),
            );
            dispatch(getAbhaProfile(primaryId, token));
            setAlertMessage('Data registered Successfully!');
            setAlertstatus('success');
            props.setShow(8)
        } catch (error) {
            console.log(error.message, 'in linking');
            setAlertMessage(error.message);
            setAlertstatus('error');
        }
    };

    const handleOnLink1Next = async () => {
        // dispatch({
        //     type: "SELECTPHR",
        //     payload: phradd
        // })
        // props.setShow(9)
        setLoading(true)
        if (phradd.length > 0) {
            setLoading(true)
            axios.get(`${URL.abha}login/search/authmodes/${primaryId}/${phradd}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    gatewayToken: 'medxperts',
                }
            }).then(res => {
                console.log(res, "authmethods")
                // setAlertMessage("")
                const updatedAuthMethods = res.data.authMethods.filter(method => method !== "AADHAAR_BIO" && method !== "DEMOGRAPHICS" && method !== "AADHAAR_OTP");

                dispatch({
                    type: "AUTH_METHODS",
                    payload: updatedAuthMethods
                })
                dispatch({
                    type: "SELECTPHR",
                    payload: res.data.abhaAddress
                })

                if (aadhar.healthIdNumber) {
                    let ibmp = ibm?.identifier
                        ? { ...ibm }
                        : { ...ibm, identifier: [] };
                    let da = {
                        ...ibmp,
                        identifier: [
                            ...ibmp.identifier,
                            {
                                system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                value: aadhar.healthIdNumber,
                                type: {
                                    coding: [
                                        {
                                            // code: 'JHN',
                                            code: 'HIN',
                                        },
                                    ],
                                },
                            },
                        ],
                    };

                    da.identifier.push({
                        system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                        value: phradd,
                        type: {
                            coding: [
                                {
                                    code: 'ABHA', // or any other code you want to use for Healthid
                                },
                            ],
                        },
                    });
                    axios
                        .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        .then(res => {
                            handleLink(xtoken)
                        }).catch(err => {
                            console.log(err.message, 'error in update');
                        });
                } else {
                    props.setShow(8)
                }
            }).catch(err => {
                setAlertMessage(err.message)
                setAlertstatus("error")
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const handlelinked = () => {
        const isAlreadyLinked = phrs.some((e) => Object.keys(e)[0] === phradd && e[Object.keys(e)[0]] === 'available');
        if (isAlreadyLinked) {
            setErrorMessage('This PHR address is already linked choose another one');
        } else {
            setErrorMessage(''); // Clear the error message
        }
    }
    const handleClickOnMobi2 = () => {
        // props.setShow(4)

        // if (result.AbhaNumber === true) {
        //     props.setShow(6)
        // } else {
        //     props.setShow(4)
        // }

        if (AbhaNumber === true) {
            // navigation.navigate('Step1');
            navigate('/connecthealthid')
        } else if (AbhaAddresswithNumber === true) {
            dispatch({
                type: 'PREVERIFY_CREATE',
                payload: {
                    transactionId: transactionId1,
                    updatedNumber: null
                }
            })
            props.setShow(9)


        } else {
            props.setShow(4)

        }
    }
    return (
        <Box sx={{ mt: { xs: "10px", sm: '15px', md: '22px' } }}>
            <Typography
                sx={{
                    fontSize: { xs: 17, sm: 17, md: 17 },
                    fontWeight: 600, color: '#007DCD'
                }}
            >
                {/* {t("existphr")} */}
                Create/Link ABHA Address
            </Typography>

            <Paper elevation={3} sx={{ p: 2, mt: { xs: "10px", sm: 2, md: "10px" } }}>

                <Box sx={{ textAlign: "center", mt: 4 }}>
                    <Typography sx={{ fontWeight: 600 }}>Want to Register New ABHA Address ?</Typography>
                    <Typography sx={{ fontSize: '14px' }}> Click below to create a new ABHA address</Typography>
                    <Button sx={{ mt: "15px", color: '#9560D9', borderColor: '#9560D9', borderRadius: 2, '&:hover': { borderColor: '#9560D9' } }}
                        variant="outlined"
                        onClick={handleClickOnMobi2}
                    >
                        {t("createnewabha")}
                    </Button>

                </Box>
                <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                    <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#000', marginRight: 1 }} />
                    <Typography variant="body1" sx={{ mx: 2 }}>OR</Typography>
                    <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: '#000', marginLeft: 1 }} />
                </Box>
                <Box mt={2}>
                    <Typography sx={{ fontWeight: 600 }}>Link Existing ABHA Address</Typography>
                    <Box sx={{
                        maxHeight: '400px', // Set your desired maximum height
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}>
                        {phrs?.map((e, i) => (
                            <Paper
                                key={i}
                                elevation={2}
                                sx={{
                                    borderRadius: 2,
                                    p: "5px 15px",
                                    mt: 1,
                                    bgcolor: e[Object.keys(e)[0]] === "available" ? "" : "#F5EDED",
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <Grid item xs={10} sm={11} md={11}>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "17px", sm: "18px", md: "19px" },
                                                color: "black",
                                            }}
                                        >
                                            {Object.keys(e)[0]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={1} md={1} sx={{ cursor: "pointer" }}>
                                        {e[Object.keys(e)[0]] === "available" ? (
                                            <Box
                                                onClick={() => handleSelect(e)}
                                                sx={{
                                                    border: "1px solid #0118B4",
                                                    width: "25px",
                                                    height: "25px",
                                                    borderRadius: 5,
                                                }}
                                            >
                                                {phradd === Object.keys(e)[0] && (
                                                    <Check sx={{ color: "#0118B4" }} name="done" />
                                                )}
                                            </Box>
                                        ) : (
                                            <Typography
                                                onClick={handlelinked}
                                                sx={{ fontSize: { xs: 12, sm: 12, md: 14 }, fontWeight: 500 }}
                                            >
                                                Linked
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </Box>
                </Box>
                {alertmessage && <Typography color={"red"} fontSize={"14px"}>{alertmessage}</Typography>}

                {errorMessage && (
                    <Typography color="red" sx={{ mt: 1, ml: 1, fontSize: '14px' }}>
                        {errorMessage}
                    </Typography>
                )}

                {allLinked ?
                    null :
                    < Box sx={{ textAlign: "center", mt: 4 }}>
                        <Button
                            sx={{ width: '110px', gap: 1 }}
                            onClick={handleOnLink1Next}
                            disabled={loading || !phradd}
                            variant="contained"
                            startIcon={
                                loading ? <CircularProgress size={20} color="inherit" /> : <img src={linkIcon} alt="link" width={15} height={15} />
                            }
                        >
                            Link
                        </Button>
                    </Box>
                }

            </Paper >

        </Box >
    );
}

export default SelectPhrAddress