
import React, { useState } from 'react'
import { ArrowBackIos, Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { Card, Grid, Typography, Box, Button, IconButton, CircularProgress, Paper, Checkbox, TextField, InputAdornment, } from '@mui/material'
import { useHistory, useLocation, useNavigate } from 'react-router-dom'
import AadharIcon from '../../../../assets/home/services_assets/aadhar-logo.svg'
import TextInputField from '../../../atoms/TextFields//TextInputField'
import { useDispatch } from 'react-redux'
import { localstore } from '../../../../localStore/localStore'
import URL, { AadharPublicKey } from '../../../../utility/apiurl'
import axios from 'axios'

import { useTranslation } from "react-i18next";
import AadharStep2 from './AadharStep2'
import SelectPhrAddress from '../ConnectAbhaFlows/SelectPhrAddress'
import Registration from '../ConnectAbhaFlows/Registration'
import EditRegistration from '../ConnectAbhaFlows/EditRegistration'
import CreatePhrAddress from '../ConnectAbhaFlows/CreatePhrAddress'
import PhrAddressScreen from '../ConnectAbhaFlows/PhrAddressScreen'
import SelectVerification from '../ConnectAbhaFlows/SelectVerification'
import AadharStep3 from './AadharStep3'
import AadharStep4 from './AadharStep4'
import ValidateOtp from './ValidateOtp'
import JSEncrypt from 'jsencrypt'
import { EndAdornmentTextField } from '../../../atoms/TextFields/AdornmentTextField'
import { handleRequestError } from '../../../atoms/helperFunctions'
import { encryptData } from '../../../../utility/encryption'


function AadharStep1() {

    const navigate = useNavigate()
    const location = useLocation()
    const abhacreate = location?.state?.abhacreate
    // console.log(abhacreate, "abhacreate")
    const dispatch = useDispatch()
    const [aadhar, setAadhar] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [authorizeCheck, setAuthorizeCheck] = useState(false);
    const [showNumber, setShowNumber] = useState(false)
    const [show, setShow] = useState(1)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const publicKey = AadharPublicKey


    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    }

    const toggleText = () => {
        setIsExpanded(!isExpanded);
        setAlertmessage('')
    };
    const handleCheckboxChange = (e) => {
        setAuthorizeCheck(e.target.checked);
    };
    const handleClickShowNumber = () => setShowNumber((prevShowPassword) => !prevShowPassword);

    const handleOnChangeAadhar = (e) => {
        const input = e.target.value;
        const formattedInput = input
            .replace(/[^0-9]/g, '')
            .slice(0, 12)
            .replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
        setAadhar(formattedInput);
        // if (!/^\d+$/.test(input.length < 12)) {
        //     setAlertmessage('Only numbers are allowed.');
        // } else {
        //     setAlertmessage('');
        // }
        // setAlertmessage('')
    };

    const handleSendOtp = async () => {

        if (!authorizeCheck) {
            showAlert('Please agree the terms', 'error');
        }
        setLoading(true);
        if (!(AadharPublicKey && primaryId && token)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }
        let data = aadhar.replace(/ /g, '').replace(/-/g, '');
        const encodedMessage = encryptData(AadharPublicKey, data);

        const payload = {
            aadhaar: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
            // mobileOrAadhaar: 'aadhaar',
        };
        try {
            const response = await axios.post(`${URL.abha}v2/generateAadhaarOtp/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });
            // console.log('aadstep1', response);
            if (response.status === 200 || response.status === 201) {
                showAlert(response.data.message || 'OTP sent to Aadhar Regristration Mobile Number !', 'success');
                setShow(2)
                dispatch({
                    type: "ABHAAADHAR",
                    payload: {
                        transactionId: response.data.txnId, AadharCreateflow: abhacreate, AadharEncrpt: encodedMessage
                    }
                })
                // navigation.navigate('AadStep2', { transactionId: response.data.txnId, AadharCreateflow: AadharCreate, AadharEncrpt: encodedMessage });
            }
        } catch (error) {
            // console.log('aadstep1', error)
            let errorMessage = handleRequestError(error);
            showAlert(errorMessage, 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '44px' }, mb: { xs: 10, sm: 10, md: 5 } }}>

            {show === 1 ? (
                <>
                    <Box sx={{ mt: { xs: "5px", sm: "10px", md: "15px" } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: "5px" },
                            }}
                        >
                            <IconButton onClick={() => navigate(-1)}><ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} /></IconButton>
                            <Typography
                                sx={{ fontSize: { xs: 15, sm: "16px", md: "16px" }, fontWeight: 600, ml: 1, color: '#007DCD' }}
                            >
                                {" "}
                                {t("creatingabhausingabha")}
                            </Typography>
                        </Box>
                    </Box>

                    <Paper
                        elevation={3}
                        sx={{
                            mt: 1,
                            boxShadow: "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)",
                            p: 2,
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                }}
                            >
                                {/* Aadhar Info Section */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 2,
                                        mt: 2,
                                    }}
                                >
                                    <img src={AadharIcon} alt="Aadhar Icon" width={30} height={30} />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 13, sm: "14px", md: "16px" },
                                            fontWeight: 500,
                                            mt: "6px",
                                        }}
                                    >
                                        {t("enteraadharcardnum")}
                                    </Typography>
                                </Box>

                                {/* Aadhar Input Section */}
                                <Grid container mt={3}>
                                    <Grid item xs={12}>
                                        {/* <TextInputField
                                            width={{ xs: '100%', sm: "350px", md: '350px' }}
                                            placeholder="XXXX - XXXX - XXXX"
                                            mt={2}
                                            border="none"
                                            name="aadhar"
                                            value={aadhar}
                                            onChange={handleOnChangeAadhar}
                                        /> */}
                                        <TextField
                                            sx={{
                                                ".MuiInputBase-root": {
                                                    borderRadius: 2,
                                                    backgroundColor: 'whitesmoke',
                                                    mt: 2


                                                },
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: 'none'
                                                },
                                                width: { xs: '100%', sm: "350px", md: '350px' }

                                            }}
                                            placeholder="XXXX - XXXX - XXXX"
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            value={aadhar}
                                            onChange={handleOnChangeAadhar}
                                            type={showNumber ? 'text' : 'password'} // Toggle password visibility
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleClickShowNumber}
                                                            edge="end"
                                                            aria-label="toggle password visibility"
                                                        >
                                                            {showNumber ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        // error={touched.password && Boolean(errors.confirmpassword)}
                                        // helperText={touched.confirmpassword && errors.confirmpassword}
                                        />
                                    </Grid>
                                </Grid>

                                {/* Additional Information Section */}
                                <Box mt={2} p={1.5} sx={{ width: '100%' }}>
                                    <Typography
                                        component="p"
                                        sx={{
                                            fontSize: { xs: 11, sm: 12, md: "11px" },
                                            color: "black",
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            WebkitLineClamp: isExpanded ? "none" : 2, // Truncate to two lines
                                            textAlign: "left", // Align text to the left for better readability
                                        }}
                                    >
                                        <Checkbox
                                            checked={authorizeCheck}
                                            onChange={handleCheckboxChange}
                                            sx={{ marginRight: 1 }}
                                        />
                                        {t("enteraddtxt")}
                                    </Typography>

                                    {/* Toggle for Read More / Read Less */}
                                    <Button onClick={toggleText} sx={{ padding: 0, textAlign: 'center', mt: 1 }}>
                                        {isExpanded ? "Read Less" : "Read More"}
                                    </Button>
                                </Box>

                                {/* Alert Message Section */}
                                {alertmessage && (
                                    <Typography
                                        sx={{
                                            color: alertstatus === "success" ? "green" : "red",
                                            mt: 2,
                                            fontSize: 13,
                                            textAlign: "center",
                                        }}
                                    >
                                        {alertmessage}
                                    </Typography>
                                )}

                                {/* Send OTP Button Section */}
                                <Box sx={{ mt: 3, mb: 5 }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            borderRadius: 2,
                                            bgcolor: "#1CB500",
                                            "&:hover": {
                                                bgcolor: "#1CB500",
                                            },
                                            width: { xs: "100%", sm: "150px" }, // Ensure it adapts well to screen size
                                        }}
                                        onClick={handleSendOtp}
                                        disabled={loading || aadhar?.length < 12 || !authorizeCheck}
                                        startIcon={
                                            loading ? <CircularProgress size={20} color="inherit" /> : null
                                        }
                                    >
                                        {t("sendotp")}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </>
            ) : show === 2 ? (
                <AadharStep2 setShow={setShow} setAlertmessage={setAlertmessage} abhacreate={abhacreate} aadharNumber={aadhar} />
            ) : show === 3 ? (
                <SelectPhrAddress setShow={setShow} />
            ) : show === 4 ? (
                <Registration setShow={setShow} />
            ) : show === 5 ? (
                <EditRegistration setShow={setShow} />
            ) : show === 6 ? (
                <CreatePhrAddress setShow={setShow} />
            ) : show === 7 ? (
                <PhrAddressScreen setShow={setShow} />
            ) : show === 8 ? (
                <SelectVerification setShow={setShow} />
            ) : show === 11 ? (
                <AadharStep3 setShow={setShow} />
            ) : show === 12 ? (
                <AadharStep4 setShow={setShow} />
            ) : show === 13 ? (
                <ValidateOtp setShow={setShow} />
            ) : null}
        </Box>
    );
}

export default AadharStep1