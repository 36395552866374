import React, { useEffect, useState } from 'react'
import { Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import URL, { MobileOrEmailpublicKey } from '../../../../utility/apiurl';
import axios from 'axios';
import { ArrowBackIos } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { useTranslation } from "react-i18next";
import HealthNumStep2 from './HealthNumStep2';
import LoginSelectPhr from './LoginSelectPhr';
import { handleErrorResponse } from '../../../atoms/StatusError';
import { encryptData } from '../../../../utility/encryption';
import { handleRequestError } from '../../../atoms/helperFunctions';
import { getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import AbhaLinking from '../AadharFlow/AbhaLinking';
import AbhaNumberLinking from './AbhaNumberLinking';


function HealthNumStep1() {

    const [show, setShow] = useState(1)
    const [activeButton, setActiveButton] = useState('mobile')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [checkid, setCheckid] = useState(null);
    const [abhaNum, setAbhaNum] = useState('')
    const dispatch = useDispatch()
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    let abhadata = useSelector(state => state.abha.abhaprofile);
    const location = useLocation()
    const HealthId = location.state.HealthId

    const newString = (abhadata?.AbhaNumber) ? (abhadata?.AbhaNumber) : null;
    const [value, setValue] = useState(newString || null);

    const handleOnChangeAbha = (e) => {
        // const inputValue = e.target.value.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');
        const inputValue = e.target.value
        setValue(inputValue);
        if (inputValue.length >= 17) {
            handleCheck(inputValue)
        }
        setAlertmessage('')
    }

    useEffect(() => {
        // console.log(fixedid, primaryid, token, '+++++++++++++++++++<<<<<<<<<<<<<<<<<<<');

        if (token && primaryId) {
            dispatch(
                getPatientdatafromibm(
                    'Patient',
                    primaryId,
                    token,
                ),
            );
        }

    }, [token, primaryId]);
    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    };

    useEffect(() => {
        const formatValue = (inputValue) => {
            // Remove all non-digit characters
            let cleaned = ('' + inputValue).replace(/\D/g, '');

            // Limit to max length of 17 digits
            if (cleaned.length > 17) {
                cleaned = cleaned.substring(0, 17);
            }

            // Apply the formatting: XXXX-XXXX-XXXX
            const formatted = cleaned.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4').trim();
            return formatted;
        };

        const formattedValue = formatValue(value);
        if (value !== formattedValue) {
            setValue(formattedValue);
        }
    }, [value]);

    const handleCheck = async val => {
        let d = {
            healthIdNumber: val,
        };
        if (d.healthIdNumber?.length >= 17) {
            await axios
                .post(`${URL.abha}search/user/byHealthId/${primaryId}`, d, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                        // 'X-CSRF-TOKEN': `${csrf}`
                    },
                })
                .then(res => {
                    setCheckid(res.data.status);
                    showAlert(res.data.status, 'success');
                })
                .catch(err => {
                    // console.log('checkerror in abha login', err);
                    const errorMessage = handleRequestError(err)
                    showAlert(errorMessage, 'error');
                })
                .finally(() => {
                    setLoading(false);
                    // setCheckid('');
                });
        }
    };
    // const handleSend = async () => {
    //     // navigation.navigate('AbhaNumberStep2')
    //     const inputValue = abhaNum.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');

    //     setLoading(true);
    //     let d = {
    //         healthIdNumber: inputValue,
    //     };
    //     let d1 = {
    //         healthIdNumber: inputValue,
    //         authMethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
    //     };

    //     try {
    //         // First API call
    //         const searchResponse = await axios.post(`${URL.abha}search/user/byHealthId/${primaryId}`, d, {
    //             headers: {
    //                 gatewayToken: 'medxperts',
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });

    //         console.log('--------->>> searchapi', searchResponse.data);
    //         setCheckid(searchResponse.data.status);

    //         if (searchResponse.status === 200 || searchResponse.status === 201) {
    //             // Second API call
    //             setLoading(true); // Set loading for the second API call
    //             const res = await axios.post(`${URL.abha}login/hid/init/transaction/${primaryId}`, d1, {
    //                 headers: {
    //                     gatewayToken: 'medxperts',
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });

    //             setAlert(true);
    //             setAlertmessage('OTP sent to mobile number!');
    //             setAlertstatus('success');
    //             setShow(2)

    //             let details = {
    //                 transactionId: res.data.transactionId,
    //                 number: inputValue,
    //                 Mobile: true,
    //             }
    //             dispatch(
    //                 {
    //                     type: 'LOGINABHANUMBER_TRANSID',
    //                     payload: details

    //                 }
    //             )
    //         }
    //     } catch (err) {
    //         if (err.response && err.response.status === 400) {
    //             setAlert(true);
    //             setAlertmessage("ABHA Number not Found or No ABHA address linked with this ABHA number");
    //             setAlertstatus('error');
    //         } else {
    //             setAlert(true);
    //             const { alertmessage, alertstatus } = handleErrorResponse(err)
    //             setAlertmessage(alertmessage);
    //             setAlertstatus(alertstatus);
    //         }
    //     } finally {
    //         setLoading(false); // Reset loading state after both API calls
    //     }
    // };
    //HANDLER FUCNTION TO LOGIN WITH  HEALTH NUMBER OR ABHA NUMBER
    let encodedAbhaNumber = encryptData(MobileOrEmailpublicKey, value)
    let loginpayload = {
        healthIdNumber: encodedAbhaNumber,
        authMode: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
    };

    const handleSend = async () => {
        setLoading(true)
        await axios
            .post(`${URL.abha}login/healthIdNumber/auth/${primaryId}`, loginpayload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`
                },
            })
            .then(res => {
                // console.log('---------RESDATAABHNUMBER', res)
                if (res.status === 200 || res.status === 201) {
                    showAlert(res?.data?.message || 'OTP sent to mobile number!', 'success');
                    if (HealthId) {

                        dispatch({
                            type: "ABHANUMBER_LINKING",
                            payload: {
                                HINumber: value,
                                transactionId: res.data.txnId,
                                authmethod: activeButton === 'aadhar' ? 'aadhaar' : 'mobile',
                                encrypthid: encodedAbhaNumber,
                                HealthId: HealthId,
                            }
                        })
                        setShow(4)
                    } else {

                        dispatch({
                            type: 'LOGINABHANUMBER_TRANSID',
                            payload: {
                                transactionId: res.data.txnId,
                                HINumber: value,
                                encrypthid: encodedAbhaNumber,
                                authmethod: activeButton === 'aadhar' ? 'aadhaar' : 'mobile',
                            },
                        });
                        setShow(2)
                        // }
                    }
                }
            })
            .catch(err => {
                let errorMessage = handleRequestError(err);
                if (errorMessage === 'User not found') {
                    showAlert('ABHA Number not found', 'error');
                } else {
                    showAlert(errorMessage, 'error');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '0px' }, mb: { xs: 10, sm: 10, md: 5 } }}>


            {show === 1 ? (
                <Box>
                    <Box sx={{ mt: { xs: "5px", sm: 2, md: "5px" } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: 2 },
                            }}
                        >
                            <IconButton onClick={() => navigate("/loginabha")}>
                                <ArrowBackIos sx={{ color: '#007DCD' }} />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: 15, sm: "17px", md: "17px" },
                                    fontWeight: 600,
                                    color: '#007DCD'
                                }}
                            >
                                {t("loginabhanum")}
                            </Typography>
                        </Box>
                    </Box>
                    <Paper
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 1,
                            p: 3,
                            boxShadow:
                                "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <Box textAlign={"center"}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 2,
                                }}
                            >
                                <img src={Abhalogo} alt="abha" width={30} height={30} />
                                <Typography sx={{
                                    fontSize: { xs: 14, sm: "16px", md: "16px" },
                                }} >{t("enterabhatxt")}</Typography>
                            </Box>
                            <Grid container mt={2}>
                                <Grid item xs={12}>
                                    <TextInputField
                                        width={{ sm: "350px", md: '350px' }}
                                        placeholder={t("abhahidnum")}
                                        name="value"
                                        border="none"
                                        value={value}
                                        onChange={handleOnChangeAbha}
                                    />
                                    {alertmessage && (
                                        <Typography
                                            sx={{
                                                color: alertstatus === "success" ? "green" : "red",
                                                mt: 1,
                                                fontSize: "13px",
                                            }}
                                        >
                                            {alertmessage}
                                        </Typography>
                                    )}
                                </Grid>
                                {/* {checkid !== "ACTIVE" && abhaNum.length > 5 ? (
                                    <Typography sx={{ color: "red", fontSize: 14 }}>
                                        {t("abhaexist")}
                                    </Typography>
                                ) : null} */}

                            </Grid>

                            <Box mt={3}>
                                <Typography sx={{
                                    fontWeight: 400, fontSize: { xs: 14, sm: "16px", md: "16px" },
                                }}>{t("verifyabha")}</Typography>
                            </Box>
                            <ButtonGroup
                                variant="contained"
                                aria-label="outlined primary button group"
                                sx={{ mt: 1 }}
                            >
                                <Button
                                    sx={{
                                        bgcolor:
                                            activeButton === "aadhar" ? "white" : "#EAE2E2",
                                        "&:hover": {
                                            bgcolor:
                                                activeButton === "aadhar" ? "white" : "#EAE2E2",
                                        },
                                        color: "black",
                                    }}
                                    disableElevation
                                    onClick={() => setActiveButton("aadhar")}
                                >
                                    {t("aadharotp")}
                                </Button>
                                <Button
                                    sx={{
                                        bgcolor:
                                            activeButton === "mobile" ? "white" : "#EAE2E2",
                                        "&:hover": {
                                            bgcolor:
                                                activeButton === "mobile" ? "white" : "#EAE2E2"
                                        },
                                        color: "black",
                                    }}
                                    disableElevation
                                    onClick={() => setActiveButton("mobile")}
                                >
                                    {t("mobileotp")}
                                </Button>
                            </ButtonGroup>
                            <Box sx={{ mt: 4, textAlign: "center", mb: 2 }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        borderRadius: 2,
                                        "&:hover": {
                                            bgcolor: "#1CB500",
                                        },
                                        bgcolor: "#1CB500",
                                        width: "150px",
                                    }}
                                    onClick={handleSend}
                                    disabled={loading || value?.length < 14}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : null
                                    }
                                >
                                    {/* {loading ? 'Sending...' : 'Send OTP'} */}
                                    {t("credentialsData.signupmobiledata.otpbtntxt")}
                                </Button>
                            </Box>
                            <Box sx={{ mt: 4, textAlign: "center", mb: 2 }}>
                                <Typography>Don't Remembering having ABHA Number?</Typography>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 2,
                                        color: '#9560D9',
                                        borderColor: '#9560D9',
                                        width: "200px",
                                        mt: 2
                                    }}
                                    onClick={() => navigate("/aadharflow", { state: { abhacreate: true } })}
                                >
                                    {/* {loading ? 'Sending...' : 'Send OTP'} */}
                                    Recover / Create
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Box >
            ) : show === 2 ? (
                <HealthNumStep2 setShow={setShow} authMethod={activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP'} abhaNum={abhaNum} />
            ) : show === 3 ? (
                <LoginSelectPhr setShow={setShow} />
            ) : show === 4 ? (
                <AbhaNumberLinking setShow={setShow} />
            ) : null
            }
        </Box >
    );
}

export default HealthNumStep1