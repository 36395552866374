import * as React from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography } from '@mui/material'
import EditProfileCard from '../../../atoms/EditProfileCard';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import axios from 'axios';
import URL, { MobileOrEmailpublicKey, OtpPublicKey } from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useState } from 'react';
import PhonenumUpdate1 from './PhonenumUpdate1';
import { useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { CustomTextField } from '../../../atoms/TextFields/customTextField';
import { handleRequestError1 } from '../../../atoms/helperFunctions';
import { encryptData } from '../../../../utility/encryption';
import { getAbhaMobile } from '../../../../redux/Actions/Actions';
import { useDispatch } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function PhonenumUpdate({ mobiledata }) {
    const [open, setOpen] = useState(false);
    const [mobilenum, setMobilenum] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [otp, setOtp] = React.useState('')
    const [error, setError] = useState(false);
    const [show, setShow] = useState(1)
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [mobiletxtnum, setmobiletxtnum] = useState('');
    const dispatch = useDispatch()

    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    }

    const handleOnChangeMobile = (e) => {
        const inputValue = e.target.value || "";
        const numericValue = inputValue.replace(/\D/g, "");
        if (/[^0-9]/.test(inputValue)) {
            setError(true);
        } else {
            setError(false);
            setMobilenum(numericValue.slice(0, 10));
        }
        setAlertmessage('')
    }
    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value || "";
        const numericValue = inputValue.replace(/\D/g, "");
        setOtp(numericValue.slice(0, 6))
    }
    useEffect(() => {
        setMobilenum(mobiledata?.mobile)
    }, [mobiledata?.mobile])

    // const handleSubmit = async () => {
    //     // setShow(2)
    //     setLoading(true)
    //     const data = {
    //         'authMode': 'MOBILE_OTP',
    //         'mobile': mobilenum
    //     }
    //     await axios.post(`${URL.consents}abha/profile/update-mobileEmail/init/${primaryId}`, data, {
    //         headers: { 'Authorization': `Bearer ${token}` }
    //     }).then(res => {

    //         setSessionId(res?.data?.sessionId)
    //         setAlertmessage("sent otp successfully")
    //         setAlertstatus('success')
    //         setShow(2)
    //     }).catch((err) => {
    //         setAlertmessage("please try with different 'Mobile Number'")
    //         setAlertstatus('error')
    //     }).finally(() => {
    //         setLoading(false)
    //     })
    // }
    //FUNCTION TO SEND THE OTP USING THE MOBILENUMBER
    const handleMobileSend = async (values) => {
        setLoading(true);
        if (!(MobileOrEmailpublicKey && primaryId && token)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }

        const encodedMessage = encryptData(MobileOrEmailpublicKey, mobilenum);
        const payload = {
            mobile: encodedMessage.replace(/\n/g, ''),
        };
        try {
            const response = await axios.post(`${URL.consents}account/change/mobile/new/generateOTP/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });

            if (response.status === 200 || response.status === 201) {
                showAlert(response?.data?.message || `OTP sent to +91XXXXXX ${values.mobile.slice(values.mobile.length - 4)}`, 'success');
                console.log('responsemobile', response);
                setmobiletxtnum(response?.data?.txnId);
                setShow(2)
            }
        } catch (error) {
            console.log(error, 'updatemobilre');
            if (error?.response?.data === 'Invalid mobileNumber') {
                showAlert(error?.response?.data, 'error');
            } else {
                let errorMessage = handleRequestError1(error);
                showAlert(errorMessage, 'error');
            }
        } finally {
            setLoading(false);
            // setmobileLoader(false);
            // setEmailloader(false);
        }
    };

    const handleSubmitOtp = async (value) => {
        try {
            // Show loading indicator
            setLoading(true);
            const encodedMessage = encryptData(OtpPublicKey, otp);
            // Prepare the data based on the value ('email' or 'mobile')
            const data = {
                txnId: mobiletxtnum,
                otp: encodedMessage.replace(/\n/g, ''),
            };

            console.log('data', data);
            let url = `change/mobile/new/verifyOTP`
            // Make the API call to verify OTP
            const response = await axios.post(`${URL.consents}account/${url}/${primaryId}`, data, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });
            // Handle successful response
            showAlert(response?.data?.message || 'OTP Verified Successfully', 'success');
            dispatch(getAbhaMobile(primaryId, token));
            // Optionally reset state after success

        } catch (err) {
            // Handle errors that occur during the request
            console.error('Error:', err);
            let errorMessage = handleRequestError1(err);
            if (errorMessage === 'Cannot invoke "java.util.List.isEmpty()" because "xTokenList" is null') {
                dispatch(getAbhaMobile(primaryId, token));
            } else {
                let errorMessage = handleRequestError1(err);
                showAlert(errorMessage, 'error');
            }
        }
        finally {
            setLoading(false)
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClosedone = () => {
        handleClose()
        setAlertmessage('')
    }
    return (
        <React.Fragment>
            <EditProfileCard title="Phone Number" displaydata={mobiledata.mobile ? mobiledata.mobile : "N/A"} action="Update" onClick={handleClickOpen} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='sm'
            >
                {show === 1 ? (
                    <>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: { xs: '15px', sm: '18px', md: '18px' }, fontWeight: 600, textAlign: 'center' }}>Update New Mobile Number</Typography>
                            <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>

                            <Grid container >
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box >
                                        <Box>
                                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Mobile Number</label>
                                        </Box>
                                        <CustomTextField placeholder="Enter New Mobile Number" type={'text'} name="mobilenumber" borderColor="#59D8FA" borderRadius="7px" value={mobilenum} onChange={handleOnChangeMobile} />
                                    </Box>
                                </Grid>
                                {error && (
                                    <Typography sx={{ fontSize: '13px', color: 'red', mt: 1 }}>
                                        Only numbers are allowed.
                                    </Typography>
                                )}
                            </Grid>
                        </DialogContent>


                    </>
                ) : show === 2 ? (
                    <>

                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>

                            <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Enter OTP</Typography>
                            <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>

                        </DialogTitle>
                        <Divider />
                        <DialogContent>

                            <Box sx={{ textAlign: 'center', mt: 2 }}>
                                <TextInputField placeholder=" Enter Received OTP" name="otp" value={otp} onChange={handleOnChangeOtp} />

                            </Box>
                        </DialogContent>

                        {/* <DialogActions sx={{ justifyContent: 'center', m: 0 }}>
                    <Button variant='contained' sx={{ width: '120px', borderRadius: '10px' }} startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null} onClick={handleSubmitOtp}>Submit</Button>
                </DialogActions>
                    */}
                    </>) : null}
                <Divider />
                {alertmessage && (
                    <Typography
                        sx={{
                            color: alertstatus === "success" ? "green" : "red",
                            mt: 1, fontSize: '13px', textAlign: 'center'
                        }}
                    >
                        {alertmessage}
                    </Typography>
                )}
                <DialogActions sx={{ justifyContent: 'center', m: 0 }}>
                    <Button variant='contained' sx={{ width: '120px', borderRadius: '10px' }} startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null} onClick={show === 1 ? handleMobileSend : handleSubmitOtp}>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}
export default PhonenumUpdate;