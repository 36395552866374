import React, { useEffect, useState } from 'react'
import { Box, Card, CircularProgress, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import URL from '../../../../utility/apiurl';
import { getDoctorsFollowCount } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import { useTranslation } from 'react-i18next';
import SuggestDoctor from './SuggestDoctor';
import MoreDoctors from './MoreDoctors';
import SuggestOrg from './SuggestOrg';
import MoreHospitals from './MoreHospitals';
import MyHospitals from './MyHospitals';
import { useNavigate } from 'react-router-dom';

function MyDoctors() {
    const [suggestDoc, setSuggestDoc] = useState([]);
    // console.log(suggestDoc, "line30");
    const [suggestedOrg, setSuggestedOrg] = useState([]);
    const token = localstore.getToken()
    const [scrollNum, setScrollNum] = useState(0);
    const [totalNumCard, setTotalNumCard] = useState(0);
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    let doctorscount = useSelector(state => state.medx.doctorsCount);
    // console.log(suggestDoc, "sugggggggggg")
    // console.log(suggestOrg, "suOOOOOOOOOOO")
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(1)
    const [offset, setOffset] = useState(0);
    const [offsetOrg, setOffsetOrg] = useState(0);
    const [total, setTotal] = useState('');
    const [refreshing, setRefreshing] = useState(false);
    const patientId = localstore.getPatientId()
    const limit = 50;
    const navigate = useNavigate()
    // console.log(suggestDoc, "doctors")
    useEffect(() => {
        fetchData()
    }, [patientId]);

    const fetchData = () => {
        if (patientId) {
            setIsLoading(true)
            axios.get(`${URL.suggestedDoctors}${limit}/${offset}/${patientId}`).then((res) => {
                // console.log(res.data, "suggestdoctors")
                setSuggestDoc(suggestDoc.concat(res?.data?.data))
                setScrollNum(pre => pre + limit)
                setTotalNumCard(res.data.TotalCount)
            })
                .catch((error) => console.log(error, 'EERRRRRRROOOOORRRRRR')).finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleDocFavourite = (index) => {
        // console.log(index, "inddddddddddd")
        let data = suggestDoc.filter((e, i) => {
            if (i !== index) {
                return (e);
            }
        });
        setSuggestDoc(data);
    }

    const handleonnextdoctors = () => {
        navigate('/moredoctors')
    }


    return (
        <Box sx={{ p: 1, mt: { xs: "10px", md: '20px' } }} >
            <>
                <Card sx={{ p: { sm: 2, md: 1 } }}>
                    <Typography sx={{ ml: "5px", fontSize: '17px', fontWeight: 600 }}>
                        Suggested Doctors
                    </Typography>
                    {isLoading ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress sx={{ color: "#CDF4F9" }} />
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: { xs: 1, sm: 1 }, p: 1 }}>
                            {suggestDoc?.length > 0 ? suggestDoc?.slice(0, 3).map((each, ind) => (
                                <SuggestDoctor key={ind} user={each}
                                    index={ind} handleDocFavourite={() => handleDocFavourite(ind)} />
                            )) : <>No Doctors Available</>
                            }
                        </Box>
                    )}
                    {suggestDoc.length >= 3 &&
                        <Typography sx={{ textAlign: 'center', cursor: 'pointer', fontWeight: 600, color: '#2FA3EC', '&:hover': { textDecoration: 'underline' } }} onClick={handleonnextdoctors}>More Doctors</Typography>}
                </Card>
            </>
            <MyHospitals />
        </ Box>
    )
}

export default MyDoctors