import React from 'react'
import { Autocomplete, Box, Button, Card, CircularProgress, Divider, Grid, IconButton, InputAdornment, List, ListItem, Paper, Popper, TextField, Tooltip, Typography } from '@mui/material'
import axios from 'axios';
import * as Yup from 'yup';
import { useState } from 'react';
import URL, { OtpPublicKey, PasswordpublicKey } from '../../../../utility/apiurl';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { getAbhaCard, getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';
import { debounce } from 'lodash';
import { encryptData } from '../../../../utility/encryption';
import { handleRequestError, updateIdentifier } from '../../../atoms/helperFunctions';
import { ArrowBackIos, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const CustomTooltip = ({ labeltext, isTooltipOpen, onCloseTooltip }) => (
    <Popper open={isTooltipOpen} placement="top" style={{ zIndex: 10 }}>
        <Paper style={{ padding: 10 }}>
            <Typography>{labeltext}</Typography>
            <Button onClick={onCloseTooltip} size="small">
                Close
            </Button>
        </Paper>
    </Popper>
);
function CreatePhrAddress(props) {
    const dispatch = useDispatch()
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    let getregistrationdata = useSelector(state => state.medx.registrationdata);
    const jwttoken = ""
    console.log(getregistrationdata, "resulttt")
    const resultabhanumber = useSelector((state) => state.phraddress.selectphrdetails)
    const AbhaAddresswithNumber = resultabhanumber.AbhaAddresswithNumber
    const transactionId = result.transactionId
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate()
    const sessionId = result.sessionId
    const AbhaNumber = result.AbhaNumber
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [checkid, setCheckid] = useState(false);
    const [suggestName, setSuggestName] = useState([])
    const [apiResponse, setApiResponse] = useState([]);
    const [filteredUsernames, setFilteredUsernames] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const [username, setUsername] = useState('')
    let ibm = useSelector((state) => state.medx.ibmpdata);
    const aadhar = useSelector((state) => state.abha.aadhartokenHin)
    // console.log(aadhar, "adharrrrrrrrrrrrrrrrrr")
    const xtoken = aadhar.xtoken
    const [isTooltipOpen2, setTooltipOpen2] = useState(false); // State for second tooltip
    const [isTooltipOpen3, setTooltipOpen3] = useState(false); // State for third tooltip
    const [isTooltipOpen1, setTooltipOpen1] = useState(false); // State for first tooltip

    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()

    const usernameValidationSchema = Yup.object().shape({
        // username: Yup.string()
        //     .matches(
        //         /^[A-Za-z0-9]+([._][A-Za-z0-9]+)?$/,
        //         'Username should not contain spaces and can have one dot or underscore in between alphanumeric characters. Special characters cannot be at the beginning or end.'
        //     )
        //     .min(8, 'Username should be at least 8 characters.')
        //     .max(18, 'Username should be at most 18 characters.')
        //     .required('Username is required'),
        password: Yup.string()
            .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
            .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
            .matches(/\d/, 'Password must have a number')
            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .required('Password is required'),
        confirmpassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords do not match')
            .required('Confirm password is required'),
    })

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prev) => !prev);
    };


    const handleLink = async (jwtResponsetoken, value) => {
        let d = {};
        try {
            const response = await axios.post(
                `${URL.abha}link/phrAddress/${primaryId}`,
                d,
                {
                    headers: {
                        'X-Token': `Bearer ${jwtResponsetoken}`,
                        'gatewayToken': 'medxperts',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            // console.log(response.data, 'linking successful');
            dispatch(
                getPatientdatafromibm(
                    'Patient',
                    primaryId,
                    token,
                ),
            );
            // dispatch(getAbhaProfile(primaryId, token));
            setAlertmessage('Data registered Successfully!');
            setAlertstatus('success');
            handleupdate(value)
        } catch (error) {
            console.log(error.message, 'in linking');
            setAlertmessage(error.message);
            setAlertstatus('error');
        }
    };

    // Handler for closing a specific tooltip
    const handleCloseTooltip = (tooltipIndex, value) => {
        if (tooltipIndex === 1) setTooltipOpen1(value);
        else if (tooltipIndex === 2) setTooltipOpen2(value);
        else if (tooltipIndex === 3) setTooltipOpen3(value);
    };

    // Handler for toggling a specific tooltip
    const onCloseTooltipPrev = (tooltipIndex) => {
        if (tooltipIndex === 1) setTooltipOpen1(prevState => !prevState);
        else if (tooltipIndex === 2) setTooltipOpen2(prevState => !prevState);
        else if (tooltipIndex === 3) setTooltipOpen3(prevState => !prevState);
    };






    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const handleCheck = async text => {
        // console.log('---------->>>>> e.nativeEvent.text', text);
        let d = {
            healthId: text,
        };
        if (d.healthId?.length > 0) {
            await axios
                .post(`${URL.abha}existsByHealthId/${primaryId}`, d, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {
                    // console.log("existhealthidres", res)
                    setCheckid(res.data.status);
                })
                .catch(err => {
                    setCheckid(false);
                    // console.log("errorinabhaname", err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };





    const handletooltip = () => {
        setTooltipOpen1(false)
        setTooltipOpen2(false)
        setTooltipOpen3(false)
        setShowSuggestions(false)
    }
    console.log(apiResponse, "apiresonspe")
    const handleSuggestion = async (text) => {
        console.log('---------->>>>> e.nativeEvent.text', text);

        if (apiResponse?.length > 0) {
            filterSuggestions(text, apiResponse);
            setShowSuggestions(true);
        } else {
            let d = {
                txnId: getregistrationdata?.txnId,
                firstName: getregistrationdata?.firstName,
                middleName: getregistrationdata?.middleName,
                lastName: getregistrationdata?.lastName,
                dayOfBirth: getregistrationdata?.dayOfBirth,
                monthOfBirth: getregistrationdata?.monthOfBirth,
                yearOfBirth: getregistrationdata?.yearOfBirth,
            };

            try {
                let response = await axios.post(`${URL.abha}mobile/suggestion`, d, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200 || response.status === 201) {
                    // Store API response
                    setApiResponse(response?.data?.abhaAddressList);
                    // console.log(response, 'responsedata');
                    // Filter suggestions based on initial input
                    filterSuggestions(text, response?.data?.abhaAddressList);
                }
            } catch (error) {
                console.log("Errorfetchingsuggestions:", error);
                // let errorMessage = handleRequestError(error);
                // showAlert(errorMessage, 'error');
            }
        }
    };

    // Debounced function to fetch suggestions
    const debouncedSearch = debounce(handleCheck, 500);

    const debouncedSearch1 = debounce((text) => handleInputChange(text), 500);
    // Handle input change

    // Filter suggestions based on user input
    const filterSuggestions = (value, apiResponsephrsuggection) => {
        // console.log('apiResponsephrsuggection', apiResponsephrsuggection)
        const filteredList = apiResponsephrsuggection?.filter((username) =>
            username?.toLowerCase()?.includes(value?.toLowerCase())
        );
        // console.log('datafilter', filteredList)
        setFilteredUsernames(filteredList);
        setShowSuggestions(true);
    };


    const handleInputChange = async (value) => {
        console.log(value, "valueess")
        setUsername(value);
        if (value) {
            await handleSuggestion(value); // Ensure API call happens
        } else {
            setShowSuggestions(false);
            setFilteredUsernames([])
        }
    };

    // const handleBlur = async (field) => {
    //     setTouched((prevTouched) => ({ ...prevTouched, [field]: true }));
    //     // await usernameValidationSchema.validate({ username }, { abortEarly: false });
    // };
    const handleupdate = async (phradd) => {
        // console.log(phradd, 'phraddress');

        let phrAddress = phradd?.toLowerCase()
        let updatedData = updateIdentifier(ibm, phrAddress, 'ABHA');

        await axios
            .put(`${URL.cliniapi}Patient/${primaryId}`, updatedData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(async res => {
                showAlert('Data registered Successfully!', 'success');
                dispatch(
                    getPatientdatafromibm(
                        'Patient',
                        primaryId,
                        token,
                    ),
                );
                dispatch(getAbhaProfile(primaryId, token,));
                dispatch(getAbhaCard(token, primaryId));
                navigate('/');
            })
            .catch(err => {
                console.log(err, 'errorinupdate');
                let errorMessage = handleRequestError(err);
                showAlert(errorMessage || err.message, 'error');
                setLoading(false);
            });
    }

    const showAlert = (message, status) => {
        setAlertopen(true);
        setAlertmessage(message);
        setAlertstatus(status);
        setTimeout(() => {
            setAlertopen(false);
        }, 5000);
    };
    return (
        <div>
            <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, color: '#007DCD', fontWeight: 600, mt: { md: 2, sm: "15px", xs: "10px" } }}>
                Create New PHR Address
            </Typography>
            <IconButton onClick={() => (AbhaAddresswithNumber ? props.setShow(9) : props.setShow(4))}>
                <ArrowBackIos sx={{ color: '#007DCD' }} />
            </IconButton>
            <span>Back</span>
            <Paper elevation={3} sx={{ p: 3, mt: 1, display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '450px', width: '100%' }}>
                    <Typography sx={{ fontStyle: 'inherit', fontSize: { xs: 16, sm: 18, md: 16 }, fontWeight: 600 }}>
                        PHR address
                    </Typography>
                    {/* <Autocomplete
                        id="state-select"
                        value={username}
                        onChange={(_, newValue) => setUsername(newValue)}
                        options={filteredUsernames || []}
                        getOptionLabel={(option) => option?.name || ''}
                        size="small"
                        autoHighlight
                        onInputChange={handleInputChange} // Call the separated input change handler
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                {option?.name}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select State"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // To prevent the browser from auto-completing
                                }}
                            />
                        )}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: '#59D8FA',
                                },
                            },
                            '& .MuiInputBase-root': {
                                borderRadius: '7px',
                            },
                            fullWidth: true, // Ensures the component takes the full width of its container
                        }}
                    /> */}
                    <Box sx={{ width: '100%', mt: 1 }}>
                        <Autocomplete
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#59D8FA',
                                    },
                                },
                                ".MuiInputBase-root": {
                                    borderRadius: '7px',
                                    backgroundColor: 'whitesmoke',

                                },
                            }}
                            size='small'
                            freeSolo // Allows the user to input custom text
                            options={filteredUsernames} // The array of suggestions
                            value={username}
                            onInputChange={(event, newValue) => {
                                // if (newValue !== username) {
                                debouncedSearch1(newValue); // Debounced call to handleInputChange
                                // }
                            }}
                            // onChange={handleCheck}
                            // onBlur={handleBlur}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Enter username"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            // <Typography sx={{ marginLeft: 1, marginRight: 1, color: '#aaa' }}>@sbx</Typography>
                                            <Typography sx={{ color: '#000000' }}>@sbx</Typography>
                                        ),
                                    }}
                                // error={Boolean(errors.username && touched.username)}
                                // helperText={errors.username && touched.username ? errors.username : ''}
                                />
                            )}
                        />

                        {checkid && (
                            <Typography color="error" sx={{ marginTop: 1 }}>
                                User Name already exists. Please select another.
                            </Typography>
                        )}
                        {/* {errors.username && touched.username && (
                            <Typography color="error" sx={{ marginTop: 1 }}>
                                {errors.username}
                            </Typography>
                        )} */}
                    </Box>

                    <Formik
                        validationSchema={usernameValidationSchema}
                        initialValues={{ password: '', confirmpassword: '' }}
                        // onSubmit={async (values, { setSubmitting }) => {
                        //     try {
                        //         let d = { healthId: values.username };
                        //         let data = {
                        //             password: values.password,
                        //             mobileOrAadhaar: 'mobile',
                        //             sessionId: sessionId,
                        //             phrAddress: `${values.username}@sbx`,
                        //         };
                        //         let data1 = {
                        //             password: values.password,
                        //             abhaPassword: 'password',
                        //             transactionId: transactionId,
                        //             phrAddress: `${values.username}@sbx`,
                        //         };

                        //         // First API call
                        //         setLoading(true);
                        //         const existsByHealthIdResponse = await axios.post(`${URL.abha}existsByHealthId/${primaryId}`, d, {
                        //             headers: {
                        //                 gatewayToken: 'medxperts',
                        //                 Authorization: `Bearer ${token}`
                        //             },
                        //         });

                        //         setCheckid(existsByHealthIdResponse.data.status);

                        //         // Second API call
                        //         if (existsByHealthIdResponse.status === 200 || existsByHealthIdResponse.status === 201) {
                        //             if (AbhaNumber === true) {
                        //                 const createPhrAddressResponse = await axios.post(`${URL.abha}registration/hid/create/phrAddress/${primaryId}`, data1, {
                        //                     headers: {
                        //                         gatewayToken: 'medxperts',
                        //                         Authorization: `Bearer ${token}`,
                        //                     },
                        //                 });

                        //                 dispatch({
                        //                     type: "PHRADDRESS1", payload: createPhrAddressResponse.data.phrAdress
                        //                 });

                        //                 props.setShow(7);
                        //             } else {
                        //                 const createPhrAddressResponse = await axios.post(`${URL.abha}mobile/create/phrAddress/${primaryId}`, data, {
                        //                     headers: {
                        //                         gatewayToken: 'medxperts',
                        //                         Authorization: `Bearer ${token}`,
                        //                     },
                        //                 });

                        //                 const phraddress = createPhrAddressResponse.data.phrAddress;
                        //                 dispatch({
                        //                     type: "PHRADDRESS", payload: phraddress
                        //                 });

                        //                 if (aadhar.healthIdNumber) {
                        //                     // Additional logic for linking if needed
                        //                     let ibmp = ibm?.identifier
                        //                         ? { ...ibm }
                        //                         : { ...ibm, identifier: [] };
                        //                     let da = {
                        //                         ...ibmp,
                        //                         identifier: [
                        //                             ...ibmp.identifier,
                        //                             {
                        //                                 system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                        //                                 value: aadhar.healthIdNumber,
                        //                                 type: {
                        //                                     coding: [
                        //                                         {
                        //                                             // code: 'JHN',
                        //                                             code: 'HIN',
                        //                                         },
                        //                                     ],
                        //                                 },
                        //                             },
                        //                         ],
                        //                     };

                        //                     da.identifier.push({
                        //                         system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                        //                         value: phraddress,
                        //                         type: {
                        //                             coding: [
                        //                                 {
                        //                                     code: 'ABHA', // or any other code you want to use for Healthid
                        //                                 },
                        //                             ],
                        //                         },
                        //                     });
                        //                     axios
                        //                         .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                        //                             headers: {
                        //                                 Authorization: `Bearer ${token}`,
                        //                             },
                        //                         })
                        //                         .then(res => {
                        //                             handleLink(xtoken)
                        //                         }).catch(err => {
                        //                             console.log(err.message, 'error in update');
                        //                         });
                        //                 } else {
                        //                     props.setShow(7);
                        //                 }
                        //             }
                        //         }
                        //     } catch (error) {
                        //         if (error.response && error.response.data && error.response.status === 400) {
                        //             const responseText = error.response.data.message;
                        //             const errorMessagePattern = /"message":"(.*?)"/;
                        //             const match = responseText.match(errorMessagePattern);

                        //             if (match && match[1]) {
                        //                 const errorMessage = match[1];
                        //                 setAlertmessage(errorMessage);
                        //                 setAlertstatus('error');
                        //             }
                        //         } else {
                        //             setAlertmessage(error.message);
                        //             setAlertstatus('error');
                        //         }
                        //     } finally {
                        //         setLoading(false);
                        //         setSubmitting(false); // Set submitting to false to allow the form to be submitted again
                        //     }
                        // }}
                        onSubmit={async (values) => {
                            setLoading(true)
                            if (OtpPublicKey && username) {
                                const encodedMessage = encryptData(OtpPublicKey, values.password);
                                if (token && primaryId) {
                                    let data = {
                                        password: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
                                        abhaAddress: `${username}@sbx`,
                                        ...getregistrationdata,
                                    };
                                    // console.log('dataregrestion', data)
                                    try {
                                        // Await the axios.post call to ensure it is properly awaited
                                        const res = await axios.post(`${URL.abha}mobile/create/phrAddress/${primaryId}`, data, {
                                            headers: {
                                                gatewayToken: 'medxperts',
                                                Authorization: `Bearer ${token}`,
                                            },
                                        });

                                        // Check the status to determine further actions
                                        if (res.status === 200 || res.status === 201) {
                                            console.log('resdatamobilestep4', res)
                                            // If the patient have ABHA number, this function will update the ABHA Number in the FHIR server.
                                            if (aadhar.healthIdNumber) {
                                                // Function to check for existing identifier in the array
                                                const isDuplicateIdentifier = (identifiers, value) => {
                                                    return identifiers.some(identifier => identifier.value === value);
                                                };

                                                // Initialize ibmp with a fallback for identifier as an empty array
                                                let ibmp = ibm?.identifier
                                                    ? { ...ibm }
                                                    : { ...ibm, identifier: [] };

                                                // Initialize the da object with the spreaded ibmp object and add a new identifier
                                                let da = {
                                                    ...ibmp,
                                                    identifier: [...ibmp.identifier]
                                                };

                                                // Define the new identifier to be added
                                                const newIdentifier1 = {
                                                    system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                                    value: aadhar?.healthIdNumber,
                                                    type: {
                                                        coding: [
                                                            {
                                                                code: 'HIN', // Updated the code to 'HIN' instead of 'JHN'
                                                            },
                                                        ],
                                                    },
                                                };
                                                // Check for duplicate before adding the new identifier
                                                if (!isDuplicateIdentifier(da.identifier, newIdentifier1.value)) {
                                                    da.identifier.push(newIdentifier1);
                                                }
                                                // Define another identifier to be added
                                                const newIdentifier2 = {
                                                    system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                                    value: `${values.username}@sbx`,
                                                    type: {
                                                        coding: [
                                                            {
                                                                code: 'ABHA', // Code for Healthid
                                                            },
                                                        ],
                                                    },
                                                };
                                                // Check for duplicate before adding the new identifier
                                                if (!isDuplicateIdentifier(da.identifier, newIdentifier2.value)) {
                                                    da.identifier.push(newIdentifier2);
                                                }

                                                // Now da.identifier will only include unique identifiers based on value
                                                try {
                                                    axios.put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                                                        headers: {
                                                            Authorization: `Bearer ${token}`,
                                                        },
                                                    });
                                                    handleLink(jwttoken, `${username}@sbx`);
                                                } catch (error) {
                                                    console.log(error.message, 'error in update');
                                                }
                                            } else {
                                                // If the patient doesn't have ABHA number, this function will update the ABHA address in the FHIR server.
                                                handleupdate(`${username}@sbx`);
                                            }
                                            showAlert(res.data.message || 'Data registered Successfully!', 'success');
                                        }

                                    } catch (error) {
                                        // console.log('mobilestep4', error);
                                        let errorMessage = handleRequestError(error);
                                        showAlert(errorMessage, 'error');
                                        setLoading(false)
                                    } finally {
                                        setLoading(false);
                                    }
                                }
                            } else {
                                showAlert('Please enter the mandatory fields *', 'error');
                                setLoading(false)
                            }
                        }}
                    >
                        {/* Rest of your Formik component */}
                        {({ handleSubmit, isValid, values, errors, setFieldTouched, handleChange, handleBlur, touched, isSubmitting }) => (
                            <Box>
                                {/* <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                            backgroundColor: 'whitesmoke',
                                            mt: 2,
                                        }
                                    }}
                                    placeholder={'User Name'}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={values.username}
                                    onChange={(e) => {
                                        handleChange('username')(e); // Handle the change
                                        setAlertmessage(''); // Clear the alert message
                                    }}
                                    onBlur={handleBlur('username')}
                                    autoComplete='off'
                                    InputProps={{
                                        endAdornment: <Typography variant="body1">@abdm</Typography>,
                                    }}
                                // error={Boolean(errors.username)}
                                // helperText={errors.username}
                                />
                                {errors.username && touched.username && (
                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                        {errors.username}</Typography>
                                )} */}
                                <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                            backgroundColor: 'whitesmoke',
                                            mt: 2
                                        }
                                    }}
                                    placeholder={'Password'}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={values.password}
                                    onChange={(e) => {
                                        handleChange('password')(e); // Handle the change
                                        setAlertmessage(''); // Clear the alert message
                                    }}
                                    onBlur={handleBlur('password')}
                                    type={showPassword ? 'text' : 'password'} // Toggle password visibility
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={togglePasswordVisibility}
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                // error={touched.password && Boolean(errors.password)}
                                // helperText={touched.password && errors.password}
                                />
                                {errors.password && touched.password && (
                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                        {errors.password}</Typography>
                                )}
                                {/* <ErrorMessage name="email" component={Typography} variant="body2" style /> */}
                                <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                            backgroundColor: 'whitesmoke',
                                            mt: 2

                                        }
                                    }}
                                    placeholder={'Confirm Password'}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={values.confirmpassword}
                                    onChange={(e) => {
                                        handleChange('confirmpassword')(e); // Handle the change
                                        setAlertmessage(''); // Clear the alert message
                                    }}
                                    onBlur={handleBlur('confirmpassword')}
                                    type={showConfirmPassword ? 'text' : 'password'} // Toggle password visibility
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={toggleConfirmPasswordVisibility}
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                // error={touched.password && Boolean(errors.confirmpassword)}
                                // helperText={touched.confirmpassword && errors.confirmpassword}
                                />
                                {errors.confirmpassword && touched.confirmpassword && (
                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                        {errors.confirmpassword}</Typography>
                                )}
                                {alertmessage && <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />}
                                <Grid mt={2} container justifyContent="center">
                                    <Grid item>
                                        <Button type='submit' sx={{ borderRadius: 3, mt: 2, width: "100%" }} variant="contained" color="primary"
                                            onClick={handleSubmit}
                                            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                                            disabled={!username || !values.password || !values.confirmpassword}
                                        >
                                            {/* {'Create PHR Address'} */}
                                            Create ABHA Address
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Formik>
                </Box>
            </Paper>
        </div >

    )
}

export default CreatePhrAddress;