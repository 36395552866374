import * as React from "react";
import { Box, Paper, Card, Checkbox, OutlinedInput, TextField } from "@mui/material";

export default function TextInputField(props) {
    return (<>
        <TextField
            sx={{
                ".MuiInputBase-root": {
                    borderRadius: 2,
                    backgroundColor: 'whitesmoke',
                    mt: props.mt,

                }, ".MuiOutlinedInput-notchedOutline": {
                    border: props.border
                },
                width: props.width

            }}
            placeholder={props.placeholder}
            size="small"
            fullWidth
            variant="outlined"
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
            name={props.name}
            autoFocus
            autoComplete='off'
            type={props.type}
            disabled={props.disabled}

        />
    </>
    );
}
