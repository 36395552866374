import React, { useEffect } from 'react'
import { ArrowForward, CelebrationOutlined, Close } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Divider, IconButton, Paper, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import URL from '../../../../utility/apiurl'
import { useState } from 'react'
import { localstore } from '../../../../localStore/localStore'
import axios from 'axios'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TokenVerifyLogin from './Tokenverify'

function TokenPhrAddress(props) {
    const { open, setOpen } = props
    const token = localstore.getToken()
    const [alertopen, setAlertopen] = useState(false);
    const [show, setShow] = useState(1)
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const primaryId = localstore.getPrimaryId()
    const [phraddress, setPhraddress] = useState('');
    // console.log(phraddress, "phrrrrrrr")
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const [phdState, setPhdState] = useState(null);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (ibm?.identifier) {
            let phd = ibm.identifier?.filter(e => {
                // if (e?.type?.coding[0]?.code === 'ACSN') {
                if (e?.type?.coding[0]?.code === 'ABHA') {
                    return e.value;
                }
            });
            setPhdState(phd);
            setPhraddress(phd?.length > 0 ? phd[0].value : '');
        }

    }, []);

    const handleloginPhr = () => {

        setLoading(true)
        if (phraddress.length > 0) {
            setLoading(true)
            axios.get(`${URL.abha}login/search/authmodes/${primaryId}/${phraddress}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    gatewayToken: 'medxperts'
                }
            }).then(res => {
                console.log(res, "authmethods")
                // setAlertMessage("")
                const updatedAuthMethods = res.data.authMethods.filter(method => method !== "AADHAAR_BIO" && method !== "DEMOGRAPHICS" && method !== "AADHAAR_OTP");

                dispatch({
                    type: "POPUP_AUTH_METHODS",
                    payload: updatedAuthMethods
                })
                dispatch({
                    type: "POPUP_PHRADDRESS",
                    payload: res.data.phrAddress
                })

                setShow(2)
            }).catch(err => {
                setAlertMessage(err.message)
                setAlertstatus("error")
            }).finally(() => {
                setLoading(false)
            })
        }

    };

    const handleCloseDialog = () => {
        setAlertMessage("your token is expired please login!");
        setAlertstatus("error");
    };
    return (
        <div>

            <Dialog
                open={open}
                fullWidth
                maxWidth="sm"
                onClose={handleCloseDialog}
            >
                {
                    show === 1 ? (
                        <>
                            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, p: '5px 20px' }}>
                                <Typography sx={{ fontWeight: 500, fontSize: { xs: 16, sm: '16px', md: "18px" }, }}>Select Verification methods</Typography>
                                <IconButton onClick={handleClose}><Close sx={{ color: 'red' }} /></IconButton>
                            </DialogTitle>
                            <Divider />
                            <DialogContent>
                                <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>

                                    <Typography>Login Token Expired</Typography>
                                    <Box mt={2}>
                                        <Typography>
                                            Your ABHA / PHR address is
                                        </Typography>
                                        <Typography sx={{ mt: 1, fontSize: { sm: 18, md: 20 }, fontWeight: 600 }}>
                                            {phraddress}
                                        </Typography>

                                        {alertmessage && (
                                            <Typography sx={{ color: alertstatus === 'success' ? 'green' : 'red', mt: 1 }}>{alertmessage}</Typography>
                                        )}
                                    </Box>
                                </Paper>
                            </DialogContent>
                            <Divider />
                            <DialogActions sx={{ justifyContent: 'center', m: 'auto' }}>
                                <Button sx={{ m: "10px 0px", fontWeight: 'bold', width: '120px', borderRadius: 2 }} variant='contained'
                                    onClick={handleloginPhr}
                                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <ArrowForward sx={{ fontWeight: 'bold' }} />}
                                >Login</Button>
                            </DialogActions>
                        </>) : show === 2 ? (
                            <TokenVerifyLogin setOpen={setOpen} setShow={setShow} handleClose={handleClose} popoverclose={props.popoverclose} />
                        ) : null}
            </Dialog>
        </div>
    );
}
export default TokenPhrAddress