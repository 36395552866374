import axios from "axios";
import URL from "../../utility/apiurl";

// Helper function to handle different error responses
export const handleRequestError = (err) => {
    console.log('handleRequestError', err)
    const { status, data } = err.response;
    let message = err.message;
    switch (status) {
        case 400:
        case 404:
            message = parse400ErrorMessage(data);
            break;
        case 500:
            message = data?.error || err.message;
            break;
        case 504:
            message = parse504ErrorMessage(data);
            break;
        case 503:
            message = 'Service Unavailable' || data?.error;
            break;
        case 401:
        case 403:
            message = status === 403 ? UpdateCSRFToken(data) : 'Unauthorized access - Please Try Again.';
            break;
    }
    return message
};

//THIS HANDLEFUCNITON WHICH THE 401 & 400 &500 ERROR MESSAGE WITH THE ...BYTES PARSE ISSUE
export const handleRequestError1 = (err) => {
    console.log(err, 'handleError1')
    const { status, data } = err.response;
    let message = err.message;
    switch (status) {
        case 400:
        case 401:
        case 500:
        case 404:
            message = parse400ErrorMessage(data);
            break;
        case 504:
            message = parse504ErrorMessage(data);
            break;
        case 503:
            message = 'Service Unavailable' || data?.error;
            break;
        case 403:
            message = status === 403 ? UpdateCSRFToken(data) : 'Unauthorized Please Relogin App';
            break;
    }
    return message
};
// Parse error message for 400 status code
const parse400ErrorMessage = (data) => {
    try {

        // Step 1: Check for specific known messages first
        if (data?.message?.includes('401 Unauthorized: [no body]')) {
            return 'Unauthorized access - Please Try Again.';
        }
        // Step 2: Extract the JSON part from the data.message
        const jsonStartIndex = data && data?.message?.indexOf('[');
        // console.log('jsonStartIndex', jsonStartIndex);

        if (jsonStartIndex !== -1) {
            const jsonString = data.message.substring(jsonStartIndex);
            // Step 2: Parse the JSON string into a JavaScript object
            const jsonResponse = JSON.parse(jsonString);
            // Step 3: Try to extract the message using the initial approach
            // console.log('errorMessage1', jsonResponse)
            const errorMessage = jsonResponse[0]?.[0]?.message || jsonResponse[0]?.[0]?.error?.message || jsonResponse[0]?.mobile || jsonResponse[0]?.message;
            // console.log('errorMessage', errorMessage)
            if (errorMessage) {
                // Return the extracted error message
                return errorMessage;
            } else {
                // Fallback to extracting from the full message if the initial extraction fails
                const fallbackErrorMessage = extractErrorMessageFromFullMessage(data.message);
                return fallbackErrorMessage;
            }
        }


    } catch (error) {
        console.error("Error parsing the JSON or extracting the message:", error);
    }
    // Fallback return if all else fails
    return 'Bad Request';
};
// Helper function to extract the error message from the full message string
const extractErrorMessageFromFullMessage = (fullMessage) => {
    // Step 3: Extract the JSON part of the message
    const jsonMatch = fullMessage.match(/\[.*\]/);
    // console.log(jsonMatch, 'jsonMath')
    if (jsonMatch) {
        // Step 4: Parse the JSON string into a JavaScript object
        const jsonString = jsonMatch[0];
        try {
            const parsedJson = JSON.parse(jsonString);
            // console.log(parsedJson, 'parsedJson');

            // Step 5: Extract and return the error message from known structures
            return extractMessageFromKnownStructures(parsedJson);
        } catch (error) {
            console.error("Failed to parse JSON:", error);
        }
    } else {
        console.error("JSON part not found in the message.");
    }

    return 'Bad Request';
};

// Helper function to handle different possible structures in the JSON response
const extractMessageFromKnownStructures = (jsonResponse) => {
    // console.log(jsonResponse[0], 'json3')
    // Structure 1: [{"code":"...", "message":"..."}]
    if (jsonResponse[0]?.message) {
        return jsonResponse[0].message;
    }
    // Structure 2: [{"error": {"code": ..., "message": "..."}}]
    if (jsonResponse[0]?.error?.message) {
        return jsonResponse[0].error.message;
    }
    // Structure 3: [{"details": {"code": ..., "message": "..."}}]
    if (jsonResponse[0]?.details?.message) {
        return jsonResponse[0].details.message;
    }
    // Structure 4: [{{"code": ..., "message": "..."}}]
    if (jsonResponse[0][0]?.message) {
        return jsonResponse[0][0].message;
    }
    // Structure 5: [{{"otp": ..., "otpValue": "..."}}]
    if (jsonResponse[0]?.otpValue || jsonResponse[0].otp) {
        return jsonResponse[0].otpValue || jsonResponse[0].otp;
    }
    if (jsonResponse[0]?.abhaAddress) {
        return jsonResponse[0].abhaAddress;
    }
    if (jsonResponse[0]?.loginId) {
        return "Please Enter Correct Aadhaar Number" || jsonResponse[0].loginId;
    }
    if (jsonResponse[0]?.txnId) {
        return jsonResponse[0].txnId || 'Invalid Transaction Id';
    }
    // Add more known structures if needed
    return 'Bad Request' || null;
};

// Extract content inside <h1> tags for 504 status code
const parse504ErrorMessage = (htmlString) => {
    const h1Regex = /<h1>(.*?)<\/h1>/;
    const match = htmlString.match(h1Regex);
    return match && match[1] ? match[1] : 'Gateway Timeout';
};

//TO UPDATE THE CSFR TOKEN TO 
const UpdateCSRFToken = (data) => {
    getCSRFToken();
    return `${data?.error} please try again later`
}

export const throttleFunction = (fn, d) => {
    return function (...args) {
        setTimeout(() => {
            fn();
        }, d);
    };
};


//TO UPDATE THE FHIR SERVER DATA
export function updateIdentifier(ibmpdata, phradd, code) {
    // Check if ibmpdata has an identifier and default to an empty array if not
    let ibmp = ibmpdata?.identifier
        ? { ...ibmpdata }
        : { ...ibmpdata, identifier: [] };
    // Remove all existing identifiers with the type "HIN"
    ibmp.identifier = ibmp?.identifier?.filter(
        id => id?.type?.coding[0]?.code !== code,
    );
    // Check if the identifier already contains the specified code
    let obj = ibmp?.identifier?.find(e => e?.type?.coding[0]?.code === code);
    // Update or add the identifier based on whether the code is found
    if (obj) {
        // console.log('updating')
        let substitute = ibmp.identifier?.map(e => {
            if (e?.type?.coding[0]?.code === code) {
                return { ...e, value: phradd };
            } else {
                return e;
            }
        });
        return { ...ibmp, identifier: substitute };
    } else {
        // console.log('new')
        return {
            ...ibmp,
            identifier: [
                ...ibmp.identifier,
                {
                    system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                    value: phradd,
                    type: {
                        coding: [
                            {
                                code: code,
                            },
                        ],
                    },
                },
            ],
        };
    }
}
export function updateRelatedIdentifier(ibmpdata, switchprofiles, phradd, fullName, code) {
    const RelatedPerID = ibmpdata?.link ? (ibmpdata?.link[0]?.other?.reference.split('/')[1]) : null;
    const RelatedPerJson = ibmpdata?.link ? switchprofiles?.filter(method => method?.resource?.id === RelatedPerID) : null;
    // Check if ibmpdata has an identifier and default to an empty array if not
    let ibmp = RelatedPerJson?.[0]?.resource?.identifier
        ? { ...RelatedPerJson?.[0]?.resource }
        : { ...RelatedPerJson?.[0]?.resource, identifier: [] };

    // let fullNameData = [
    //     {
    //         ...RelatedPerJson?.[0]?.resource?.name[0],  // Spread the existing name object
    //         family: '',  // Update the 'family' field
    //         given: [fullName],  // Update the 'given' array with the new fullName
    //     },
    // ]
    // Remove all existing identifiers with the type "HIN"
    ibmp.identifier = ibmp?.identifier?.filter(
        id => id?.type?.coding[0]?.code !== code,
    );
    // Check if the identifier already contains the specified code
    let obj = ibmp?.identifier?.find(e => e?.type?.coding[0]?.code === code);
    // Update or add the identifier based on whether the code is found
    if (obj) {
        console.log('updating')
        let substitute = ibmp.identifier?.map(e => {
            if (e?.type?.coding[0]?.code === code) {
                return { ...e, value: phradd };
            } else {
                return e;
            }
        });
        if (fullName !== ' ') {
            return {
                ...ibmp, identifier: substitute, name: [
                    {
                        ...ibmp?.name?.[0],
                        text: fullName
                    }]
            };
        } else {
            return { ...ibmp, identifier: substitute }
        }

    } else {
        console.log('new')
        return {
            ...ibmp,
            identifier: [
                // ...ibmp.identifier,
                {
                    system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                    value: phradd,
                    type: {
                        coding: [
                            {
                                code: code,
                            },
                        ],
                    },
                },
            ],
            name: [
                {
                    ...ibmp?.name?.[0],
                    text: fullName !== ' ' ? fullName : ibmp?.name?.[0]?.family + " " + ibmp?.name?.[0]?.given[0]
                }]
        };
    }
}
export function RemoveRelatedIdentifier(ibmpdata, switchprofiles, code) {
    const RelatedPerID = ibmpdata?.link ? (ibmpdata?.link[0]?.other?.reference.split('/')[1]) : null;
    const RelatedPerJson = ibmpdata?.link ? switchprofiles?.filter(method => method?.resource?.id === RelatedPerID) : null;

    // Check if ibmpdata has an identifier and default to an empty array if not
    let ibmp = RelatedPerJson?.[0]?.resource?.identifier
        ? { ...RelatedPerJson?.[0]?.resource }
        : { ...RelatedPerJson?.[0]?.resource, identifier: [] };

    // Remove all existing identifiers with the type "HIN" (based on code)
    ibmp.identifier = ibmp?.identifier?.filter(
        id => id?.type?.coding[0]?.code !== 'HIN'
    );

    // Check if the identifier exists and remove it if found
    let obj = ibmp?.identifier?.find(e => e?.type?.coding[0]?.code === code);

    if (obj) {
        // If the identifier exists and should be removed, return the updated object without it
        ibmp.identifier = ibmp.identifier.filter(
            e => e?.type?.coding[0]?.code !== code // Remove the identifier with the specified code
        );
    }

    // Return the updated ibmp object after removing the identifier
    return { ...ibmp };
}


//TO Get the csfrToken
export const getCSRFToken = async () => {
    const token = await localStorage.getItem('kctoken');
    // const csrfAbha = await AsyncStorage.getItem('csrfAbha');
    // console.log('token', token)
    // console.log('csrfAbha', csrfAbha)
    try {
        if (token) {
            const response = await axios.get(`${URL.getAbhaCSRF}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('csfrtoken', response)
            await localStorage.setItem('csrfAbha', response.headers['api-req-id']);
        }
    } catch (error) {
        console.log('GET CSRFTOKEN ERROR', error);
    }
};

// Define the truncate function
export const truncateName = (name, maxLength = 30) => {
    if (name.length > maxLength) {
        return name.slice(0, maxLength) + '...';
    }
    return name;
};


export const updateRelatedPersonData = async (updateRelated, id) => {
    const token = await localStorage.getItem('kctoken');
    if (updateRelated) {
        try {
            const response = await axios.put(
                `${URL.cliniapi}RelatedPerson/${id}`,
                updateRelated,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response) throw new Error('Failed to update related person data.');
        } catch (err) {
            console.log(err, 'error')
            // throw new Error(`Error updating related person data: ${err.message}`);
        }
    }
};