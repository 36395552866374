
import React, { useEffect } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import axios from 'axios';
import { useState } from 'react'
import { Box, Button, Card, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material'
import URL, { AadharPublicKey, MobileOrEmailpublicKey, OtpPublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import ResendOtp, { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';
import { encryptData } from '../../../../utility/encryption';
import { handleRequestError, updateIdentifier } from '../../../atoms/helperFunctions';

function HealthNumStep2(props) {
    const abhanumberdetails = useSelector((state) => state.abha.sessionIdandMobilenumber)
    // console.log(abhanumberdetails, "abhaaaaaaaa")

    const ibm = useSelector((state) => state.medx.ibmpdata)
    const transactionId = abhanumberdetails.transactionId
    const HINumber = abhanumberdetails.HINumber
    const authmethod = abhanumberdetails.authmethod
    const [otp, setOtp] = useState('')
    const [time, setTime] = useState(59);
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [transactionid, settransactionId] = useState('')

    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = OtpPublicKey
    const dispatch = useDispatch()

    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertMessage(message);
        setAlertstatus(status);
    };

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);

    useEffect(() => {
        settransactionId(transactionId);
    }, [])

    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertMessage('')
    }

    // const handleVerify = async () => {
    //     setLoading(true)
    //     const encrypt = new JSEncrypt();
    //     encrypt.setPublicKey(publicKey);

    //     const encryptOtp = encrypt.encrypt(otp);
    //     let d = {
    //         "otp": encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
    //         'authCode': "otp",
    //         'transactionId': transactionId,
    //     };
    //     setLoading(true);
    //     console.log('mobilestep2', d);
    //     await axios
    //         .post(`${URL.abha}login/hid/verify/otp/${primaryId}`, d, {
    //             headers: {
    //                 gatewayToken: 'medxperts',
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         })
    //         .then(res => {
    //             console.log('AbhaNumberStep2 validate', res.data);
    //             const data = res.data
    //             setAlert(true);
    //             setAlertMessage('OTP Verified Successfully!');
    //             setAlertstatus('success');

    //             let ibmp = ibm?.identifier
    //                 ? { ...ibm }
    //                 : { ...ibm, identifier: [] };
    //             let da = {
    //                 ...ibmp,
    //                 identifier: [
    //                     ...ibmp.identifier,
    //                     {
    //                         system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
    //                         value: healthIdNumber,
    //                         type: {
    //                             coding: [
    //                                 {
    //                                     // code: 'JHN',
    //                                     code: 'HIN',
    //                                 },
    //                             ],
    //                         },
    //                     },
    //                 ],
    //             };
    //             console.log('------------>>>putapibody', da);

    //             axios
    //                 .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 })
    //                 .then(res => {
    //                     console.log('-----------resdataabhanumber2', res.data);
    //                     let details1 = {
    //                         transactionId: transactionId,
    //                         AbhaNumber: true,
    //                     }
    //                     dispatch({
    //                         type: 'ABHAFLOW_SESSIONID1',
    //                         payload: details1
    //                     })
    //                     dispatch({
    //                         type: 'LOGINABHA_PHRADDRESS',
    //                         payload: data
    //                     })
    //                     props.setShow(3)
    //                     dispatch(getPatientdatafromibm('Patient', primaryId, token))
    //                     setAlertMessage('Data updated Successfully!');
    //                     setAlertstatus('success');
    //                 })
    //                 .catch(err => {
    //                     console.log(err.message, 'error in update');
    //                 });

    //         })
    //         .catch(err => {
    //             console.log(err.message);
    //             setAlert(true);
    //             setAlertMessage('OTP entered is incorrect!');
    //             setAlertstatus('error');

    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };

    // Handler function to  verify the OTP for ABHA Address login || link

    const handleVerifyLogin = async () => {
        setLoading(true);
        if (!(AadharPublicKey && primaryId && token)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }
        const encodedMessage = encryptData(AadharPublicKey, otp);
        let payload = {
            otpValue: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
            authMode: authmethod,
            txnId: transactionid,
        };
        try {
            const response = await axios.post(`${URL.abha}login/hid/verify/otp/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });

            // console.log('AbhaNum   berStep2loginvalidate', response);
            if (response?.data?.authResult === 'failed') {
                showAlert(response?.data.message || 'Entered OTP is incorrect. Kindly re-enter valid OTP', 'error');
            } else if (response.status === 200 || response.status === 201) {
                let PHRAddressData = response.data.mappedPhraddress;
                let xtoken = response.data.token.token
                let updatedData = updateIdentifier(ibm, HINumber, 'HIN');
                await axios
                    .put(`${URL.cliniapi}Patient/${primaryId}`, updatedData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(res => {
                        // console.log('updatedres', res)
                        showAlert(response.data.message || 'OTP Verified Successfully!', 'success');
                        // showAlert(response.data.message || 'Data updated Successfully!', 'success');
                        // navigation.navigate('LoginSelectPhr', {
                        //     transactionId: transactionid,
                        //     phrs: PHRAddressData,
                        //     AbhaNumber: true,
                        //     XToken: xtoken,  
                        // });


                        dispatch({
                            type: 'LOGINABHA_SELECTPHR',
                            payload: {
                                transactionId: transactionid,
                                phrs: PHRAddressData,
                                AbhaNumber: true,
                                XToken: xtoken,
                            }
                        })
                        props.setShow(3)
                    })
                    .catch(err => {
                        console.log(err, 'error in update');
                    });
            }
        } catch (error) {
            // console.log('abhanumber2', error)
            let errorMessage = handleRequestError(error);
            if (errorMessage === 'Bad Request, invalid request Body') {
                errorMessage = 'Please enter a valid OTP. Entered OTP is either expired or incorrect.'
            }
            showAlert(errorMessage, 'error');
        } finally {
            setLoading(false);
        }

    };

    // RESEND OTP TO LOGIN 
    const handleResendOtp = async () => {
        let encodedAbhaNumber = encryptData(MobileOrEmailpublicKey, HINumber)

        let loginpayload = {
            healthIdNumber: encodedAbhaNumber,
            authMode: authmethod === 'aadhaar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
        };
        settransactionId('');
        setOtp('');
        if (!(primaryId && token)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }
        await axios
            .post(`${URL.abha}login/healthIdNumber/auth/${primaryId}`, loginpayload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`
                },
            })
            .then(res => {
                console.log('---------RESDATAABHNUMBER2', res)
                if (res.data.txnId) {
                    showAlert(res.data.message || 'OTP sent to mobile number!', 'success');
                    setTime(59)
                    settransactionId(res.data.txnId);
                }
            })
            .catch(err => {
                let errorMessage = handleRequestError(err);
                showAlert(errorMessage, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };


    // const handleResendOtp = async () => {
    //     setTime(59);
    //     const inputValue = props.abhaNum.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');

    //     let data = {
    //         healthIdNumber: inputValue,
    //         authMethod: props.authMethod,
    //     };

    //     await axios.post(`${URL.abha}search/user/byHealthId/${primaryId}`, data, {
    //         headers: {
    //             gatewayToken: 'medxperts',
    //             Authorization: `Bearer ${token}`,
    //         }
    //     }).then(res => {
    //         console.log(res, "abharesendOtppppp")
    //         setAlertMessage('OTP sent Successfully!');
    //         setAlertstatus('success');
    //         let details = {
    //             transactionId: res.data.transactionId,
    //             number: inputValue,
    //             Mobile: true,
    //         }
    //         dispatch(
    //             {
    //                 type: 'LOGINABHANUM_RESENDOTP_TRANSID',
    //                 payload: details
    //             }
    //         )
    //     })
    //         .catch(err => {
    //             console.log(err.message);
    //             setAlertMessage('Oops something went wrong!');
    //             setAlertstatus('error');

    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    //     // setOtp('')

    // }
    return (
        <div>

            <Box sx={{ mt: { xs: "5px", sm: "10px", md: 1 } }}>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2, md: 2 } }}>
                    <IconButton onClick={() => props.setShow(1)}><ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} /></IconButton>
                    <Typography sx={{ fontSize: { xs: 15, sm: '17px', md: "17px" }, fontWeight: '500', color: '#007DCD' }} >Change Registration Details </Typography>
                </Box>
            </Box>

            <Card sx={{ p: 3, mt: 1, display: 'flex', justifyContent: 'center' }}>
                <Box>
                    <Box mt={1} >
                        <Typography sx={{ fontSize: { xs: 14, md: 16 }, textAlign: "center", color: 'black', ml: 1, fontWeight: 500 }}>
                            Verify OTP
                        </Typography>


                        <Box mt={2}>
                            <TextInputField width="350px" placeholder="Enter OTP Here" border="none" name="otp" value={otp} onChange={handleOnChangeOtp} />
                        </Box>
                        <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} />
                        {alertmessage && (
                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                        )}
                        {/* {alertmessage && (
                        <Typography sx={{ color: alertstatus === 'success' ? 'green' : 'red', mt: 1 }}>{alertmessage}</Typography>
                    )} */}


                        <Box sx={{ mt: 2, textAlign: 'center', mb: 2 }}>

                            <Button variant="contained" sx={{ borderRadius: 2, width: "150px", mt: 1, }} onClick={handleVerifyLogin}
                                disabled={loading || otp?.length < 6} startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}

                            >
                                {/* {loading ? "submit.." : "Submit"} */}Submit
                            </Button>
                        </Box>


                        <Box mt={5}>
                            <Typography sx={{ color: 'black', fontSize: 12 }}>
                                Enter OTP sent on Mobile NO.Associated with ABHA Health ID
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </div>
    )
}

export default HealthNumStep2