import React, { useEffect, useState } from 'react'
import { Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useDispatch } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import URL, { MobileOrEmailpublicKey } from '../../../../utility/apiurl';
import axios from 'axios';
import { ArrowBackIos, Call, Close, ExpandMore } from '@mui/icons-material';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { debounce } from 'lodash';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import VerifyShowPhr from './VerifyShowphr';
import SelectVerification from '../ConnectAbhaFlows/SelectVerification';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { handleErrorResponse } from '../../../atoms/StatusError';
import AbhaLinking from './AbhaLinking';
import { encryptData } from '../../../../utility/encryption';
import { handleRequestError } from '../../../atoms/helperFunctions';
import LoginSelectPhr from '../LoginAbhaFlows/LoginSelectPhr';

function VerifyAadhar() {

    const [show, setShow] = useState(1)
    const [activeButton, setActiveButton] = useState('mobile')
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [checkid, setCheckid] = useState(null);
    const [abhaNum, setAbhaNum] = useState('')
    const [txnId, setTxnId] = useState('')
    const dispatch = useDispatch()
    const [value, setValue] = useState('');

    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate()
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    }
    const handleOnChangeAbha = (e) => {
        const inputValue = e.target.value
        setValue(inputValue);
        if (inputValue.length >= 17) {
            handleCheck(inputValue)
        }
        setAlertmessage('')
    }

    useEffect(() => {
        const formatValue = (inputValue) => {
            // Remove all non-digit characters
            let cleaned = ('' + inputValue).replace(/\D/g, '');

            // Limit to max length of 17 digits
            if (cleaned.length > 17) {
                cleaned = cleaned.substring(0, 17);
            }

            // Apply the formatting: XXXX-XXXX-XXXX
            const formatted = cleaned.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4').trim();
            return formatted;
        };

        const formattedValue = formatValue(value);
        if (value !== formattedValue) {
            setValue(formattedValue);
        }
    }, [value]);

    const handleCheck = async val => {
        let d = {
            healthIdNumber: val,
        };
        if (d.healthIdNumber?.length > 12) {
            await axios
                .post(`${URL.abha}search/user/byHealthId/${primaryId}`, d, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {
                    // console.log(res, 'response')
                    setCheckid(res?.data?.status);
                    showAlert(res.data.message || 'ABHA Number is valid!', 'success');

                })
                .catch(err => {

                    let errorMessage = handleRequestError(err);
                    console.log(errorMessage, 'errormessage');
                    showAlert(errorMessage, 'error');

                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleSend = async () => {
        let encodedAbhaNumber = encryptData(MobileOrEmailpublicKey, value)
        let loginpayload = {
            healthIdNumber: encodedAbhaNumber,
            authMethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
        };
        setLoading(true)
        await axios
            .post(`${URL.abha}login/hid/init/transaction/${primaryId}`, loginpayload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    showAlert(res?.data?.message || 'OTP sent to mobile number!', 'success');
                    dispatch({
                        type: 'VERIFY_KYC_ABHANUMBER',
                        payload: {
                            transactionId: res?.data?.txnId,
                            authmethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
                            HidNumber: value,
                        }
                    })
                    setShow(2)
                }
            })
            .catch(err => {
                let errorMessage = handleRequestError(err);
                showAlert(errorMessage, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };




    return (
        <Box sx={{ mt: { md: 2, xs: 9, sm: 9 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '0px', sm: "0px", md: '30px' } }}>



            {show === 1 ? (
                <>
                    <Box sx={{ mt: { xs: 3, sm: 3, md: 2 } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: 2 },
                            }}
                        >
                            {/* <IconButton onClick={() => history.push('/services/loginabha')}><ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} /></IconButton> */}
                            <Typography
                                sx={{
                                    fontSize: { xs: 14, sm: "16px", md: "17px" },
                                    fontWeight: 600,
                                    ml: 1,
                                    color: '#007DCD'
                                }}
                            >
                                KYC using ABHA Number
                            </Typography>
                        </Box>
                    </Box>

                    <Paper elevation={3}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 1,
                            p: 3,
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 2,
                                }}
                            >
                                <img src={Abhalogo} alt="abha" width={30} height={30} />
                                <Typography sx={{
                                    fontSize: { xs: 14, sm: "16px", md: "17px" },
                                }} >Enter Your 14 ABHA Number</Typography>
                            </Box>

                            <Grid container mt={2} sx={{ textAlign: 'center' }}>
                                <Grid item xs={12}>
                                    <TextInputField
                                        width={{ sm: "350px", md: '350px' }}
                                        placeholder={"ABHA NUMBER"}
                                        name="abhaNum"
                                        border="none"
                                        value={value}
                                        onChange={handleOnChangeAbha}
                                    />
                                    {alertmessage && (
                                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                    )}
                                </Grid>
                                {/* {checkid !== "ACTIVE" && abhaNum.length > 5 ? (
                                    <Typography sx={{ color: "red", fontSize: 14 }}>
                                        {t("abhadoesnotexist")}
                                    </Typography>
                                ) : null} */}

                            </Grid>

                            {/* <Typography
                                    sx={{
                                        color: "#9560D9",
                                        ml: 1,
                                        fontSize: { xs: 12, sm: 13, md: 13 },
                                        mt: "5px",
                                        "&:hover": {
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        },
                                    }}
                                    onClick={() => navigate("/aadharflow", { state: { abhacreate: false } })}
                                >
                                    {t("forgothealthid")}
                                </Typography> */}
                            <Box mt={3} textAlign={"center"}>
                                <Typography sx={{ fontSize: '14px' }}>{t("verifyabha")}</Typography>
                                <ButtonGroup
                                    variant="contained"
                                    aria-label="outlined primary button group"
                                    sx={{ mt: 1 }}
                                >
                                    <Button
                                        sx={{
                                            bgcolor:
                                                activeButton === "aadhar" ? "white" : "#EAE2E2",
                                            '&:hover': {
                                                bgcolor: activeButton === "aadhar" ? "white" : "#EAE2E2",
                                            },
                                            color: "black",
                                        }}
                                        disableElevation
                                        onClick={() => setActiveButton("aadhar")}
                                    >
                                        {t("aadharotp")}
                                    </Button>
                                    <Button
                                        sx={{
                                            bgcolor:
                                                activeButton === "mobile" ? "white" : "#EAE2E2",
                                            "&:hover": {
                                                bgcolor:
                                                    activeButton === "mobile" ? "white" : "#EAE2E2"
                                            },
                                            color: "black",
                                        }}
                                        disableElevation
                                        onClick={() => setActiveButton("mobile")}
                                    >
                                        {t("mobileotp")}
                                    </Button>
                                </ButtonGroup>
                            </Box>

                            <Box sx={{ mt: 2.5, textAlign: "center", }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        borderRadius: 2,
                                        "&:hover": {
                                            bgcolor: "#1CB500",
                                        },
                                        bgcolor: "#1CB500",
                                        width: "150px",
                                    }}
                                    onClick={handleSend}
                                    disabled={loading || value.length < 16}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : null
                                    }
                                >
                                    {/* {loading ? 'Sending...' : 'Send OTP'} */}
                                    {t("credentialsData.signupmobiledata.otpbtntxt")}
                                </Button>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '14px', mt: 2 }}>Don't Remembering having ABHA Number ?</Typography>
                                <Button variant='outlined' sx={{
                                    "&:hover": {
                                        borderColor: "#9560D9",
                                    },
                                    borderColor: "#9560D9",
                                    color: '#9560D9',
                                    borderRadius: '20px',
                                    mt: 2
                                }} onClick={() => navigate("/aadharflow")}

                                >Recover / Create New</Button>
                            </Box>
                            {/* <Box sx={{ textAlign: "center", mt: 3 }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            borderRadius: 2,
                                            "&:hover": {
                                                bgcolor: "#9560D9",
                                            },
                                            bgcolor: "#9560D9",
                                        }}
                                        onClick={() => navigate("/aadharflow")}
                                    >
                                        Create HealthId Number
                                    </Button>
                                </Box> */}
                        </Box>
                    </Paper>
                </>
            ) : show === 2 ? (
                <AbhaLinking
                    setShow={setShow}
                    authMethod={
                        activeButton === "aadhar" ? "AADHAAR_OTP" : "MOBILE_OTP"
                    }
                    setAlertmessage={setAlertmessage}
                />
                // ) : show === 3 ? (
                //     <VerifyShowPhr setShow={setShow} />
            ) : show === 3 ? (
                <LoginSelectPhr setShow={setShow} />
            ) : show === 4 ? (
                <SelectVerification />
            ) : null
            }
        </Box >
    );
}

export default VerifyAadhar