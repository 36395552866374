import React, { useState, useEffect, useRef } from 'react';
import { Button, CircularProgress, Snackbar, Typography, Box, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { localstore } from '../../../../localStore/localStore';
import { handleRequestError } from '../../../atoms/helperFunctions';
import URL from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';

const ViewAbhaCard = () => {
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertStatus, setAlertStatus] = useState('');
    const [fileurl, setFileurl] = useState(null);
    const navigate = useNavigate();
    const location = useLocation()
    const jwttoken1 = location.state.jwttoken1
    const token = localstore.getToken();
    const primaryId = localstore.getPrimaryId()
    const downloadLinkRef = useRef(null);


    const showAlert = (message, status) => {
        setAlertOpen(true);
        setAlertMessage(message);
        setAlertStatus(status);
        setTimeout(() => {
            setAlertOpen(false);
        }, 5000);
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading1(true);
            setTimeout(() => {
                setLoading1(false);
            }, 1500);
        };
        fetchInitialData();
    }, []);

    useEffect(() => {
        const fetchAbhaCardData = async () => {
            setLoading(true);
            try {
                const res = await axios.get(`${URL.abha}aadhaar/profilecard/${primaryId}`, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                        'X-Token': `Bearer ${jwttoken1}`,
                    },
                });
                setFileurl(res.data);
            } catch (error) {
                let errorMessage = handleRequestError(error);
                showAlert(errorMessage, 'error');
            } finally {
                setLoading(false);
            }
        };
        fetchAbhaCardData();
    }, [primaryId, jwttoken1]);

    // const handleDownload = async () => {
    //     try {
    //         const message = await downloadBase64Image(fileurl, 'abhacard', 'png');
    //         showAlert(message, `${message === 'Download Successfully' ? 'success' : 'error'}`);
    //     } catch (error) {
    //         let errorMessage = handleRequestError(error);
    //         showAlert(errorMessage, 'error');
    //     }
    // };

    const handleProfileDownload = () => {
        const element = downloadLinkRef.current;
        element.href = `data:image/png;base64,${fileurl}`;
        element.download = 'abhacard.png';
        element.click();
    };

    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '44px' }, mb: { xs: 10, sm: 10, md: 5 } }}>

            <Box>
                {/* Snackbar for alerts */}
                {/* <Snackbar
                    open={alertOpen}
                    autoHideDuration={5000}
                    onClose={() => setAlertOpen(false)}
                    message={alertMessage}
                    severity={alertStatus === 'error' ? 'error' : 'success'}
                /> */}

                {/* <Box display="flex" alignItems="center" mt={2}>
                    <IconButton onClick={() => navigate(-1)} size="large">
                        <ArrowBackIos />
                    </IconButton>
                    <Typography variant="h6">Create ABHA Number</Typography>
                </Box> */}
                <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, color: '#007DCD', fontWeight: 600, mt: { md: 2, sm: "15px", xs: "10px" } }}>
                    <IconButton onClick={() => navigate(-1)}><ArrowBackIos sx={{ color: '#007DCD' }} /></IconButton>
                    Create / Link ABHA Address
                </Typography>

                {loading1 ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                        <CircularProgress size={60} />
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="center" marginTop="20px">
                        <img
                            src={`data:image/png;base64,${fileurl}` || fileurl}
                            alt="ABHA Card"
                            style={{ maxWidth: '100%', maxHeight: '500px' }}
                        />
                    </Box>
                )}

                <Box display="flex" justifyContent="space-around" marginTop="20px">
                    <a ref={downloadLinkRef} style={{ display: 'none' }} />

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        onClick={handleProfileDownload}
                    >
                        Download
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('/loginabha')}
                    >
                        Login
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ViewAbhaCard;
