import React, { useState } from 'react'
import { ArrowBackIos, Close } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Card, Checkbox, CircularProgress, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import UserIdStep2 from './UserIdStep2';
import URL from '../../../../utility/apiurl'
import { localstore } from '../../../../localStore/localStore'
import axios from 'axios'
import SelectVerification from '../ConnectAbhaFlows/SelectVerification'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp'
import { handleRequestError } from '../../../atoms/helperFunctions'


function UserIdStep1() {
    const navigate = useNavigate()
    const [t, i18n] = useTranslation("global");
    const [show, setShow] = useState(1)

    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    };

    const dispatch = useDispatch()
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setValue('');  // Reset value when switching checkbox

    }
    const handleloginPhr = async () => {
        setLoading(true);
        let entervalue = `${value}@sbx`
        const pattern = /^.+@(sbx|abdm)$/;
        const isValidInput = pattern.test(entervalue);
        if (isValidInput) {
            await axios
                .get(`${URL.abha}login/search/authmodes/${primaryId}/${entervalue}`, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                        // 'X-CSRF-TOKEN': `${csrf}`
                    },
                })
                .then(res => {
                    // console.log('authmethods', res.data.authMethods)
                    showAlert('Successfully Done !', 'success')
                    const updatedAuthMethods = res.data.authMethods.filter(method => method !== "AADHAAR_BIO" && method !== "DEMOGRAPHICS" && method !== "AADHAAR_OTP");
                    dispatch({
                        type: "USERID_AUTH_METHODS",
                        payload: updatedAuthMethods
                    })
                    dispatch({
                        type: "USERID_PHRADDRESS",
                        payload: res.data.abhaAddress
                    })
                    setShow(2)
                })
                .catch(err => {
                    // console.log('-------->>> handlelink error ', err);
                    let errorMessage = handleRequestError(err);
                    showAlert(errorMessage, 'error');
                }).finally(() => {
                    setLoading(false);
                })
        } else {
            setIsValid(false);
            setLoading(false);
        }
    };


    const handleTextChange = (e) => {
        let newValue = e.target.value;

        if (isChecked) {
            // Allow only numeric values and limit length to 14 or 16 digits if checkbox is checked
            newValue = newValue.replace(/[^0-9]/g, '');  // Remove non-numeric characters

            // Ensure the input length is either 14 or 16 digits
            if (newValue.length > 16) {
                newValue = newValue.slice(0, 16);  // Trim the value to 16 digits
            }
        }
        setValue(newValue);
        setIsValid(true);
    };

    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '0px' }, mb: { xs: 10, sm: 10, md: 5 } }}>

            {show === 1 ? (
                <>
                    <Box sx={{ mt: { xs: "5px", sm: "10px", md: "5px" } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: 1 },
                            }}
                        >
                            <IconButton onClick={() => navigate("/loginabha")}>
                                <ArrowBackIos sx={{ color: "#007DCD", cursor: "pointer" }} />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: 15, sm: "16px", md: "17px" },
                                    fontWeight: 600, color: "#007DCD",
                                }}
                            >
                                {t("loginabha")}
                            </Typography>
                        </Box>
                    </Box>
                    <Paper elevation={3}
                        sx={{ p: 3, mt: 1, display: "flex", justifyContent: "center" }}
                    >
                        <Box>
                            <Typography sx={{ textAlign: "center", fontWeight: 600 }}>
                                {t("abhaadd")}
                            </Typography>
                            <Grid container sx={{ mt: 3 }}>
                                <Grid item xs={12}>

                                    <TextField
                                        sx={{
                                            ".MuiInputBase-root": {
                                                borderRadius: 2,
                                                backgroundColor: 'whitesmoke',
                                                mt: 2,
                                            },
                                            ".MuiOutlinedInput-notchedOutline": {
                                                border: 'none'
                                            },
                                            width: { sm: '350px', md: '350px' }
                                        }
                                        }
                                        placeholder={isChecked ? "Enter ABHA Number" : "username@sbx"}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        value={value}
                                        onChange={handleTextChange}
                                        autoComplete='off'
                                        autoFocus={true}
                                        maxLength={isChecked ? 14 : null}
                                        InputProps={{
                                            endAdornment: <Typography variant="body1">@sbx</Typography>,
                                        }} />
                                    {!isValid && (
                                        <Typography sx={{ color: 'red', fontSize: 14 }}>
                                            Please enter a valid input in the format .
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <Checkbox
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <Typography sx={{ fontSize: '14px' }}>Login With ABHA Number of  @sbx</Typography>
                            </Box>

                            <Box sx={{ mt: 5, textAlign: "center" }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        bgcolor: "#9560D9",
                                        width: "150px",
                                        borderRadius: 2,
                                        "&:hover": { bgcolor: "#9560D9" },
                                    }}
                                    disable={loading || value === ''}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : null
                                    }
                                    onClick={handleloginPhr}
                                >
                                    {/* {t("mobileflow.confirm")} */}
                                    Confirm
                                </Button>
                            </Box>

                        </Box>
                    </Paper>
                </>
            ) : show === 2 ? (
                // <UserIdStep2 setShow={setShow} useHealthId={useHealthId} phraddress={phradd} healthIdNumber={healthNumber} />
                <SelectVerification setShow={setShow} />
            ) : null
            }
        </Box >
    );
}

export default UserIdStep1