import axios from "axios";
import URL from "../../utility/apiurl";

export const getPreRegistrationData = (data) => {
    return dispatch => {
        if (data) {
            dispatch({
                type: 'PREREGRISTRATIONDATA',
                payload: data,
            });
        } else {
            dispatch({
                type: 'PREREGRISTRATIONDATA',
                payload: null,
            });
        }
    };
};


export const getPredata = (data) => {
    return dispatch => {
        if (data) {
            dispatch({
                type: 'PREDATA',
                payload: data,
            });
        } else {
            dispatch({
                type: 'PREDATA',
                payload: false,
            });
        }
    };
};

//API TO GET SESSSION ID FOR PASSWORD LOGIN
export const getpasswordsession = (primaryId, token, data) => {
    if (primaryId && token && data) {
        return dispatch => {
            axios
                .post(`${URL.abha}auth/init/${primaryId}`, data, {
                    headers: {
                        // 'X-Token': abhatoken,
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {
                    dispatch({
                        type: 'GETPASSWORDSESS',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    dispatch({
                        type: 'GETPASSWORDSESS',
                        payload: [],
                    });
                });
        };
    } else {
        return dispatch => {
            dispatch({
                type: 'GETPASSWORDSESS',
                payload: [],
            });
        };
    }
};
