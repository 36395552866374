const forge = require('node-forge');
/**
 * Encrypts data using RSA with OAEP padding.
 * @param {string} publicKeyPem - The PEM formatted RSA public key.
 * @param {string} plaintext - The plaintext to encrypt.
 * @returns {string} - The encrypted data as a base64-encoded string.
 */
export const encryptData = (publicKeyPem, plaintext) => {
    if (plaintext === null || plaintext === undefined || plaintext === '' && publicKeyPem === undefined) {
        return null
    } else {
        try {
            // Convert PEM formatted public key to a forge publicKey object
            const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

            // Encrypt the plaintext using RSA/ECB/OAEPWithSHA-1AndMGF1Padding
            const ciphertext = publicKey.encrypt(plaintext, 'RSA-OAEP', {
                md: forge.md.sha1.create(),      // Message digest algorithm
                mgf1: forge.mgf.mgf1.create(forge.md.sha1.create()) // Mask generation function
            });

            // Convert the ciphertext to a base64-encoded string
            return forge.util.encode64(ciphertext);
        } catch (error) {
            console.error('Encryption failed:', error);
            return null;
        }
    }
};

