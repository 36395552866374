const initialState = {

    checkuser: '',
    addpatient: [],

    ibmpdata: [],
    fixeddata: [],
    usernotifications: [],
    addpatientibm: '',

    switchprofile: [],
    linkedprofiles: [],
    primaryprofile: {},
    secondaryprofile: {},
    fixedprofile: {},
    postdata: [],
    feeddata: [],
    count: [],
    getpatientdetails: [],
    loading: false,
    suggestedDoctors: [],
    suggestedOrganisation: [],


    doctordata: [],
    doctorsCount: {},
    orgsCount: {},
    orgdata: [],
    suggestedFollowDoctors: [],
    suggestedFollowOrganizations: [],
    naviagtion: null,
    selectedTab: null,
    registrationdata: null,

};

const medxReducer = (state = initialState, action) => {
    switch (action.type) {


        case 'FINDVALIDUSERNAME':
            return {
                ...state,
                checkuser: action.payload,
            };
        case 'SUGG_DATA':
            return {
                ...state,
                suggestDoctorData: action.payload,
            };

        case 'ADDPATIENT':
            return {
                ...state,
                addpatient: action.payload,
            };

        case 'IBMPDATA':
            // console.log(action.payload, "ibmp")
            return {
                ...state,
                ibmpdata: action.payload,
            };
        case 'ADDPATIENTIBM':
            return {
                ...state,
                addpatientibm: action.payload,
            };
        case 'LINKEDPROFILES':
            return {
                ...state,
                linkedprofiles: action.payload,
            };
        case 'SWITCHPROFILES':
            return {
                ...state,
                switchprofile: action.payload,
            };

        case 'FIXEDDATA':
            return {
                ...state,
                fixeddata: action.payload,
            };

        case 'NOTIFICATION_DATA':
            return {
                ...state,
                usernotifications: action.payload,
            };

        case 'PRIMARY_PROFILE':
            return {
                ...state,
                primaryprofile: action.payload,
            };
        case 'SECONDARY_PROFILE':
            return {
                ...state,
                secondaryprofile: action.payload,
            };
        case 'FIXED_PROFILE':
            return {
                ...state,
                fixedprofile: action.payload,
            };
        case 'POSTBYID':
            return {
                ...state,
                postdata: action.payload,
            };
        case 'CLEARPOST':
            return {
                ...state,
                postdata: action.payload,
                doctordata: action.payload,
                orgdata: action.payload,
            };
        case 'FEED':
            return {
                ...state,
                feeddata: action.payload,
                count: action.count,
                loading: action.loading,
            };

        case 'GETPATIENTDETAILS':
            return {
                ...state,
                getpatientdetails: action.payload,
            };
        case 'SUGGESTED_DOCTORS':
            return {
                ...state,
                suggestedDoctors: action.payload,
            };
        case 'DOCTORDATA':
            return {
                ...state,
                doctordata: action.payload,
            };
        case 'DOCFOLLOWCOUNT':
            return {
                ...state,
                doctorsCount: action.payload,
            };
        case 'ORGFOLLOWCOUNT':
            return {
                ...state,
                orgsCount: action.payload,
            };
        case 'SUGGESTED_ORGANISATION':
            return {
                ...state,
                suggestedOrganisation: action.payload,
            };

        case 'ORGDATA':
            return {
                ...state,
                orgdata: action.payload,
            };

        case 'DOCTORSFOLLOWLIST':
            return {
                ...state,
                suggestedFollowDoctors: action.payload
            }
        case 'ORGSFOLLOWLIST':
            return {
                ...state,
                suggestedFollowOrganizations: action.payload
            }
        case 'Navigation':
            return {
                ...state,
                naviagtion: action.payload
            }
        case 'SELECTTAB':
            return {
                ...state,
                selectedTab: action.payload
            }
        case 'REGRISTRATIONDATA':
            return {
                ...state,
                registrationdata: action.payload
            }
        default:
            return state;
    }
};
export default medxReducer;
