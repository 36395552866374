import React from 'react'
import { Avatar, Box, Button, Card, Checkbox, CircularProgress, Grid, IconButton, MenuItem, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { useState } from 'react'
import URL, { OtpPublicKey } from '../../../../utility/apiurl';
import { useDispatch, useSelector } from 'react-redux';
import ProfileIcon from '../../../../assets/home/profile_assets/Userprofilelogo1.svg'
import TextInputField from '../../../atoms/TextFields/TextInputField';
import _ from 'lodash';
import { useEffect } from 'react';
import { getDistricts, getregistrationdata, getStates } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import EditImg from '../../../../assets/home/Icon feather-edit-4.svg'
import { ArrowBackIos } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import AdornmentTextField from '../../../atoms/TextFields/AdornmentTextField';
import JSEncrypt from 'jsencrypt';
import { encryptData } from '../../../../utility/encryption';

function PreVerifyCreate(props) {
    const [register, setRegister] = useState({
        address: '',
        pinCode: '',
    })
    // const [token, setToken] = useState('');
    const getPhoneNumber = (mobile, updatednumber) => {
        if (updatednumber && updatednumber !== null) {
            return updatednumber;
        } else if (mobile && mobile !== '') {
            return mobile;
        } else {
            return null;
        }
    }
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch();
    const [name, setName] = useState('');

    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = React.useState({});
    const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);
    const [stateError, setStateError] = React.useState(false);
    const [districtError, setDistrictError] = React.useState(false);

    // const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    const result = useSelector((state) => state.phraddress.registrationdetails)
    let predata = useSelector(state => state.phraddress.predata);
    let preverifycreate = useSelector(state => state.phraddress.preverifycreate);
    let preregistrationdata = useSelector(state => state.phraddress.preregistrationdata);
    const { firstName, middleName, lastName, dob, gender, mobile, email, address, photo, districtCode, stateCode, pinCode, stateName, districtName, ABHANumber, yearOfBirth, dayOfBirth, monthOfBirth, profilePhoto, pincode } = preregistrationdata
    const transactionId = preverifycreate.transactionId;
    const updatedNumber = preverifycreate.updatedNumber;
    let districts = useSelector(state => state.abha.districts);
    const sessionId = result.transactionId
    const mobileNumber = result.mobile
    const EmailAddress = result.EmailAddress1
    const authmethod = result.authmethod
    const [autoemail, setAutoEmail] = useState(email !== null ? email : null);
    const [autophone, setAutoPhone] = useState(() => getPhoneNumber(mobile, updatedNumber));
    let states = useSelector(state => state.abha.states);
    const [state, setState] = useState(stateName || '');
    const [district, setDistrict] = useState(districtName || '');
    const [statecode, setStatecode] = useState(stateCode || '');
    const [districtcode, setDistrictcode] = useState(districtCode || '');
    const [districtselect, setDistrictselect] = useState(false);
    const [stateselect, setStateselect] = useState(false);
    const [filteredStates, setFilteredStates] = useState();
    const [filteredDistrict, setFilteredDistrict] = useState();
    const [searchText, setSearchText] = useState('');
    const [hoverStates, setHoverStates] = useState(Array(districts?.length).fill(false));
    const [imageUrl, setImageUrl] = useState(null);
    const [ibmpdata, setIbmpdata] = useState();
    const [key, setKey] = useState('');
    const [age, setAge] = useState('');
    const [gend, setGend] = useState(gender || '')

    const [pincodedata, setPinData] = useState(pinCode || pincode || '');
    const [addressdata, setAddressdata] = useState(address || '');

    let DateofBirth = `${dayOfBirth}-${monthOfBirth}-${yearOfBirth}` || dob

    // console.log(result, "states")
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = OtpPublicKey
    // console.log(ibmid, "abcdef")

    useEffect(() => {
        dispatch(getStates(token))
    }, []);

    let ibm = useSelector((state) => state.medx.ibmpdata);
    console.log(ibm, "registraionpage")
    const handleChangePhone = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setAutoPhone(inputValue.slice(0, 10));
        // setAlertMessage('')
    }
    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
        setFormErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.checkbox; // Clear the state error if any
            return updatedErrors;
        });
    };
    const handleOnChangeRegister = (e) => {
        setRegister((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
        setFormErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.address; // Clear the state error if any
            return updatedErrors;
        });
    }

    const handleOnChangePincode = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        const pincode = inputValue.slice(0, 6);
        setRegister((prevRegister) => ({
            ...prevRegister,
            pinCode: pincode
        }));
        // setAlertMessage('');
        setFormErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.pinCode; // Clear the state error if any
            return updatedErrors;
        });
    }

    useEffect(() => {
        if (ibm) {
            setAge(ibm.birthDate);
            setGend(ibm.gender);
            setName(`${ibm?.name?.[0]?.given?.[0] || ''} ${ibm?.name?.[0]?.family || ''}`);
        }
    }, [ibm]);

    const handleState = (e) => {
        setState(e.name);
        setStatecode(e.code);
        dispatch(getDistricts(e.code, token));
        setDistrict('');
        setStateError(false); // Clear the error when a state is selected


    }
    const handleDistrict = (e) => {
        setDistrict(e.name);
        setDistrictcode(e.code);
        setDistrictError(false); // Clear the error when a state is selected
    };


    const handleOnConfirm = async (e) => {
        // console.log("hello")
        // props.setShow(6)
        const errors = {};

        // Name Validation
        // if (!name.trim()) errors.name = "Name is required.";

        // Phone Validation (Optional)
        const phoneRegex = /^[6-9]\d{9}$/; // 10-digit number starting with 6-9
        if (autophone && !phoneRegex.test(autophone))
            errors.autophone = "Enter a valid 10-digit phone number.";

        // Email Validation (Optional)
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // if (email && !emailRegex.test(autoemail))
        //     errors.autoemail = "Enter a valid email address.";

        // Address Validation
        if (!addressdata.trim())
            errors.addressdata = "Address is required.";

        // Pincode Validation
        const pinRegex = /^\d{6}$/; // 6-digit number
        if (!pincodedata.trim() || !pinRegex.test(pincodedata))
            errors.pincodedata = "Enter a valid 6-digit pincode.";

        if (!state) {
            setStateError(true);
            return;
        }
        if (!district) {
            setDistrictError(true);
            return;
        }

        // Checkbox Validation
        if (!isCheckboxChecked) errors.checkbox = "You must agree to the terms.";

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            console.log("Form submitted successfully.");
            // Call your API or perform desired actions

            setLoading(true)
            e.preventDefault();
            const encodedPhoneNo = encryptData(OtpPublicKey, autophone);
            const encodedEmailAdd = encryptData(OtpPublicKey, autoemail);

            setLoading(true);
            if (addressdata?.length > 0 && statecode && district && pincodedata) {

                let data = {
                    // 'mobile': encryptphone.replace(/\n/g, '').replace(/\r/g, ''),
                    'mobile': autophone === '' || autophone === null || autophone === undefined ? '' : encodedPhoneNo.replace(/\n/g, '').replace(/\r/g, ''),
                    // 'mobileOrAadhaar': 'mobile',
                    // 'mobileOrEmail': 'email',
                    'txnId': transactionId,
                    'authMethod': 'healthIdNumber',
                    'firstName': firstName,
                    'middleName': middleName,
                    'lastName': lastName,
                    'dayOfBirth': DateofBirth.split('-')[0],
                    'monthOfBirth': DateofBirth.split('-')[1],
                    'yearOfBirth': DateofBirth.split('-')[2],
                    'gender': _.capitalize(gend.slice(0, 1)),
                    'stateName': state,
                    'districtName': district,
                    'stateCode': statecode,
                    'districtCode': districtcode,
                    'address': addressdata,
                    'email': autoemail === '' || autoemail === undefined || autoemail === null ? '' : encodedEmailAdd.replace(/\n/g, '').replace(/\r/g, ''),
                    'pinCode': pincodedata,
                };
                dispatch(getregistrationdata(data || []));
                props.setShow(6)
            }
        }
    }

    useEffect(() => {
        if (mobile !== undefined) {
            setAutoPhone(mobile)
        }
        if (email !== undefined || email !== null) {
            setAutoEmail(email)
        }
        if (address !== undefined || address !== null) {
            setAddressdata(address)
        }
        if (pincode || pinCode !== undefined || pincode || pinCode !== null) {
            setPinData(pinCode || pincode)
        }
    }, [])
    const handleEdit = () => {
        // props.setShow(5)
    }

    return (
        <div>
            <Box >

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mt: { xs: "10px", sm: "15px", md: "20px" },
                    }}
                >
                    {/* <IconButton onClick={() => props.setShow(3)}><ArrowBackIos sx={{ color: 'black' }} /></IconButton> */}
                    <Typography sx={{ fontSize: { md: "17px" }, fontWeight: 600, color: '#007DCD' }}>
                        Verify your ABHA Number Registration Details
                    </Typography>
                </Box>
            </Box>

            <Paper elevation={3} sx={{ p: 3, mt: 1.5, display: "flex", justifyContent: "center" }}>
                <Box
                // sx={{
                //     display: "flex",
                //     flexDirection: "column",
                //     alignItems: "center",
                // }}
                >
                    <Grid container alignItems={"center"} sx={{ border: '1px solid whitesmoke', p: "5px", borderRadius: "10px" }}>
                        <Grid item xs={4} sm={3} md={2}>
                            <Box>
                                <Avatar
                                    sx={{ width: "40px", height: "40px", ml: 1 }}
                                    variant="rounded"
                                >
                                    <img
                                        src={`data:image/png;base64,${photo || profilePhoto}`}
                                        alt="media"
                                        style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </Avatar>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={7} md={8}>
                            <Typography
                                sx={{ fontSize: { xs: 16, sm: 16, md: 16 }, fontWeight: 500 }}
                            >
                                {firstName} {lastName}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ fontSize: { xs: 14, md: 14 } }}>
                                    DOB: {dob || DateofBirth}{' '}   {' '}
                                </Typography>
                                <Typography sx={{ fontSize: { xs: 14, md: 14 }, ml: 1 }}>
                                    Sex: {_.capitalize(gender)}
                                </Typography>
                            </Box>
                        </Grid>

                    </Grid>
                    <Box mt={2}>
                        <Box>
                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Mobile Number</label>
                        </Box>
                        <AdornmentTextField
                            placeholder={t("phnumoptinal")}
                            type="text"
                            name="phone"
                            border="none"
                            value={autophone}
                            onChange={handleChangePhone} />
                    </Box>
                    <Box mt={2}>
                        <Box>
                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Email Address</label>
                        </Box>
                        <TextInputField
                            // width="450px"
                            placeholder={t("emailoptnal")}
                            type="text"
                            name="autoemail"
                            border="none"
                            value={autoemail}
                            onChange={(e) => setAutoEmail(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        {/* <Typography ml={1}>{t("address")}</Typography> */}
                        <Box>
                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Address</label>
                            <label style={{ fontWeight: 500, fontSize: '14px', color: 'red' }}>*</label>

                        </Box>
                        <TextInputField
                            // width="450px"
                            placeholder={t("add1")}
                            name="addressdata"
                            border="none"
                            value={addressdata}
                            onChange={handleOnChangeRegister}
                        />
                        {formErrors.address && (
                            <Typography sx={{ color: "red", fontSize: "12px" }}>
                                {formErrors.address}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Box>
                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Pincode</label>
                            <label style={{ fontWeight: 500, fontSize: '14px', color: 'red' }}>*</label>
                        </Box>
                        <Box>
                            <TextInputField
                                // width="450px"
                                placeholder={t("pincode")}
                                // mt={2}
                                name="pincodedata"
                                border="none"
                                value={pincodedata}
                                onChange={handleOnChangePincode}
                            />
                            {formErrors.pinCode && (
                                <Typography sx={{ color: "red", fontSize: "12px" }}>
                                    {formErrors.pinCode}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Box>
                            <label style={{ fontWeight: 500, fontSize: '14px' }}>State</label>
                            <label style={{ fontWeight: 500, fontSize: '14px', color: 'red' }}>*</label>
                        </Box>
                        <TextField
                            sx={{
                                ".MuiInputBase-root": {
                                    borderRadius: 2,
                                    backgroundColor: "whitesmoke",
                                    // mt: 2,
                                },
                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                            }}
                            placeholder={t("relation")}
                            size="small"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                            name={"state"}
                            autoFocus
                            autoComplete="off"
                            select
                            SelectProps={{
                                displayEmpty: true,
                                renderValue: (selected) =>
                                    selected === "" ? t("state") : selected,
                            }}
                        >
                            <MenuItem value="">{t("selstate")}</MenuItem>
                            {states.map((state, i) => (
                                <MenuItem
                                    key={i}
                                    value={state.name}
                                    onClick={() => handleState(state)}
                                >
                                    {state.name}
                                </MenuItem>
                            ))}

                        </TextField>
                        {stateError && (
                            <Typography color="red" sx={{ fontSize: "12px", mt: 0.5 }}>
                                Please select a state.
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ mt: 1 }}>

                        <Box>
                            <label style={{ fontWeight: 500, fontSize: '14px' }}>District</label>
                            <label style={{ fontWeight: 500, fontSize: '14px', color: 'red' }}>*</label>
                        </Box>
                        <TextField
                            sx={{
                                ".MuiInputBase-root": {
                                    borderRadius: 2,
                                    backgroundColor: "whitesmoke",
                                    // mt: 2,
                                },
                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                            }}
                            placeholder="Relation"
                            size="small"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setDistrict(e.target.value)}
                            value={district}
                            name={"district"}
                            autoFocus
                            autoComplete="off"
                            select
                            SelectProps={{
                                displayEmpty: true,
                                renderValue: (selected) =>
                                    selected === "" ? t("district") : selected,
                            }}
                        >
                            <MenuItem value="">{t("seldis")} </MenuItem>
                            {districts.map((district, i) => (
                                <MenuItem
                                    key={i}
                                    value={district.name}
                                    onClick={() => handleDistrict(district)}
                                >
                                    {district.name}
                                </MenuItem>
                            ))}

                        </TextField>
                        {districtError && (
                            <Typography color="red" sx={{ fontSize: "12px", mt: 0.5 }}>
                                Please select a district.
                            </Typography>
                        )}
                    </Box>
                    {/* {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )} */}

                    <Box>
                        <Checkbox
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                        />
                        I hereby, voluntarily share my profile details with NHA for Creating ABHA Address as per
                        <div style={{ color: '#007DCD' }}>user information Agreement</div>
                        {formErrors.checkbox && (
                            <Typography sx={{ color: "red", fontSize: "12px" }}>
                                {formErrors.checkbox}
                            </Typography>
                        )}
                    </Box>
                    <Box mt={4} sx={{ mb: { md: 0, sm: 8, xs: 8 }, textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{ width: "20c0px", height: "35px", borderRadius: 2 }}
                            onClick={handleOnConfirm}
                        // disabled={
                        //     !register.address ||
                        //     !register.pinCode ||
                        //     !stat ||
                        //     !district ||
                        //     !isCheckboxChecked ||
                        //     Object.keys(formErrors).length > 0
                        // }
                        // startIcon={
                        //     loading ? <CircularProgress size={20} color="inherit" /> : null
                        // }
                        >
                            Agree & Continue
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </div >
    );
}

export default PreVerifyCreate;