import React, { useEffect } from 'react'
import { Box, Button, Card, CircularProgress, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { useState } from 'react'
import URL, { AadharPublicKey, MobileOrEmailpublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import ResendOtp, { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';
import { encryptData } from '../../../../utility/encryption';
import { handleRequestError } from '../../../atoms/helperFunctions';
import { useNavigate } from 'react-router-dom';
import { GrCopy } from "react-icons/gr";


function AadharStep2(props) {
    const result = useSelector((state) => state.abha.abhaAadhar)
    let abhadata = useSelector(state => state.abha.abhaprofile);

    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(59);
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [abhaNumberview, setAbhaNumberView] = useState(false)
    const [transactionid, settransactionId] = useState('')

    const navigate = useNavigate()
    // const [phrAddress, setPhraddress] = useState('')
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [number, setNumber] = useState(null)
    const transactionId = result.transactionId
    const publicKey = AadharPublicKey
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    const [AbhaData, setAbhaData] = useState({
        healthIdNumber: null,
        healthId: null
    })

    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        if (!/^\d+$/.test(e.target.value)) {
            setAlertmessage('Only numbers are allowed.');
        } else {
            setAlertmessage('');
        }
    }

    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    }

    const handleLink = async (jwtResponsetoken, phrAddress) => {
        let d = {};
        await axios.post(
            `${URL.abha}link/phrAddress/${primaryId}`,
            d,
            {
                headers: {
                    'X-Token': `Bearer ${jwtResponsetoken}`,
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },

            }).then(res => {
                // console.log(response.data, 'linking successful');
                dispatch(
                    getPatientdatafromibm(
                        'Patient',
                        primaryId,
                        token,
                    ),
                );
                dispatch(getAbhaProfile(primaryId, token));
                setAlertmessage('Data registered Successfully!');
                setAlertstatus('success');
                dispatch({
                    type: 'VERIFY_PHRADDRESS',
                    payload: phrAddress
                })
                props.setShow(7)
            }).catch(err => {
                // console.log(erro.message, 'in linking');
                setAlertopen(true);
                setAlertmessage(err.message);
                setAlertstatus('error');

            })
    };

    const handleVerifyKyc = async () => {
        // props.setShow(3)
        setLoading(true)
        const encodedMessage = encryptData(AadharPublicKey, otp);

        let d = {
            otpValue: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
            txnId: result.transactionId,
            mobile: number,
        };
        await axios.post(`${URL.abha}v2/verifyAadhaarOtp/${primaryId}`, d, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            console.log(res.data, 'kkkkkkkkkkkkk OTP Verified')
            const healthIdNumber = res.data.healthIdNumber
            const jwtResponsetoken = res.data.jwtResponse
            const phrAddress = res.data.HealthId
            // setHidNumber(res.data.healthIdNumber)
            if (res.status === 200 || res.status === 201) {
                if (healthIdNumber) {
                    let ibmp = ibm?.identifier ? { ...ibm } : { ...ibm, identifier: [] };
                    let da = {
                        ...ibmp, identifier: [...ibmp.identifier, {
                            'system': 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                            'value': healthIdNumber,
                            'type': {
                                coding: [
                                    {
                                        code: 'HIN',
                                    },
                                ],
                            },
                        }],
                    };

                    // da.identifier.push({
                    //     system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                    //     value: phrAddress,
                    //     type: {
                    //         coding: [
                    //             {
                    //                 code: 'ABHA', // or any other code you want to use for Healthid
                    //             },
                    //         ],
                    //     },
                    // });


                    axios
                        .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        .then(res => {
                            handleLink(jwtResponsetoken?.token, phrAddress)
                        })
                        .catch(err => {
                            console.log(err.message, 'error in update');
                        });
                }
                else {
                    props.setShow(11)
                }
            }

        }).catch(err => {
            console.log(err);
            setAlertopen(true);
            setAlertmessage('OTP entered is incorrect! or not enter ');
            setAlertstatus('error');
        })
            .finally(() => { setLoading(false); });
        // setOtp('')
    }


    // const handleVerifyOtp = async () => {
    //     setLoading(true)
    //     const encrypt = new JSEncrypt()
    //     encrypt.setPublicKey(publicKey)
    //     const encryptOtp = encrypt.encrypt(otp)
    //     let d = {
    //         'otp': encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
    //         'txnId': result.txnId,
    //         mobileOrAadhaar: 'aadhaar',
    //     };
    //     await axios.post(`${URL.abha}v2/verifyAadhaarOtp/${primaryId}`, d, {
    //         headers: {
    //             gatewayToken: 'medxperts',
    //             Authorization: `Bearer ${token}`,
    //         },
    //     }).then(res => {
    //         setAlertmessage('OTP Verified Successfully!');
    //         setAlertstatus('success');
    //         props.setShow(11)

    //     })
    //         .catch(err => {
    //             console.log(err);
    //             setAlertopen(true);
    //             setAlertmessage('OTP entered is incorrect! or not enter ');
    //             setAlertstatus('error');
    //         })
    //         .finally(() => { setLoading(false); });
    // }
    const handleFindAbhaNumber = async () => {
        setLoading(true);
        if (!(AadharPublicKey && primaryId && token && number)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }
        const encodedMessage = encryptData(AadharPublicKey, otp);
        let payload = {
            otpValue: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
            txnId: transactionid,
            mobile: number,
        };
        try {
            const res = await axios.post(`${URL.abha}v2/verifyAadhaarOtp/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });
            if (res.status === 200 || res.status === 201) {
                // console.log(res.data, 'kkkkkkkkkkkkk');
                let healthIdNumber = res?.data?.ABHAProfile?.ABHANumber || res?.data?.ABHANumber;
                let healthId = res?.data?.ABHAProfile?.HealthId || abhadata?.AbhaAddress;
                setAbhaData({
                    ...AbhaData,
                    healthIdNumber: healthIdNumber,
                    healthId: healthId
                });
                showAlert(res.data.message || 'OTP Verified Successfully!', 'success');
                setAbhaNumberView(true)
                // navigation.navigate('AbhaNumberStep1', { Login: true, HealthIdNumber: healthIdNumber, HealthId: healthId })
            }
        } catch (error) {
            let errorMessage = handleRequestError(error);
            showAlert(errorMessage, 'error');
        } finally {
            setLoading(false);
        }
    };
    const handleVerifyOtp = async () => {
        setLoading(true);
        if (!(AadharPublicKey && primaryId && token && number)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }

        const encodedMessage = encryptData(AadharPublicKey, otp);

        let payload = {
            otpValue: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
            txnId: transactionid,
            mobile: number,
        };
        try {
            const res = await axios.post(`${URL.abha}v2/verifyAadhaarOtp/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });
            if (res.status === 200 || res.status === 201) {
                // console.log('addstep2', res.data);
                let tranId = res?.data?.txnId || transactionid;
                let jwtToken = res?.data?.tokens?.token;
                // dispatch(getPreRegistrationData(res?.data?.ABHAProfile || res?.data || []));
                showAlert(res.data.message || 'OTP Verified Successfully!', 'success');
                // if (res?.data?.ABHAProfile?.ABHANumber || res?.data?.ABHANumber) {
                //   navigation.navigate('AadStep3', {transactionId: tranId, checkNumber: res?.data?.ABHAProfile?.mobile, jwttoken: res?.data?.tokens?.token});
                // }
                if (res?.data?.ABHAProfile?.mobile === number || res?.data?.ABHAProfile?.mobile === null) {
                    showAlert(res?.data?.message || res?.data?.ABHAProfile?.message || 'This mobile number is already registered!', 'success');
                    setTimeout(() => {
                        setTimeout(() => {
                            // dispatch(getABHAadhaarCard(token, primaryid, jwttoken))
                            // navigation.navigate('ViewAbhaCard', { jwttoken1: jwttoken })
                            navigate('/createaddressabhano', { state: { transactionId: tranId, jwttoken1: jwtToken } })
                        }, 500);
                        // dispatch(getABHAadhaarCard(token, primaryid, jwttoken))
                        // navigation.navigate('ViewAbhaCard', { jwttoken1: jwttoken })
                        // navigation.navigate('CreateAddressWithAbhaNo', { transactionId: tranId, jwttoken1: jwtToken })
                    }, 500);
                } else {
                    const encodedMessage = encryptData(MobileOrEmailpublicKey, number)
                    let data = {
                        mobile: encodedMessage?.replace(/\n/g, '') || '',
                        txnId: tranId,
                    };
                    axios
                        .post(`${URL.abha}v2/checkAndGenerateMobileOtp/${primaryId}`, data, {
                            headers: {
                                gatewayToken: 'medxperts',
                                Authorization: `Bearer ${token}`,
                                // 'X-CSRF-TOKEN': `${csrf}`
                            },
                        })
                        .then(async res => {
                            // console.log('-------->>>> resData', res);
                            // setChecklinked(res?.data?.mobileLinked);
                            showAlert(res?.data?.message || 'OTP sent to mobile number', 'success');

                            dispatch({
                                type: "AADHARSTEP4",
                                payload: {
                                    transactionId: res?.data?.txnId, mobile: number, jwttoken1: jwtToken
                                }
                            })
                            // navigation.navigate('AadStep4', { transactionId: res?.data?.txnId, mobile: number, jwttoken1: jwtToken });
                            props.setShow(12)
                        })
                        .catch(err => {
                            // console.log(err, 'checkAndGenerateMobileOtp');
                            let errorMessage = handleRequestError(err);
                            showAlert(errorMessage, 'error');
                        })
                }
            }
        } catch (error) {
            // console.log(error, 'errorAadstep2');
            let errorMessage = handleRequestError(error);
            // Check if the error message contains the specific prefix we want to remove
            const prefix = 'UIDAI Error code : 400 : ';
            if (errorMessage?.startsWith(prefix)) {
                // Remove the prefix from the error message
                errorMessage = errorMessage?.slice(prefix?.length)
            }
            showAlert(errorMessage || 'Invalid Aadhaar OTP please Enter Correct Value', 'error');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);

    useEffect(() => {
        settransactionId(transactionId);
    }, [])



    const handleResendOtp = async () => {
        setOtp('')
        if (!(primaryId && token)) {
            showAlert('Something Went Wrong', 'error');
            return;
        }

        let data = props.aadharNumber.replace(/ /g, '').replace(/-/g, '');
        const encodedMessage = encryptData(AadharPublicKey, data);
        const payload = {
            aadhaar: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
        };
        try {
            const response = await axios.post(`${URL.abha}v2/generateAadhaarOtp/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });
            if (response.status === 200 || response.status === 201) {
                setTime(59)
                showAlert(response?.data?.message || 'OTP sent to Aadhaar Regristration Mobile Number !', 'success');
                settransactionId(response.data.txnId);
            }
        } catch (error) {
            let errorMessage = handleRequestError(error);
            showAlert(errorMessage, 'error');
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(AbhaData.healthIdNumber);
        alert('Health ID Number copied!');
    };

    const handleLoginClick = () => {
        navigate('/loginabhanumber', { state: { LoginFlow: true, HealthIdNumber: AbhaData?.healthIdNumber, HealthId: AbhaData?.healthId } })
    }

    return (
        <div>
            <Box sx={{ mt: { xs: "5px", sm: 1, md: 2 } }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: 1, sm: 2, md: 1 },
                    }}
                >
                    <IconButton onClick={() => {
                        props.setShow(1)
                        props.setAlertmessage('')
                    }}>
                        <ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: 17, sm: "17px", md: "17px" }, color: '#007DCD', fontWeight: 600 }}>
                        {" "}
                        {/* {t("createaadharnum")}{" "} */}
                        Create ABHA using Aadhaar Number
                    </Typography>
                </Box>
            </Box>

            <Paper elevation={3} sx={{ mt: { md: 1, sm: 1, xs: 1 }, p: 3, display: "flex", justifyContent: "center" }}>

                {
                    abhaNumberview ?
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                                Your ABHA NUMBER / HealthIdNumber is
                            </Typography>

                            <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3 }}>
                                    {AbhaData?.healthIdNumber}<span onClick={copyToClipboard} style={{ color: 'green' }} >
                                        <GrCopy size={24} />
                                    </span>
                                </Typography>
                                {/* <IconButton onClick={copyToClipboard} sx={{ marginTop: 1 }}>
                                </IconButton> */}
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                    sx={{ mt: 3, padding: '10px', width: '150px', }}
                                    disabled={loading}
                                    onClick={handleLoginClick}
                                >
                                    {loading ? 'Loading...' : 'Login'}
                                </Button>
                            </Box>
                        </Box> :
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: { xs: 14, md: 16 },
                                    fontWeight: 500,
                                    textAlign: "center",
                                }}
                            >
                                {t("verifyotp")}
                            </Typography>

                            <Box>
                                <TextInputField
                                    placeholder="Enter OTP Here"
                                    mt={2}
                                    name="otp"
                                    border="none"
                                    value={otp}
                                    onChange={handleOnChangeOtp}
                                />
                                {alertmessage && (
                                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                )}

                                <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} />
                                {/* <Typography
                            sx={{
                                fontStyle: "inherit",
                                textAlign: "center",
                                fontSize: { xs: 14, md: 14 },
                                fontWeight: 400,
                                mt: 2,
                            }}
                        >
                            {t("enterotpsndtoaadhar")} {result.mobileNumber}
                        </Typography> */}
                                <Typography
                                    sx={{
                                        fontStyle: "inherit",
                                        textAlign: "center",
                                        fontSize: { xs: 14, md: 14 },
                                        fontWeight: 400,
                                        mt: 2,
                                    }}
                                >
                                    This mobile number will be used for all the communications related to ABHA
                                </Typography>

                                {/* <TextInputField
                            placeholder="Mobile Number"
                            mt={2}
                            name="number"
                            border="none"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        /> */}
                                <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                            backgroundColor: "whitesmoke",
                                            mt: 2,
                                        },
                                        ".MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                        width: "100%",
                                    }}
                                    placeholder={"Enter your Mobile Number"}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => setNumber(e.target.value)}
                                    value={number}
                                    name="number"
                                    autoFocus
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    variant="outlined"
                                                    sx={{
                                                        borderRadius: 2,
                                                        backgroundColor: "white",
                                                        height: "32px",
                                                    }}
                                                >
                                                    +91
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                                    <Button
                                        variant="contained"
                                        sx={{ borderRadius: 2, width: "150px", mt: 1 }}
                                        onClick={() => props.abhacreate === true ? handleVerifyOtp() : handleFindAbhaNumber()}
                                        disabled={loading || otp?.length < 6}
                                        startIcon={
                                            loading ? (
                                                <CircularProgress size={20} color="inherit" />
                                            ) : null
                                        }
                                    >
                                        {/* {t("verify")} */}
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                }
            </Paper>
        </div>
    );
}

export default AadharStep2