
import React, { useEffect } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import axios from 'axios';
import { useState } from 'react'
import { Box, Button, Card, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material'
import URL, { AadharPublicKey, OtpPublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaCard, getAbhaProfile, getAbhaQRCode, getPatientdatafromibm } from '../../../../redux/Actions/Actions';

import { useTranslation } from "react-i18next";
import ResendOtp, { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { handleErrorResponse } from '../../../atoms/StatusError';
import JSEncrypt from 'jsencrypt';
import { handleRequestError, updateIdentifier, updateRelatedIdentifier, updateRelatedPersonData } from '../../../atoms/helperFunctions';
import { encryptData } from '../../../../utility/encryption';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

function AbhaLinking(props) {
    const navigate = useNavigate()
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const abhanumberlinkdetails = useSelector((state) => state.phraddress.abhanumberlinkdetails)
    // const abhanumberdetails = useSelector((state) => state.abha.sessionIdandMobilenumber)
    console.log(abhanumberlinkdetails, "abhanumber")
    const abhadata = useSelector((state) => state.abha.abhaprofile)

    const switchprofiles = useSelector(stat => stat.medx.switchprofile?.response?.entry);
    const transactionId = abhanumberlinkdetails.transactionId
    const HealthId = abhanumberlinkdetails.HealthId
    const authmethod = abhanumberlinkdetails.authmethod
    const HidNumber = abhanumberlinkdetails.HidNumber
    const pid = localstore.getPatientId()
    const [otp, setOtp] = useState('')
    const [time, setTime] = useState(59);
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [newtransactionid, setNewtransactionId] = useState('');
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()

    //----------------------------------->>>>TO EXTRACT THE ABHA ADDRESS FROM THE IBM FHIRSERVER <<<--------------------------------------
    const abhaIdentifier = ibm && Array.isArray(ibm?.identifier) && ibm?.identifier?.find(item => {
        return item?.type?.coding?.some(coding => coding?.code === "ABHA");
    });

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);

    useEffect(() => {
        setNewtransactionId(transactionId);
        dispatch(getAbhaProfile(primaryId, token))
    }, [])
    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }
    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    };

    //----------------------------------->>>>TO ABHA ADDRESS WITH ABHA NUMBER <<<--------------------------------------
    const handleLink = async (newtransactionid) => {
        let d = {
            transactionId: newtransactionid,
        };
        try {
            const response = await axios.post(
                `${URL.abha}link/hid/phrAddress/${primaryId}`,
                d,
                {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            showAlert(response?.data?.message || 'ABHA Number is securely linked to ABHA Address', 'success')

            let ibmp = ibm?.identifier
                ? { ...ibm }
                : { ...ibm, identifier: [] };

            // Remove all existing identifiers with the type "HIN"
            ibmp.identifier = ibmp?.identifier?.filter(id => id?.type?.coding[0]?.code !== 'HIN');

            // Add the latest identifier with the type "HIN"
            ibmp.identifier.push({
                system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                value: HidNumber,
                type: {
                    coding: [{ code: 'HIN' }]
                }
            });

            let da = { ...ibmp };

            let profile = 'Patient';
            await axios
                .put(`${URL.cliniapi}${profile}/${primaryId}`, da, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {
                    dispatch(
                        getPatientdatafromibm(
                            'Patient',
                            primaryId,
                            token,
                        ),
                    );
                    dispatch(getAbhaProfile(primaryId, token));
                    showAlert('Data Registered Sucessfully', 'success')
                    handleloginPhr(abhadata?.abhaAddress || abhaIdentifier?.value)
                })
                .catch(err => {
                    // console.log(err, 'error in update');
                });
        } catch (err) {
            // console.log(err, 'ERROR IN THE LINKING');
            let errorMessage = handleRequestError(err);
            showAlert(errorMessage, 'error');
        }
    };
    //----------------------------------->>>>LOGIN WITH ABHA ADDRESS TO UPDATE THE TOKEN TO GET ALL DETAILS <<<--------------------------------------
    const handleloginPhr = async (phraddress) => {
        await axios
            .get(`${URL.abha}login/search/authmodes/${primaryId}/${phraddress}`, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                showAlert(res.data.message || 'Successfully Done!', 'success');
                const updatedAuthMethods = res?.data?.authMethods?.filter(method => method !== "AADHAAR_BIO" && method !== "DEMOGRAPHICS" && method !== "AADHAAR_OTP");

                dispatch({
                    type: "ABHA_LINK_AUTH_METHODS",
                    payload: updatedAuthMethods
                })
                dispatch({
                    type: "ABHA_LINK_SELECTOPTIONS",
                    payload: res.data.abhaAddress
                })
                setLoading(false);

                props.setShow(4)
            })
            .catch(err => {
                let errorMessage = handleRequestError(err);
                showAlert(errorMessage, 'error');
                setLoading(false);
            });
    };

    //----------------------------------->>>>TO VERIFY THE OTP <<<--------------------------------------
    const handleVerify = async () => {
        const encodedMessage = encryptData(AadharPublicKey, otp);
        if (!(OtpPublicKey && primaryId && token)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }
        let payload = {
            otp: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
            transactionId: newtransactionid,
            authMethod: authmethod
        };
        setLoading(true);
        try {
            const response = await axios.post(`${URL.abha}login/hid/confirm-init/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200 || response.status === 201) {
                await handleLink(newtransactionid);
            }
        } catch (error) {
            let errorMessage = handleRequestError(error);
            showAlert(errorMessage, 'error');
        } finally {
            setLoading(false);
        }



    };


    const handleResendOtp = async () => {
        setTime(59);
        let data = {
            transactionId: transactionId,
        };

        await axios.post(`${URL.abha}hid/init/resend/otp/${primaryId}`, data, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {


            console.log(res, "abharesendOtppppp")
            setAlert(true);
            setAlertmessage(t("otpsentsuccessfully"));
            setAlertstatus('success');
        })
            .catch(err => {
                console.log(err.message);
                setAlert(true);
                setAlertmessage(t("errormsg"));
                setAlertstatus('error');
            })
            .finally(() => {
                setLoading(false);
            });

        setOtp('')
    }
    return (
        <div>
            <Box sx={{ mt: { xs: 3, sm: 2, md: 1 } }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: 1, sm: 2, md: 2 },
                    }}
                >
                    <IconButton onClick={() => {
                        props.setShow(1)
                        props.setAlertmessage('')
                    }}>
                        <ArrowBackIos sx={{ color: "#007DCD", cursor: "pointer" }} />
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: { xs: 14, sm: "16px", md: "18px" },
                            fontWeight: "500",
                            color: "#007DCD",
                        }}
                    >
                        {t("changeregisterdetails")}{" "}
                    </Typography>
                </Box>
            </Box>

            <Card sx={{ p: 3, mt: 1, display: "flex", justifyContent: "center" }}>
                <Box>
                    <Box mt={1}>
                        <Typography
                            sx={{
                                fontSize: { xs: 14, md: 16 },
                                textAlign: "center",
                                color: "black",
                                ml: 1,
                                fontWeight: 500,
                            }}
                        >
                            {t("verifyotp")}
                        </Typography>

                        <Grid container mt={2}>
                            <Grid item xs={12}>
                                <TextInputField
                                    width={{ sm: "350px", md: "350px" }}
                                    placeholder={t("enterotphere")}
                                    border="none"
                                    name="otp"
                                    value={otp}
                                    onChange={handleOnChangeOtp}
                                />
                            </Grid>
                        </Grid>
                        {/* {alertmessage && (
                        <Typography sx={{ color: alertstatus === 'error' ? 'red' : 'green', ml: 1 }}>{alertmessage}</Typography>

                    )} */}
                        {alertmessage && (
                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                        )}

                        {/* <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} /> */}

                        <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                            <Button
                                variant="contained"
                                sx={{ borderRadius: 2, width: "150px", mt: 1 }}
                                onClick={handleVerify}
                                disabled={loading || otp?.length < 6}
                                startIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="inherit" />
                                    ) : null
                                }
                            >
                                {/* {loading ? "submit.." : "Submit"} */}
                                {t("credentialsData.signupmobiledata.submitbtn")}
                            </Button>
                        </Box>

                        <Box mt={5}>
                            <Typography sx={{ color: "black", fontSize: 12 }}>
                                {t("enterotpsenttomb")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </div>
    );
}

export default AbhaLinking