import React, { useEffect, useState } from 'react'
import { Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import URL, { MobileOrEmailpublicKey } from '../../../../utility/apiurl';
import axios from 'axios';
import { ArrowBackIos } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { useTranslation } from "react-i18next";
import AbhaNumStep2 from './AbhaNumStep2';
import SelectPhrAddress from './SelectPhrAddress';
import CreatePhrAddress from './CreatePhrAddress';
import PhrAddressScreen from './PhrAddressScreen';
import SelectVerification from './SelectVerification';
import { handleRequestError } from '../../../atoms/helperFunctions';
import { encryptData } from '../../../../utility/encryption';
import PreVerifyCreate from './preVerifyCreate';
import { getPatientdatafromibm } from '../../../../redux/Actions/Actions';


function AbhaNumStep1() {
    const navigate = useNavigate()
    const [show, setShow] = useState(1)
    const [activeButton, setActiveButton] = useState('mobile')
    let abhadata = useSelector(state => state.abha.abhaprofile);
    const location = useLocation()
    const LoginFlow = location.state.LoginFlow
    const newString = (abhadata?.AbhaNumber) ? (abhadata?.AbhaNumber) : null;
    const [value, setValue] = useState(newString || null);

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [checkid, setCheckid] = useState(null);
    const [abhaNum, setAbhaNum] = useState('')
    const dispatch = useDispatch()
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const handleOnChangeAbha = (e) => {
        // const inputValue = e.target.value.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');
        const inputValue = e.target.value
        setValue(inputValue);
        if (inputValue.length >= 17) {
            handleCheck(inputValue)
        }
        setAlertmessage('')
    }

    useEffect(() => {
        // console.log(fixedid, primaryid, token, '+++++++++++++++++++<<<<<<<<<<<<<<<<<<<');

        if (token && primaryId) {
            dispatch(
                getPatientdatafromibm(
                    'Patient',
                    primaryId,
                    token,
                ),
            );
        }

    }, [token, primaryId]);
    useEffect(() => {
        const formatValue = (inputValue) => {
            // Remove all non-digit characters
            let cleaned = ('' + inputValue).replace(/\D/g, '');

            // Limit to max length of 17 digits
            if (cleaned.length > 17) {
                cleaned = cleaned.substring(0, 17);
            }

            // Apply the formatting: XXXX-XXXX-XXXX
            const formatted = cleaned.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4').trim();
            return formatted;
        };

        const formattedValue = formatValue(value);
        if (value !== formattedValue) {
            setValue(formattedValue);
        }
    }, [value]);
    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    };

    const handleCheck = async val => {
        let d = {
            healthIdNumber: val,
        };
        if (d.healthIdNumber?.length >= 17) {
            await axios
                .post(`${URL.abha}search/user/byHealthId/${primaryId}`, d, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                        // 'X-CSRF-TOKEN': `${csrf}`
                    },
                })
                .then(res => {
                    setCheckid(res.data.status);
                    showAlert(res.data.status, 'success');
                })
                .catch(err => {
                    // console.log('checkerror in abha login', err);
                    const errorMessage = handleRequestError(err)
                    showAlert(errorMessage, 'error');
                })
                .finally(() => {
                    setLoading(false);
                    // setCheckid('');
                });
        }
    };

    const handleSend = async () => {
        // navigation.navigate('AbhaNumberStep2')
        const inputValue = abhaNum.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');

        setLoading(true);
        let d = {
            healthIdNumber: inputValue,
        };
        let d1 = {
            healthIdNumber: inputValue,
            authMethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
        };

        try {
            // First API call
            const searchResponse = await axios.post(`${URL.abha}search/user/byHealthId/${primaryId}`, d, {
                headers: {
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },
            });

            console.log('--------->>> searchapi', searchResponse.data);
            setCheckid(searchResponse.data.status);

            if (searchResponse.status === 200 || searchResponse.status === 201) {
                // Second API call
                setLoading(true); // Set loading for the second API call
                const res = await axios.post(`${URL.abha}registration/hid/init/transaction/${primaryId}`, d1, {
                    headers: {
                        'gatewayToken': 'medxperts',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setAlertmessage('OTP sent to mobile number!');
                setAlertstatus('success');
                setShow(2);

                let details = {
                    transactionId: res.data.transactionId,
                    number: inputValue,
                };
                dispatch({
                    type: 'ABHANUMBER_TRANSID',
                    payload: details,
                });
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setAlertmessage("ABHA Number not Found or No ABHA address linked with this ABHA number");
                setAlertstatus('error');
            } else {
                setAlertmessage(err.message);
                setAlertstatus('error');
            }
        } finally {
            setLoading(false); // Reset loading state after both API calls
        }
    };

    let encodedAbhaNumber = encryptData(MobileOrEmailpublicKey, value)
    let payload = {
        healthIdNumber: encodedAbhaNumber,
        authMethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
    };
    //HANDLER FUCNTION TO CREATE THE ABHA ADDRESS WITH HEALTH NUMBER OR ABHA NUMBER
    const handlecreateAbha = async () => {
        setLoading(true)
        await axios
            .post(`${URL.abha}registration/hid/init/transaction/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`
                },
            })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    // console.log('responseAbhaNumber', res.data)
                    showAlert(res.data.message || 'OTP sent to Mobile Number!', 'success');
                    // navigation.navigate('AbhaNumberStep2', {
                    //     transactionId: res.data.txnId,
                    //     HINumber: value,
                    //     login: LoginFlow,
                    //     encrypthid: encodedAbhaNumber,
                    //     authmethod: selectedType === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
                    // });
                    dispatch({
                        type: 'ABHANUMBER_TRANSID',
                        payload: {
                            transactionId: res.data.txnId,
                            HINumber: value,
                            login: LoginFlow,
                            encrypthid: encodedAbhaNumber,
                            authmethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
                        },
                    });
                    setShow(2)
                }
            })
            .catch(err => {
                let errorMessage = handleRequestError(err);
                showAlert(errorMessage, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    //HANDLER FUCNTION TO LOGIN WITH  HEALTH NUMBER OR ABHA NUMBER
    let loginpayload = {
        healthIdNumber: encodedAbhaNumber,
        authMode: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
    };
    const handlelogin = async () => {
        await axios
            .post(`${URL.abha}login/healthIdNumber/auth/${primaryId}`, loginpayload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`
                },
            })
            .then(res => {
                // console.log('---------RESDATAABHNUMBER', res)
                if (res.status === 200 || res.status === 201) {
                    showAlert(res?.data?.message || 'OTP sent to mobile number!', 'success');
                    // if (HealthId) {
                    //     navigation.navigate('AbhaNumberLinking', {
                    //         HINumber: value,
                    //         transactionId: res.data.txnId,
                    //         authmethod: selectedType === 'aadhar' ? 'aadhaar' : 'mobile',
                    //         encrypthid: encodedAbhaNumber,
                    //         HealthId: HealthId,
                    //     });
                    // } else {
                    //     navigation.navigate('AbhaNumberStep2', {
                    //         transactionId: res.data.txnId,
                    //         HINumber: value,
                    //         login: LoginFlow,
                    //         encrypthid: encodedAbhaNumber,
                    //         authmethod: selectedType === 'aadhar' ? 'aadhaar' : 'mobile',
                    //     })
                    // }
                    dispatch({
                        type: 'ABHANUMBER_TRANSID',
                        payload: {
                            transactionId: res.data.txnId,
                            HINumber: value,
                            login: LoginFlow,
                            encrypthid: encodedAbhaNumber,
                            authmethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
                        },
                    });
                    setShow(2)
                }
            })
            .catch(err => {
                let errorMessage = handleRequestError(err);
                if (errorMessage === 'User not found') {
                    showAlert('ABHA Number not found', 'error');
                } else {
                    showAlert(errorMessage, 'error');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (value) {
            setCheckid('ACTIVE')
        }
    }, [])

    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '44px' }, mb: { xs: 10, sm: 10, md: 5 } }}>

            {show === 1 ? (
                <>
                    <Box sx={{ mt: { xs: "5px", sm: "10px", md: 2 } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: 2 },
                            }}
                        >
                            <IconButton
                                onClick={() => navigate("/connecthealthid")}
                            >
                                <ArrowBackIos sx={{ color: '#007DCD' }} />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: 15, sm: "16px", md: "16px" },
                                    fontWeight: 600,
                                    color: '#007DCD'
                                }}
                            >
                                Pre-verified 14-Digit ABHA Number
                            </Typography>
                        </Box>
                    </Box>
                    <Paper elevation={3}
                        sx={{
                            textAlign: "center",
                            mt: 1,
                            p: 3,
                            boxShadow: "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                            }}
                        >
                            <img src={Abhalogo} alt="abha" width={30} height={30} />
                            <Typography sx={{
                                fontSize: { xs: 14, sm: "16px", md: "16px" },
                            }}>
                                {/* {t("healthid14")} */}
                                Enter Your 14-digit ABHA Number

                            </Typography>
                        </Box>
                        <Grid container mt={2}>
                            <Grid item xs={12}>
                                <TextInputField
                                    width={{ sm: "350px", md: "350px" }}
                                    placeholder="Enter 14-Digit ABHA  NUMBER"
                                    name="number"
                                    border="none"
                                    value={value}
                                    onChange={handleOnChangeAbha}
                                    disabled={abhadata?.AbhaNumber !== null && abhadata?.AbhaNumber !== undefined && abhadata?.AbhaNumber !== ''}
                                />
                                {alertmessage && (
                                    <Typography
                                        sx={{
                                            color: alertstatus === "success" ? "green" : "red",
                                            mt: 1,
                                            fontSize: 13,
                                        }}
                                    >
                                        {alertmessage}
                                    </Typography>
                                )}
                                {/* {checkid !== "ACTIVE" && abhaNum.length > 5 ? (
                                    <Typography sx={{ color: "red", fontSize: 13 }}>
                                        Abha Health Id Number is not exist please check it.
                                    </Typography>
                                ) : null} */}
                            </Grid>



                        </Grid>
                        <Box mt={3}>
                            <Typography>Verify ABHA using</Typography>
                        </Box>

                        <ButtonGroup
                            variant="contained"
                            aria-label="outlined primary button group"
                            sx={{ mt: 1 }}
                        >
                            <Button
                                sx={{
                                    bgcolor: activeButton === "aadhar" ? "white" : "#EAE2E2",
                                    color: "black",
                                    "&:hover": {
                                        bgcolor: activeButton === "aadhar" ? "white" : "#EAE2E2",
                                    }
                                }}
                                disableElevation
                                onClick={() => setActiveButton("aadhar")}
                            >
                                Aadhar OTP
                            </Button>
                            <Button
                                sx={{
                                    bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2",
                                    '&:hover': { bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2" },

                                    color: "black",
                                }}
                                disableElevation
                                onClick={() => setActiveButton("mobile")}
                            >
                                Mobile OTP
                            </Button>
                        </ButtonGroup>
                        <Box sx={{ mt: 4, textAlign: "center", mb: 2 }}>
                            <Button
                                variant="contained"
                                sx={{
                                    borderRadius: 2,
                                    "&:hover": {
                                        bgcolor: "#1CB500",
                                    },
                                    bgcolor: "#1CB500",
                                    width: "150px",
                                }}
                                onClick={handlecreateAbha}
                                disabled={loading || value?.length < 14}
                                startIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="inherit" />
                                    ) : null
                                }
                            >
                                {/* {loading ? 'Sending...' : 'Send OTP'} */}
                                Send OTP
                            </Button>
                            <Box sx={{ mt: 4, textAlign: "center", mb: 2 }}>
                                <Typography>Don't Remembering having ABHA Number?</Typography>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 2,
                                        color: '#9560D9',
                                        borderColor: '#9560D9',
                                        width: "200px",
                                        mt: 2
                                    }}
                                    onClick={() => navigate("/aadharflow", { state: { abhacreate: true } })}
                                >
                                    {/* {loading ? 'Sending...' : 'Send OTP'} */}
                                    Recover / Create
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </>
            ) : show === 2 ? (
                <AbhaNumStep2 setShow={setShow} setAlertmessage={setAlertmessage} />
            ) : show === 3 ? (
                <SelectPhrAddress setShow={setShow} />
            ) : show === 6 ? (
                <CreatePhrAddress setShow={setShow} />
            ) : show === 7 ? (
                <PhrAddressScreen setShow={setShow} />
            ) : show === 8 ? (
                <SelectVerification setShow={setShow} />
            ) : show === 9 ? (
                <PreVerifyCreate setShow={setShow} />
            ) : null
            }
        </Box >
    );
}

export default AbhaNumStep1