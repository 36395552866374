import React from 'react'
import { ArrowForwardIos } from '@mui/icons-material'
import { Grid, Typography, Box, Card, IconButton, Paper, } from '@mui/material'
import AbhaLogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

function AbhaNumberFlow() {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate()
  return (
    <Paper elevation={3}
      sx={{
        p: "10px 15px",
        borderRadius: '10px'
        // boxShadow:
        //     "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box>
        <Grid
          item
          container
          spacing={2}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/abhanumber", { state: { LoginFlow: false } })}
        >
          <Grid item xs={1.5} sm={1} md={0.6}>
            <img src={AbhaLogo} alt="aadhar" width={30} height={30} />
          </Grid>
          <Grid item xs={9} sm={10} md={10.8}>
            <Typography
              sx={{
                fontSize: { xs: 14, sm: 15, md: 16 },
                fontWeight: 600,
              }}
            >
              {t("usingabhanum")}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 11, sm: 12, md: 12 }, fontWeight: 400, mt: '5px'
              }}
            >
              {t("usingabhatxt")}
            </Typography>
          </Grid>
          <Grid item xs={1.5} sm={1} md={0.4}>
            <IconButton>
              <ArrowForwardIos sx={{ color: "#2891D5" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default AbhaNumberFlow