import * as React from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography } from '@mui/material'
import EditProfileCard from '../../../atoms/EditProfileCard';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import axios from 'axios';
import URL, { MobileOrEmailpublicKey, OtpPublicKey } from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useState } from 'react';
import EmailUpdate1 from './EmailUpdate1';
import { useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { CustomTextField } from '../../../atoms/TextFields/customTextField';
import { encryptData } from '../../../../utility/encryption';
import { handleRequestError, handleRequestError1 } from '../../../atoms/helperFunctions';
import { useDispatch } from 'react-redux';
import { getAbhaMobile } from '../../../../redux/Actions/Actions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function EmailUpdate({ mobiledata }) {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [error, setError] = useState(false);
    const [show, setShow] = useState(1)
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const [emailerrormsg, setEmailerrormsg] = useState('')
    const [emailtxnid, setEmailtxnid] = useState('');
    const [otp, setOtp] = React.useState('')
    const dispatch = useDispatch()
    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    }

    const handleOnChangeMobile = (e) => {

        const inputValue = e.target.value || "";

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!emailRegex.test(inputValue)) {
            setError('Please enter a valid email address');
        } else {
            setError('');
        }

        setEmail(inputValue);
        setAlertmessage('')
        setError('');
    }

    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value || "";
        const numericValue = inputValue.replace(/\D/g, "");
        setOtp(numericValue.slice(0, 6))
    }
    useEffect(() => {
        setEmail(mobiledata?.email)
    }, [mobiledata?.email])

    const handleValidations = () => {
        let isValid = true;

        if (!email) {
            setEmailerrormsg('Email Address is required');
        }
        if (email) {
            // console.log("if Email");/
            const EMAIL_REGEX = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
            if (EMAIL_REGEX.test(email)) {
                setEmailerrormsg('')
            }
            else (
                setEmailerrormsg(t("emailalert"))
            )
        }
        return isValid;

    }

    const handleSubmit = async () => {
        // setShow(2)
        handleValidations()
        const isValid = handleValidations();

        if (!isValid) {
            // If validations fail, do not proceed with sending the email
            return;
        }
        setLoading(true);
        if (!(MobileOrEmailpublicKey && primaryId && token)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }

        const encodedMessage = encryptData(MobileOrEmailpublicKey, email);
        const payload = {
            email: encodedMessage.replace(/\n/g, ''),
            // mobileOrAadhaar: 'mobile',
        };
        try {
            const response = await axios.post(`${URL.consents}account/email/verification/send/otp/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });

            if (response.status === 200 || response.status === 201) {
                showAlert(response?.data?.message || `OTP sent to +91XXXXXX ${email?.slice(email?.length - 4)}`, 'success');
                console.log('responsemobile', response);
                setEmailtxnid(response?.data?.txnId);
                setShow(2)

            }
        } catch (error) {
            console.log(error, 'updateemailre');
            if (error?.response?.data === 'Invalid email') {
                showAlert(error?.response?.data, 'error');
            } else {
                let errorMessage = handleRequestError(error);
                showAlert(errorMessage, 'error');
            }
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }


    const handleSubmitOtp = async () => {
        try {
            // Show loading indicator
            setLoading(true);
            let EncryptOTP = otp
            const encodedMessage = encryptData(OtpPublicKey, EncryptOTP);
            // Prepare the data based on the value ('email' or 'mobile')
            const data = {
                txnId: emailtxnid,
                otpValue: encodedMessage.replace(/\n/g, ''),
            }

            console.log('data', data);
            let url = `email/verification/verify/otp`
            // Make the API call to verify OTP
            const response = await axios.post(`${URL.consents}account/${url}/${primaryId}`, data, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });
            // Handle successful response
            showAlert(response?.data?.message || 'OTP Verified Successfully', 'success');
            dispatch(getAbhaMobile(primaryId, token));
            // Optionally reset state after success

        } catch (err) {
            // Handle errors that occur during the request
            console.error('Error:', err);
            let errorMessage = handleRequestError1(err);
            if (errorMessage === 'Cannot invoke "java.util.List.isEmpty()" because "xTokenList" is null') {
                dispatch(getAbhaMobile(primaryId, token));
            } else {
                let errorMessage = handleRequestError1(err);
                showAlert(errorMessage, 'error');
            }
        }
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClosedone = () => {
        handleClose()
        setAlertmessage('')
    }
    return (
        <React.Fragment>
            <EditProfileCard title="Email Address" displaydata={mobiledata.email ? mobiledata.email : "N/A"} action="Change" onClick={handleClickOpen} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='sm'
            >
                {show === 1 ? (
                    <>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: { xs: '14px', sm: '18px', md: '18px' }, fontWeight: 600, textAlign: 'center' }}>Update New Email Address</Typography>
                            <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box >
                                        <Box>
                                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Email Address</label>
                                        </Box>
                                        <CustomTextField placeholder="Enter New Email Address" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={email} onChange={handleOnChangeMobile} />
                                    </Box>
                                </Grid>
                                {error && (
                                    <Typography sx={{ fontSize: '13px', color: 'red', mt: 1 }}>
                                        {error}
                                    </Typography>
                                )}
                            </Grid>
                        </DialogContent>
                        {/* <Divider /> */}
                        {emailerrormsg && (
                            <Typography
                                sx={{ color: "red", fontSize: { xs: 14, md: 16 } }}
                            >
                                {emailerrormsg}
                            </Typography>
                        )}


                    </>
                ) : show === 2 ? (
                    <div>
                        <DialogTitle>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Enter OTP</Typography>
                                <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>

                            </Box>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>

                            <Box sx={{ textAlign: 'center', mt: 2 }}>
                                <TextInputField width="350px" placeholder=" Enter Received OTP" name="otp" value={otp} onChange={handleOnChangeOtp} />

                            </Box>
                        </DialogContent>
                        {alertmessage && (
                            <Typography
                                sx={{
                                    color: alertstatus === "success" ? "green" : "red",
                                    mt: 1, fontSize: '13px', textAlign: 'center',
                                }}
                            >
                                {alertmessage}
                            </Typography>
                        )}
                        {/* <DialogActions sx={{ justifyContent: 'center', m: 0 }}>
                            <Button variant='contained' sx={{ width: '120px', borderRadius: '10px' }} startIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null} onClick={handleSubmitOtp}>Submit</Button>
                        </DialogActions> */}
                    </div>
                    // <EmailUpdate1 handleClose={handleClose} setShow={setShow} primaryId={primaryId} token={token} sessionId={sessionId} />
                ) : null}
                <Divider />
                {alertmessage && (
                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                )}
                <DialogActions sx={{ justifyContent: 'center', m: 0 }}>
                    <Button variant='contained' disabled={!email} sx={{ width: '120px', borderRadius: '10px' }} onClick={show === 1 ? handleSubmit : handleSubmitOtp}>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default EmailUpdate;