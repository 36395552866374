import React, { useState } from 'react'
import { Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useDispatch } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import URL, { MobileOrEmailpublicKey, PasswordpublicKey } from '../../../../utility/apiurl';
import axios from 'axios';
import { ArrowBackIos, Close, } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import MobileStep2 from './MobileStep2';
import SelectPhrAddress from './SelectPhrAddress';
import Registration from './Registration';
import EditRegistration from './EditRegistration';
import CreatePhrAddress from './CreatePhrAddress';
import PhrAddressScreen from './PhrAddressScreen';
import SelectVerification from './SelectVerification';
import { useNavigate } from 'react-router-dom';
import AdornmentTextField from '../../../atoms/TextFields/AdornmentTextField';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';
import { encryptData } from '../../../../utility/encryption';
import PreVerifyCreate from './preVerifyCreate';

function MobileStep1() {
  const [mobilenum, setMobilenum] = useState({
    number: '',
    error: {
      number: ''
    }
  })

  const { error } = mobilenum
  const [value, setValue] = useState('');
  const [alertopen, setAlertopen] = useState(false);
  const [alertmessage, setAlertmessage] = useState('');
  const [alertstatus, setAlertstatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(1)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = localstore.getToken()
  const primaryId = localstore.getPrimaryId()
  const csrftoken = localstore.getCsrfToken()
  const publicKey = PasswordpublicKey
  const [activeButton, setActiveButton] = useState('mobile')
  const [t, i18n] = useTranslation("global");


  const handleOnChangeMobile = (e) => {
    // console.log(e.target.value)
    const inputValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    const tennumbersOnly = inputValue.slice(0, 10); // Take the first 10 digits

    setMobilenum({
      ...mobilenum,
      [e.target.name]: tennumbersOnly, // Update the specific field with sanitized input
      error: { ...error, [e.target.name]: '' }, // Clear the error for this field
    });

    setAlertmessage('');

    // setMobilenum((val => e.target.value))
    // const inputValue = e.target.value.replace(/\D/g, '');
    // setMobilenum(inputValue.slice(0, 10));

  };

  const handleSend = async (e) => {
    // setShow(2)
    e.preventDefault()
    if (!mobilenum.number) {
      error.number = "Mobile Number is required"
    }
    setMobilenum({ ...mobilenum, error })
    setLoading(true);
    // const encrypt = new JSEncrypt()
    // encrypt.setPublicKey(MobileOrEmailpublicKey)
    // const encryptmobile = encrypt.encrypt(mobilenum?.number)
    const encodedMessage = encryptData(MobileOrEmailpublicKey, mobilenum?.number);

    let d = {
      mobile: encodedMessage.replace(/\n/g, ''),
      // mobileOrAadhaar: 'mobile',
    };
    await axios.post(`${URL.abha}mobile/generate/otp/${primaryId}`, d, {
      headers: {
        'gatewayToken': 'medxperts',
        'Authorization': `Bearer ${token}`,
        // 'X-CSRF-TOKEN': `${csrftoken}`
      },
    }).then(res => {

      setAlertmessage(t("otpsnttombl"));
      setAlertstatus('success');
      setShow(2)
      let details = {
        transactionId: res.data.txnId,
        mobile: mobilenum.number,
      }

      dispatch({
        type: "MOBILEFLOW_TRANSACTIONID",
        payload: details
      })

    }).catch(err => {
      // console.log(err.message);
      setAlertmessage(err.message);
      setAlertstatus('error');

    }).finally(() => { setLoading(false); });
  };

  const handleOnTitle = () => {
    setOpen(!open)
  };


  return (
    <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '44px' }, mb: { xs: 10, sm: 10, md: 5 } }}>
      {show === 1 ? (
        <>
          <Box sx={{ mt: { xs: 1, sm: "10px", md: 1.5 } }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, sm: 2, md: 2 },
              }}
            >
              <IconButton onClick={() => navigate('/connecthealthid')} >
                <ArrowBackIos sx={{ color: '#007DCD' }} />
              </IconButton>
              <Typography
                sx={{
                  fontSize: { xs: 15, sm: "16px", md: "16px" },
                  fontWeight: 600, color: '#007DCD'
                }}
              >
                {" "}
                {t("selfdeclared")}
              </Typography>
            </Box>
          </Box>
          <Paper
            elevation={3}
            sx={{
              mt: 1,
              p: 3,
              boxShadow:
                "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center-align horizontally
                justifyContent: "center", // Center-align vertically
                textAlign: "center", // Center-align text within the card
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: { xs: 14, sm: 16, md: 16 } }}>
                {/* {t("registerabha")} */}
                Use Registered Phone Number to Create
              </Typography>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                sx={{ mt: 2 }}
              >
                <Button
                  sx={{
                    bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2",
                    color: "black",
                    "&:hover": {
                      bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2",
                    }
                  }}
                  disableElevation
                >
                  {t("mobileotp")}
                </Button>
                {/* <Button
                  sx={{
                    bgcolor: activeButton === "email" ? "white" : "#EAE2E2",
                    color: "black",
                  }}
                  disableElevation
                >
                  {t("emailotp")}
                </Button> */}
              </ButtonGroup>
              <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12}>
                  <AdornmentTextField placeholder="Enter Mobile Number" width={{ sm: "350px", md: '350px' }} onChange={handleOnChangeMobile}
                    value={mobilenum.number}
                    name="number" />
                  {error.number ? (
                    <Typography sx={{ color: "red" }}>
                      {error.number}
                    </Typography>
                  ) : null}
                  {alertmessage && (
                    <ErrorStatusMsg alertstatus={alertstatus} alertmessage={alertmessage} />
                  )}
                </Grid>
              </Grid>
              <Box sx={{ mt: 4, textAlign: "center", mb: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#1CB500",
                    },
                    bgcolor: "#1CB500",
                    width: "150px",
                  }}
                  onClick={handleSend}
                  disabled={loading || mobilenum.number.length < 10}
                  startIcon={
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                >
                  {t("credentialsData.signupmobiledata.otpbtntxt")}
                </Button>
              </Box>
            </Box>
          </Paper>
        </>
      ) : show === 2 ? (
        <MobileStep2
          mobilenum={mobilenum}
          setAlertmessage={setAlertmessage}
          open={open}
          onClick={handleOnTitle}
          setShow={setShow}
        />
      ) : show === 3 ? (
        <SelectPhrAddress
          mobilenum={mobilenum}
          open={open}
          onClick={handleOnTitle}
          setShow={setShow}
        />
      ) : show === 4 ? (
        <Registration setShow={setShow} />
      ) : show === 5 ? (
        <EditRegistration setShow={setShow} />
      ) : show === 6 ? (
        <CreatePhrAddress setShow={setShow} show={show} />
      ) : show === 7 ? (
        <PhrAddressScreen setShow={setShow} />
      ) : show === 8 ? (
        <SelectVerification setShow={setShow} />
      ) : show === 9 ? (
        <PreVerifyCreate setShow={setShow} />
      ) : null
      }
    </Box >
  );
}

export default MobileStep1
