
import React, { useEffect } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import axios from 'axios';
import { useState } from 'react'
import { Box, Button, Card, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material'
import URL, { AadharPublicKey, OtpPublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaCard, getAbhaProfile, getAbhaQRCode, getPatientdatafromibm } from '../../../../redux/Actions/Actions';

import { useTranslation } from "react-i18next";
import ResendOtp, { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { handleErrorResponse } from '../../../atoms/StatusError';
import JSEncrypt from 'jsencrypt';
import { handleRequestError, updateIdentifier, updateRelatedIdentifier, updateRelatedPersonData } from '../../../atoms/helperFunctions';
import { encryptData } from '../../../../utility/encryption';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

function AbhaNumberLinking(props) {
    const navigate = useNavigate()
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const abhanumberlinkdetails = useSelector((state) => state.phraddress.abhanumberlinkdetails)
    // const abhanumberdetails = useSelector((state) => state.abha.sessionIdandMobilenumber)
    console.log(abhanumberlinkdetails, "abhanumber")
    const switchprofiles = useSelector(stat => stat.medx.switchprofile?.response?.entry);
    const transactionId = abhanumberlinkdetails.transactionId
    const HealthId = abhanumberlinkdetails.HealthId
    const authmethod = abhanumberlinkdetails.authmethod
    const HINumber = abhanumberlinkdetails.HidNumber
    const pid = localstore.getPatientId()
    const [otp, setOtp] = useState('')
    const [time, setTime] = useState(59);
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [transactionid, setTransactionId] = useState('')
    console.log(transactionid, "transactionid")
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);

    useEffect(() => {
        setTransactionId(transactionId);
    }, [])
    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }
    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    };

    const handleLink = async (XToken) => {
        setLoading(true);
        if (HealthId?.length > 0) {
            const data = {
                abhaAddress: HealthId,
                txnId: transactionid,
            };
            await axios
                .post(`${URL.abha}login/hid/confirm/otp/${primaryId}`, data, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                        // 'X-CSRF-TOKEN': `${csrf}`,
                        'T-token': `Bearer ${XToken}`
                    },
                })
                .then(async res => {
                    let patient = jwtDecode(XToken);
                    console.log('---------res Link3', res);
                    showAlert(res?.data?.message || 'Successfully Linked !', 'success');
                    let updatedData = updateIdentifier(ibm, patient?.abhaAddress, 'ABHA');
                    const updateRelated = updateRelatedIdentifier(ibm, switchprofiles, patient?.abhaAddress, patient?.fullName, 'ABHA')
                    await axios
                        .put(`${URL.cliniapi}Patient/${primaryId}`, updatedData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        .then(async res => {
                            // console.log('updateded', res)
                            // if (!ibm?.identifier || !ibm?.identifier[0]?.system || ibm?.identifier[0]?.system !== 'https://patientMedxperts') {
                            // If the identifier is missing or invalid, proceed with updating the related person
                            await updateRelatedPersonData(updateRelated, updateRelated?.id);
                            // }

                            showAlert('Successfully Updated data', 'success');
                            dispatch(getPatientdatafromibm('Patient', primaryId, token));
                            dispatch(getAbhaProfile(primaryId, token,));
                            dispatch(getAbhaCard(token, primaryId))
                            dispatch(getAbhaQRCode(token, pid, HealthId, primaryId))
                            navigate('/');
                            setLoading(false);
                        })
                        .catch(err => {
                            // console.log(err, 'error in update');
                            showAlert('Something Went Wrong in update', 'error');
                            setLoading(false);
                        });

                })
                .catch(err => {
                    // console.log('errorloginselctphrs', err)
                    let errorMessage = handleRequestError(err);
                    showAlert(errorMessage, 'error');
                });

        }
    };




    const handleVerify = async () => {
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(OtpPublicKey)
        const encryptOtp = encrypt.encrypt(otp)
        let d = {
            otp: encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
            transactionId: transactionId,
            // authMethod: authMethod,
        };
        // console.log('-------body1', d);
        await axios
            .post(`${URL.abha}login/hid/confirm-init/${primaryId}`, d, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(async res => {
                // console.log(res.data, 'kkkkkkkkkkkkk');
                // console.log(res.data.healthIdNumber, 'kkkkkkkkkkkkk');
                const jwtResponsetoken = res.data;
                setAlertmessage(t("otpversuccesfully"));
                setAlertstatus('success');

                handleLink(transactionId);

            })
            .catch(err => {
                // console.log('--------errorinconfirm', err);
                // console.log(err.message);
                if (err.response.status === 400) {
                    setAlertmessage(t("otpenterincrt"));
                    setAlertstatus('error');
                } else {
                    const { alertmessage, alertstatus } = handleErrorResponse(err)
                    setAlertmessage(alertmessage)
                    setAlertstatus(alertstatus)
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleLogin = async () => {
        setLoading(true);
        if (!(AadharPublicKey && primaryId && token)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }
        const encodedMessage = encryptData(AadharPublicKey, otp);
        let payload = {
            otpValue: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
            authMode: authmethod,
            txnId: transactionid,
        };
        try {
            const response = await axios.post(`${URL.abha}login/hid/verify/otp/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });

            // console.log('AbhaNumberStep2loginvalidate', response);
            // if (response.status === 200 || response.status === 201) {
            let PHRAddressData = response?.data?.mappedPhraddress;
            let xtoken = response?.data?.token?.token
            let updatedData = updateIdentifier(ibm, HINumber, 'HIN');
            await axios
                .put(`${URL.cliniapi}Patient/${primaryId}`, updatedData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {
                    // console.log('updatedres', res)
                    showAlert(response.data.message || 'OTP Verified Successfully!', 'success');
                    if (HealthId.length > 0 || HealthId !== undefined) {
                        handleLink(xtoken)
                    } else {
                        dispatch({
                            type: "ABHANUMBER_LOGINSELECTPHR",
                            payload: {
                                transactionId: transactionid,
                                phrs: PHRAddressData,
                                AbhaNumber: true,
                                XToken: xtoken,
                            }
                        })
                    }
                })
                .catch(err => {
                    console.log(err, 'error in update');
                });
            // }
        } catch (error) {
            // console.log('abhanumber2verifyotp', error)
            let errorMessage = handleRequestError(error);
            showAlert(errorMessage, 'error');
        } finally {
            setLoading(false);
        }

    };

    const handleResendOtp = async () => {
        setTime(59);
        let data = {
            transactionId: transactionId,
        };

        await axios.post(`${URL.abha}hid/init/resend/otp/${primaryId}`, data, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {


            console.log(res, "abharesendOtppppp")
            setAlert(true);
            setAlertmessage(t("otpsentsuccessfully"));
            setAlertstatus('success');
        })
            .catch(err => {
                console.log(err.message);
                setAlert(true);
                setAlertmessage(t("errormsg"));
                setAlertstatus('error');
            })
            .finally(() => {
                setLoading(false);
            });

        setOtp('')
    }
    return (
        <div>
            <Box sx={{ mt: { xs: 3, sm: 2, md: 1 } }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: 1, sm: 2, md: 2 },
                    }}
                >
                    <IconButton onClick={() => {
                        props.setShow(1)
                        props.setAlertmessage('')
                    }}>
                        <ArrowBackIos sx={{ color: "#007DCD", cursor: "pointer" }} />
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: { xs: 14, sm: "16px", md: "18px" },
                            fontWeight: "500",
                            color: "#007DCD",
                        }}
                    >
                        {t("changeregisterdetails")}{" "}
                    </Typography>
                </Box>
            </Box>

            <Card sx={{ p: 3, mt: 1, display: "flex", justifyContent: "center" }}>
                <Box>
                    <Box mt={1}>
                        <Typography
                            sx={{
                                fontSize: { xs: 14, md: 16 },
                                textAlign: "center",
                                color: "black",
                                ml: 1,
                                fontWeight: 500,
                            }}
                        >
                            {t("verifyotp")}
                        </Typography>

                        <Grid container mt={2}>
                            <Grid item xs={12}>
                                <TextInputField
                                    width={{ sm: "350px", md: "350px" }}
                                    placeholder={t("enterotphere")}
                                    border="none"
                                    name="otp"
                                    value={otp}
                                    onChange={handleOnChangeOtp}
                                />
                            </Grid>
                        </Grid>
                        {/* {alertmessage && (
                        <Typography sx={{ color: alertstatus === 'error' ? 'red' : 'green', ml: 1 }}>{alertmessage}</Typography>

                    )} */}
                        {alertmessage && (
                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                        )}

                        {/* <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} /> */}

                        <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                            <Button
                                variant="contained"
                                sx={{ borderRadius: 2, width: "150px", mt: 1 }}
                                onClick={handleLogin}
                                disabled={loading || otp?.length < 6}
                                startIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="inherit" />
                                    ) : null
                                }
                            >
                                {/* {loading ? "submit.." : "Submit"} */}
                                {t("credentialsData.signupmobiledata.submitbtn")}
                            </Button>
                        </Box>

                        <Box mt={5}>
                            <Typography sx={{ color: "black", fontSize: 12 }}>
                                {t("enterotpsenttomb")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </div>
    );
}

export default AbhaNumberLinking;