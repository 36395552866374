import React, { useEffect, useState } from 'react';
import { Autocomplete, Avatar, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, TextField } from '@mui/material'
import { Close } from '@mui/icons-material';
import { getAbhaCard, getAbhaMobile, getAbhaProfile, getDistricts, getPatientdatafromibm, getStates } from '../../../../redux/Actions/Actions';
import axios from 'axios';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import EditProfileCard from '../../../atoms/EditProfileCard';
import { useTranslation } from "react-i18next";
import { CustomTextField } from '../../../atoms/TextFields/customTextField';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function AddressUpdate({ mobiledata }) {
    // console.log(mobiledata, "mobile")
    const [open, setOpen] = useState(false);
    const [firstname, setFirstname] = useState('')
    const [middlename, setMiddlename] = useState('')
    const [lastname, setLastname] = useState('')
    const [address, setAddress] = useState('')
    const [gender, setGender] = useState('Male');
    const genderdata = ['Male', 'Female', 'Other'];
    const [birthDate, setBirthDate] = useState('');
    const [pincode, setPincode] = useState('');
    const [state, setState] = useState(null); // or use an empty string if you prefer
    const [district, setDistrict] = useState('')
    const [statecode, setStatecode] = useState('');
    const [districtcode, setDistrictcode] = useState('');
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [relation, setRelation] = useState('')
    const [t, i18n] = useTranslation("global");

    let states = useSelector(state => state.abha.states);
    // console.log(result, "states")
    let districts = useSelector(state => state.abha.districts);

    const ibm = useSelector((state) => state.medx.ibmpdata)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()
    const nameParts = mobiledata?.fullName?.split(' ');

    // Extract first name and last name
    const firstName = nameParts?.[0];
    const lastName = nameParts?.slice(1).join(' '); // Join
    const dateOfBirth = mobiledata.dateOfBirth
    const day = dateOfBirth?.date;
    const month = dateOfBirth?.month;
    const year = dateOfBirth?.year;
    const formatedDate = `${year}-${month}-${day}`
    // console.log(formatedDate, "formateddate")
    const dateObject = new Date(birthDate)
    // console.log(dateObject, "dateee")
    const yearObject = dateObject.getFullYear()
    const monthObject = dateObject.getMonth() + 1
    const dayObject = dateObject.getDate()
    useEffect(() => {
        dispatch(getStates(token))
    }, []);
    useEffect(() => {
        console.log(mobiledata, "mobiledata")
        if (mobiledata) {
            setFirstname(firstName);
            setLastname(lastName);
            setAddress(mobiledata?.address);
            setPincode(mobiledata?.pinCode);
            setState({ name: mobiledata?.stateName, code: mobiledata?.stateCode } || null);
            setDistrict({ name: mobiledata?.districtName, code: mobiledata?.districtCode } || null);
            setStatecode(mobiledata?.stateCode);
            setDistrictcode(mobiledata?.districtCode);
            setBirthDate(dateOfBirth);

            // Set gender
            if (mobiledata?.gender === "M") {
                setGender("Male");
            } else if (mobiledata?.gender === "F") {
                setGender("Female");
            } else if (mobiledata?.gender === "O") {
                setGender("Other");
            }
        }
    }, [mobiledata, relation]); // Make sure mobiledata is populated


    const handleChangeDate = (newValue) => {
        setBirthDate(newValue?.format("YYYY-MM-DD"))
    }

    const handleGender = (selectedGender) => {
        setGender(selectedGender);
    }

    const handleChangePincode = (e) => {
        const inputvalue = e.target.value
        // Only allow numeric input and limit it to 6 digits
        if (/^\d{0,6}$/.test(inputvalue)) {
            setPincode(inputvalue); // Update pincode if valid input
        }
    }

    const handleState = (e) => {
        setState(e.name);
        setStatecode(e.code);
        dispatch(getDistricts(e.code, token));
        setDistrict('');
    }

    const handleDistrict = (e) => {
        setDistrict(e.name);
        setDistrictcode(e.code);
    };

    const handleSubmit = async () => {
        setLoading(true)
        let genderValue;

        if (gender === "Male") {
            genderValue = "M";
        } else if (gender === "Female") {
            genderValue = "F";
        } else if (gender === "Other") {
            genderValue = "O";
        } else {
            genderValue = "";
        }
        const data = {
            "addressLine": address,
            "gender": genderValue,
            "dayOfBirth": dayObject,
            "monthOfBirth": monthObject,
            "yearOfBirth": yearObject,
            "districtCode": districtcode,
            "firstName": firstname,
            "lastName": lastname,
            "middleName": middlename,
            "pinCode": pincode,
            "stateCode": statecode
        }
        console.log(data, "dataaa")
        await axios.post(`${URL.consents}account/profile/updateAddress/${primaryId}`, data, {
            headers: { "Authorization": `Bearer ${token}`, "gatewayToken": "medxperts", }
        }).then(res => {

            setAlertmessage("updated successfully")
            setAlertstatus('success')
            dispatch(getAbhaProfile(primaryId, token,));
            dispatch(getAbhaMobile(primaryId, token,));
            dispatch(getAbhaCard(token, primaryId));
            handleClosedone()
        }).catch(err => {
            setAlertmessage("try again some time")
            setAlertstatus("error")
        }).finally(() => {
            setLoading(false)
        })

    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClosedone = () => {
        handleClose()
        setAlertmessage('')
    }

    // console.log(state, "state")
    return (
        <React.Fragment>
            {/* <EditProfileCard
                title="Address"
                displaydata={
                    mobiledata?.address?.length > 20 ||
                        mobiledata?.districtName?.length > 10 ||
                        mobiledata?.pinCode?.length > 0
                        ? `${mobiledata?.address?.slice(0, 20)}, ${mobiledata?.districtName?.slice(0, 10)}, ${mobiledata?.pinCode}`
                        : `${mobiledata?.address} ${mobiledata?.districtName} ${mobiledata?.pinCode}`
                }
                action="Update"
                onClick={handleClickOpen}
            /> */}
            <EditProfileCard title="Address" displaydata={mobiledata?.address?.length > 0 ?
                mobiledata.address : 'N/A'} action="Update" onClick={handleClickOpen} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='md'            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Update New Address</Typography>
                    <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div>
                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Address Line1</label>
                                    </Box>
                                    <CustomTextField
                                        placeholder="Enter your Address Line"
                                        type='text'
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Select State</label>
                                </Box>
                                <Autocomplete
                                    id="state-select"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#59D8FA',
                                            },
                                        },
                                        ".MuiInputBase-root": {
                                            borderRadius: '7px',
                                        },
                                    }}
                                    options={states}
                                    size='small'
                                    autoHighlight
                                    value={state}
                                    onChange={(e, newValue) => {
                                        setState(newValue);
                                        dispatch(getDistricts(newValue.code, token));

                                    }}
                                    fullWidth
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                            {option?.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Select State'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Select District</label>
                                </Box>
                                <Autocomplete
                                    id="district-select"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#59D8FA',
                                            },
                                        },
                                        ".MuiInputBase-root": {
                                            borderRadius: '7px',
                                        },
                                    }}
                                    options={districts}
                                    size='small'
                                    autoHighlight
                                    onChange={(e, newValue) => setDistrict(newValue)}
                                    value={district}
                                    fullWidth
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                            {option?.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Select District'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>PinCode</label>
                                </Box>
                                <CustomTextField
                                    placeholder="Enter Pincode"
                                    type='text'
                                    borderColor="#59D8FA"
                                    borderRadius="7px"
                                    value={pincode}
                                    onChange={handleChangePincode}
                                />
                            </Grid>
                        </Grid>
                        <Grid container textAlign={'center'}>
                            {/* 
                            <Grid item xs={12} mt={2}>
                                <TextInputField width={{ sm: "400px", md: '400px' }} type="text" placeholder="First Name" name="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <TextInputField width={{ sm: "400px", md: '400px' }} type="text" placeholder={'Middle Name'} value={middlename} onChange={(e) => setMiddlename(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <TextInputField width={{ sm: "400px", md: '400px' }} type="text" placeholder={'Last Name'} value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </Grid> */}
                            {/* <Grid item xs={12} mt={2}>
                                <TextInputField width={{ sm: "400px", md: '400px' }} type="text" placeholder={'Address Line'} value={address} onChange={(e) => setAddress(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <TextInputField width={{ sm: "400px", md: '400px' }} type="text" placeholder={'Pincode'} value={pincode} onChange={(e) => setPincode(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} mt={2}>

                                <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                            backgroundColor: "whitesmoke",
                                        },
                                        ".MuiOutlinedInput-notchedOutline": {
                                            // border: "none",
                                        },
                                        width: { sm: '400px', md: '400px' }
                                    }}
                                    placeholder={t("relation")}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => setStat(e.target.value)}
                                    value={stat}
                                    name={"stat"}
                                    autoFocus
                                    autoComplete="off"
                                    select
                                    SelectProps={{
                                        displayEmpty: true,
                                        renderValue: (selected) =>
                                            selected === "" ? t("state") : selected,
                                    }}
                                >
                                    <MenuItem value="">{t("selstate")}</MenuItem>
                                    {states.map((state, i) => (
                                        <MenuItem
                                            key={i}
                                            value={state.name}
                                            onClick={() => handleState(state)}
                                        >
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                            backgroundColor: "whitesmoke",
                                        },
                                        ".MuiOutlinedInput-notchedOutline": {
                                            // border: "none",
                                        },
                                        width: { sm: '400px', md: '400px' }
                                    }}
                                    placeholder="Relation"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => setDistrict(e.target.value)}
                                    value={district}
                                    name={"district"}
                                    autoFocus
                                    autoComplete="off"
                                    select
                                    SelectProps={{
                                        displayEmpty: true,
                                        renderValue: (selected) =>
                                            selected === "" ? t("district") : selected,
                                    }}
                                >
                                    <MenuItem value="">{t("seldis")} </MenuItem>
                                    {districts.map((district, i) => (
                                        <MenuItem
                                            key={i}
                                            value={district.name}
                                            onClick={() => handleDistrict(district)}
                                        >
                                            {district.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} mt={2} display={'flex'} justifyContent={'center'} >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            sx={{
                                                '.MuiOutlinedInput-input': {
                                                    padding: '9px 14px',
                                                    bgcolor: 'whitesmoke',

                                                },
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    borderRadius: 2
                                                },
                                                width: { sm: '400px', md: '400px' }
                                            }}
                                            value={dayjs(birthDate)}
                                            onChange={handleChangeDate}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>

                            <Typography sx={{ mt: 1, ml: 8 }} variant="subtitle1">
                                <span>Select Your Gender</span>
                            </Typography>
                            <Grid item xs={12} display={'flex'} justifyContent={'center'}>

                                <Box display={"flex"} justifyContent="space-around" sx={{
                                    border: "1px solid #BCBCBC", "&:hover": {
                                        border: "1px solid black"
                                    }, borderRadius: 2, p: "4px", backgroundColor: 'whitesmoke', width: { xs: '290px', sm: '400px', md: '400px' }

                                }}>
                                    {genderdata.map((gen, i) => (
                                        <Button sx={{
                                            height: "30px", textTransform: 'capitalize', backgroundColor: 'white', color: gender === gen ? "black" : "gray", '&:hover': {
                                                bgcolor: 'white',

                                            },
                                            bgcolor: gender === gen ? 'white' : 'whitesmoke'
                                        }}
                                            key={i}
                                            variant={gender === gen ? 'contained' : 'text'}
                                            onClick={() => handleGender(gen)}
                                        >
                                            {gen}
                                        </Button>
                                    ))}
                                </Box>
                            </Grid> */}
                        </Grid>
                    </div >
                </DialogContent>
                <Divider />
                {alertmessage && (
                    <Typography
                        sx={{
                            color: alertstatus === "success" ? "green" : "red", fontSize: '14px', textAlign: 'center',
                            mt: 1,
                        }}
                    >
                        {alertmessage}
                    </Typography>
                )}
                <DialogActions sx={{
                    justifyContent: 'center', // Center the content horizontally
                    margin: 'auto',
                }}>
                    <Button variant='contained' startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null} sx={{ width: '120px', borderRadius: '10px', left: 0 }} onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}
export default AddressUpdate;