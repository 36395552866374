const initialState = {
    mobiletransactionId: {},
    registrationdetails: {},
    selectphrdetails: {},
    emailtransactionId: {},
    aadhartranDetails: {},
    preregistrationdata: null,
    predata: false,
    preverifycreate: {},
    loginselecphr: {},
    getpasswordsesid: null,
    abhanumberlinkdetails: {}
};

const phrAddressReducer = (state = initialState, action) => {
    // console.log(action,">>>>>>>>>>>>>");
    switch (action.type) {
        case "MOBILEFLOW_TRANSACTIONID":
            return {
                ...state,
                mobiletransactionId: action.payload
            };
        case "REGISTRATION_PAGE":
            return {
                ...state,
                registrationdetails: action.payload
            };
        case "SELECTPHR_PAGE":
            return {
                ...state,
                selectphrdetails: action.payload
            };
        case "EMAILFLOW_TRANSACTIONID":
            return {
                ...state,
                emailtransactionId: action.payload
            };
        case "EMAIL_REGISTRATION_PAGE":
            return {
                ...state,
                registrationdetails: action.payload
            };
        case "EMAIL_SELECTPHR_PAGE":
            return {
                ...state,
                selectphrdetails: action.payload
            };
        case "AADHARSTEP4":
            return {
                ...state,
                aadhartranDetails: action.payload
            };
        case "PREREGRISTRATIONDATA":
            return {
                ...state,
                preregistrationdata: action.payload
            };
        case 'PREDATA':
            return {
                ...state,
                predata: action.payload,
            };
        case "ABHANUM_SELECTPHR_PAGE":
            return {
                ...state,
                selectphrdetails: action.payload
            };
        case "PREVERIFY_CREATE":
            return {
                ...state,
                preverifycreate: action.payload
            };
        case "LOGINMOBILE_SELECTPHR":
            return {
                ...state,
                loginselecphr: action.payload
            };
        case "LOGINABHA_SELECTPHR":
            return {
                ...state,
                loginselecphr: action.payload
            };
        case "ABHANUMBER_LOGINSELECTPHR":
            return {
                ...state,
                loginselecphr: action.payload
            };
        case "GETPASSWORDSESS":
            return {
                ...state,
                getpasswordsesid: action.payload

            };
        case "ABHANUMBER_LINKING":
            return {
                ...state,
                abhanumberlinkdetails: action.payload
            };
        case "VERIFY_KYC_ABHANUMBER":
            return {
                ...state,
                abhanumberlinkdetails: action.payload
            };


        default:
            return state;
    }
};

export default phrAddressReducer;
