import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Select, Slide, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, TextField } from '@mui/material'
import { Close, Person } from '@mui/icons-material';
import { getAbhaMobile, getDistricts, getPatientdatafromibm, getStates } from '../../../../redux/Actions/Actions';
import axios from 'axios';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import EditProfileCard from '../../../atoms/EditProfileCard';
import { useTranslation } from "react-i18next";
import { CustomTextField } from '../../../atoms/TextFields/customTextField';
import CommonCalender from '../../../atoms/CommonCalender';
import { handleRequestError1 } from '../../../atoms/helperFunctions';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const MAX_FILE_SIZE = 102400; // 100KB in bytes

function NameUpdate({ mobiledata, open, handleClose }) {
    console.log(mobiledata, "mobile")
    const genderOptions = ["male", "female", "other"];
    const [firstname, setFirstname] = useState('')
    const [middlename, setMiddlename] = useState('')
    const [lastname, setLastname] = useState('')
    const [address, setAddress] = useState('')
    const [gender, setGender] = useState('Male');
    const genderdata = ['Male', 'Female', 'Other'];
    const [birthDate, setBirthDate] = useState('');
    const [pincode, setPincode] = useState('');
    const [stat, setStat] = useState('')
    const [district, setDistrict] = useState('')
    const [statecode, setStatecode] = useState('');
    const [districtcode, setDistrictcode] = useState('');
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [relation, setRelation] = useState('')
    const [t, i18n] = useTranslation("global");

    let states = useSelector(state => state.abha.states);
    // console.log(result, "states")
    let districts = useSelector(state => state.abha.districts);

    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()
    const nameParts = mobiledata?.fullName?.split(' ');
    // Extract first name and last name
    const [uploadpersonal, setUploadperonal] = useState(false)
    const [name, setName] = useState("")
    const [filename, setFilename] = useState("")
    const [filesize, setFilesize] = useState(0)
    const [filetype, setFiletype] = useState("")
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [uploadprofessional, setUploadprofessional] = useState(false)
    const [RelResourseData, setRelResourseData] = useState(null);
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const firstName = nameParts?.[0];
    const lastName = nameParts?.slice(1).join(' '); // Join
    const dateOfBirth = mobiledata.dateOfBirth
    const day = dateOfBirth?.date;
    const month = dateOfBirth?.month;
    const year = dateOfBirth?.year;
    const formatedDate = `${year}-${month}-${day}`
    console.log(formatedDate, "formateddate")
    const dateObject = new Date(birthDate)
    console.log(dateObject, "dateee")
    const yearObject = dateObject.getFullYear()
    const monthObject = dateObject.getMonth() + 1
    const dayObject = dateObject.getDate()

    useEffect(() => {
        dispatch(getStates(token))
    }, []);


    const [image, setImage] = useState(null); // State to store the image
    const [error, setError] = useState(''); // State to store the error message

    const imageHandler = (e, type) => {
        const file = e.target.files[0]; // Get the first file from the input
        if (file) {
            const fileSize = file.size;
            console.log('File size:', fileSize);

            if (fileSize > MAX_FILE_SIZE) {
                setError('The image is too large! Please choose a smaller photo below 100KB.');
                setImage(null); // Clear the previous image
                return;
            }

            setError(''); // Clear any previous error message

            const reader = new FileReader();
            reader.onloadend = () => {
                // Set the base64 data URL as the image source
                setImage(reader.result);
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };

    useEffect(() => {
        setFirstname(firstName)
        setLastname(lastName)
        setMiddlename(mobiledata?.middleName);

        setAddress(mobiledata?.address)
        setPincode(mobiledata?.pincode)
        setStat(mobiledata?.stateName)
        setDistrict(mobiledata?.districtName)
        setStatecode(mobiledata?.stateCode)
        setDistrictcode(mobiledata?.districtCode)
        setBirthDate(dateOfBirth);
        setMobile(mobiledata?.mobile)
        setEmail(mobiledata?.email)
        setImage(mobiledata?.profilePhoto)
        if (mobiledata?.gender === "M") {
            setGender("Male");
        } else if (mobiledata?.gender === "F") {
            setGender("Female");
        } else if (mobiledata?.gender === "O") {
            setGender("Other");
        }
    }, [mobiledata, relation])

    const handleChangeDate = (newValue) => {
        setBirthDate(newValue?.format("YYYY-MM-DD"))
    }
    const handleGender = (selectedGender) => {
        setGender(selectedGender);
    }

    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);
    }

    const handleState = (e) => {
        setStat(e.name);
        setStatecode(e.code);
        dispatch(getDistricts(e.code, token));
        setDistrict('');

    }
    const handleDistrict = (e) => {
        setDistrict(e.name);
        setDistrictcode(e.code);
    };
    const handleSubmit = async () => {

        setLoading(true)
        let genderValue;

        if (gender === "Male") {
            genderValue = "M";
        } else if (gender === "Female") {
            genderValue = "F";
        } else if (gender === "Other") {
            genderValue = "O";
        } else {
            genderValue = "";
        }
        // Format dayObject if it is a single digit
        const formattedDay = dayObject < 10 ? `0${dayObject}` : dayObject;
        const formattedMonth = monthObject < 10 ? `0${monthObject}` : monthObject;

        const data = {
            // addressLine: address,
            // gender: genderValue,
            // dayOfBirth: formattedDay || day,
            // monthOfBirth: formattedMonth || month,
            // yearOfBirth: yearObject || year,
            // districtCode: districtcode,
            // firstName: firstname,
            // lastName: lastname,
            // middleName: middlename,
            // // pinCode: pincode || '',
            // stateCode: statecode,
            // photo: image,
            // email: email,
            // stateName: stat,
            // districtName: district,
            // mobile: mobile,
            ...mobiledata,
            photo: image
        }
        try {
            // Send the POST request to update the profile
            const response = await axios.post(
                `${URL.consents}account/profile/updatePicture/${primaryId}`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'gatewayToken': 'medxperts',
                        // 'X-CSRF-TOKEN': csrf,
                    },
                }
            );
            if (response.status === 200) {
                // Dispatch actions after successful update
                dispatch(getAbhaMobile(token, primaryId));
                // Show success alert
                showAlert(response?.data?.message || 'Updated Successfully Done!', 'success');
                // Reset the state after 1 second

            }
        } catch (err) {
            // Handle error
            const errorMessage = handleRequestError1(err);
            showAlert(errorMessage, 'error');
            // Reset the state after 1 second (even on error)

        } finally {
            // Ensure loading is turned off
            setLoading(false);
        }

    }


    const handleClosedone = () => {
        handleClose()
        setAlertmessage('')
    }
    return (
        <React.Fragment>
            {/* <EditProfileCard title="Display Name" displaydata={mobiledata.fullName ? mobiledata.fullName : 'N/A'} action="Update" onClick={handleClickOpen} /> */}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Update Information</Typography>
                    <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div>

                        <Box sx={{ display: "flex", justifyContent: "center", mt: 1.5 }}>
                            <div>
                                <input
                                    type="file"
                                    name="uploadfile"
                                    multiple
                                    id="post-img2"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    style={{ display: "none" }}
                                    onChange={(e) => imageHandler(e, "personal")}
                                />
                                <label htmlFor="post-img2" className="cursor">
                                    <div className="inputOption" style={{ display: "flex" }}>
                                        <Box
                                            sx={{
                                                width: "70px",
                                                height: "60px",
                                                position: "relative",
                                                display: "flex",
                                                justifyContent: "center",
                                                bgcolor: "#9560D9",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            {image ? (
                                                <img
                                                    src={image}
                                                    alt="media"
                                                    style={{
                                                        objectFit: "cover",
                                                        width: "100%",
                                                        height: "100%",
                                                        cursor: "pointer",
                                                        borderRadius: "10px",
                                                    }}
                                                />
                                            ) : (
                                                <Person sx={{ fontSize: "40px", cursor: "pointer" }} />
                                            )}

                                            <Typography
                                                sx={{
                                                    position: "absolute",
                                                    color: "white",
                                                    top: 37,
                                                    cursor: "pointer",
                                                    fontSize: "14px",
                                                    backgroundColor: "#0005",
                                                    color: "#fff",
                                                    borderBottomRightRadius: "10px",
                                                    borderBottomLeftRadius: "10px",
                                                    bottom: 0,
                                                    width: "70px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Upload
                                            </Typography>
                                        </Box>
                                    </div>
                                </label>
                            </div>
                        </Box>
                        {error && (
                            <Typography sx={{ color: 'red', fontSize: '14px', textAlign: 'center', mb: 1 }}>
                                {error}
                            </Typography>
                        )}
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} md={12}>
                                <Box >
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>First Name</label>
                                    </Box>
                                    <CustomTextField placeholder="First Name" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Box >
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Middle Name</label>
                                    </Box>
                                    <CustomTextField placeholder="Middle Name" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={middlename} onChange={(e) => setMiddlename(e.target.value)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box >
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Last Name</label>
                                    </Box>
                                    <CustomTextField placeholder="Last Name" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box >
                                    <Box >
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Gender</label>
                                    </Box>
                                    <Select
                                        displayEmpty
                                        fullWidth
                                        sx={{ '.MuiSelect-select': { padding: '9px' }, borderRadius: '7px' }}
                                        value={gender}
                                        onChange={handleGender}
                                        placeholder='Select your Gender'
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return <span>{"Select your Gender"}</span>;
                                            }
                                            return selected;
                                        }}
                                    // error={props.error}
                                    // MenuProps={{ style: { zIndex: props.zIndex } }}

                                    >
                                        {genderOptions.map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}

                                    </Select>
                                    {/* <SelectOptions borderRadius="7px" value={gender} defaultName="Select your Gender" onChange={handleGender}>
                                        {genderOptions.map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </SelectOptions> */}

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{ '.MuiStack-root': { paddingTop: '0px' } }}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Date of Birth</label>
                                </Box>
                                <CommonCalender width="100%" value={dayjs(birthDate)} onChange={handleChangeDate} maxDate={dayjs()} />
                            </Grid>
                        </Grid>
                    </div >
                </DialogContent>
                <Divider />
                {alertmessage && (
                    <Typography
                        sx={{
                            color: alertstatus === "success" ? "green" : "red", fontSize: '14px', textAlign: 'center',
                            mt: 1,
                        }}
                    >
                        {alertmessage}
                    </Typography>
                )}
                <DialogActions sx={{
                    justifyContent: 'center', // Center the content horizontally
                    margin: 'auto',
                }}>
                    <Button variant='contained' startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null} sx={{ width: '120px', borderRadius: '10px', left: 0 }} onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}
export default NameUpdate;