import React from 'react'
import { Close } from '@mui/icons-material'
import { Box, Button, Card, Grid, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { useTranslation } from "react-i18next";
import HealthIdNumber from './HealthNumLogin'
import MobileEmailLogin from './Mobile_EmailLogin'
import UserIdLogin from './UserIdLogin'

function LoginAbhaAddress() {
    const navigate = useNavigate()
    const [t, i18n] = useTranslation("global");

    return (
        <Box sx={{ mt: { md: 2, xs: 9, sm: 9 }, m: 1, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '0px', sm: "0px", md: '0px' }, mb: { xs: 10, sm: 10 } }}>
            <Typography
                variant="h6"
                sx={{
                    fontSize: { xs: "14px", sm: "17px", md: "17px" },
                    mt: { xs: 3, sm: 3, md: 2 },
                    fontWeight: 600,
                    ml: 1,
                }}
            >
                {t("selectloginmethod")}

            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: "15px", justifyContent: 'center' }}>
                <img src={Abhalogo} alt="abha" width="35px" height="35px" />

                <Box>
                    <Typography sx={{ fontWeight: 500, fontSize: { xs: '14px', sm: 16, md: 16 } }}>Login to ABHA Address</Typography>
                    <Typography sx={{ fontSize: { xs: '13px', sm: 14, md: 14 } }}>If you have already Ayushman Bharat Health Account</Typography>
                </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <MobileEmailLogin />
            </Box>

            <Box mt={2}>
                <UserIdLogin />
            </Box>
            <Box mt={2}>
                <HealthIdNumber />
            </Box>
            <Box mt={3} sx={{ textAlign: "center" }}>
                <Typography
                    sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, color: "black" }}
                >
                    {t("newabhalogin")}
                </Typography>
            </Box>
            <Box
                sx={{ textAlign: "center", mr: 2, mt: 2 }}
                onClick={() => navigate("/connecthealthid")}
            >
                <Button
                    variant="outlined"
                    startIcon={
                        <img src={Abhalogo} alt="abha" width="20px" height="20px" />
                    }
                    sx={{
                        width: { xs: "200px", sm: 230, md: "200px" },
                        height: "40px",
                        gap: { sm: 1, md: 1 },
                        "&:hover": { border: "1px solid #B18BE2" },
                        border: "1px solid #B18BE2",
                        borderRadius: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: "16px", sm: "18px", md: "14px" },
                            color: "#B18BE2",
                            fontWeight: 600
                        }}
                    >
                        {t("createhealthid")}
                    </Typography>
                </Button>
            </Box>
        </Box >
    );
}

export default LoginAbhaAddress