import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, Button, CircularProgress, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { ArrowBackIos } from '@mui/icons-material';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { handleRequestError } from '../../../atoms/helperFunctions';

function CreateAddressWithAbhaNo() {
    const dispatch = useDispatch();
    const location = useLocation()
    const transactionValues = location.state

    const navigate = useNavigate();
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber);
    const transactionId = transactionValues.transactionId;
    const jwttoken = transactionValues.jwttoken1
    const [username, setUsername] = useState('');
    const [filteredUsernames, setFilteredUsernames] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState([]);
    const aadhar = useSelector((state) => state.abha.aadhartokenHin);
    const xtoken = aadhar.xtoken;

    const token = localstore.getToken();
    const primaryId = localstore.getPrimaryId();

    console.log(apiResponse, "apiResponse");

    // Function to handle suggestion fetching
    const handleSuggestion = async (text) => {
        if (apiResponse?.length > 0) {
            filterSuggestions(text);
            setShowSuggestions(true);
        } else {
            try {
                if (transactionId) {
                    // Make the API call to fetch suggestions
                    console.log(`Fetching suggestions for text: ${text}`);
                    const response = await axios.get(`${URL.abha}aadhaar/suggestion/${transactionId}`, {
                        headers: {
                            gatewayToken: 'medxperts',
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (response.status === 200 || response.status === 201) {
                        // Set the API response
                        console.log('API response:', response.data);
                        setApiResponse(response?.data?.abhaAddressList);
                        filterSuggestions(text, response?.data?.abhaAddressList);
                    }
                }
            } catch (error) {
                console.log('Error fetching suggestions:', error);
                // Handle error if necessary
            }
        }
    };

    // Filter suggestions based on user input
    const filterSuggestions = (value, list = apiResponse) => {
        console.log('Filtering suggestions:', value);
        const filteredList = list?.filter((username) =>
            username?.toLowerCase()?.includes(value?.toLowerCase())
        );
        setFilteredUsernames(filteredList);
        setShowSuggestions(true);
    };

    // API call for creating ABHA address
    const handlecreatephr = async (values) => {
        setLoading(true);
        if (token && primaryId) {
            const data = {
                abhaAddress: values.username,
                txnId: transactionId,
            };

            try {
                const res = await axios.post(`${URL.abha}v2/createHealthIdByAdhaar/${primaryId}`, data, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (res.status === 200 || res.status === 201) {
                    // Handle success response (optional)
                    console.log('ABHA Address created successfully');
                }
            } catch (error) {
                let errorMessage = handleRequestError(error);
                console.error("Error while creating ABHA address:", errorMessage);
                // Handle error if necessary
            } finally {
                setLoading(false);
            }
        }
    };

    // Debounced search function
    const debouncedSearch1 = debounce((text) => handleInputChange(text), 500);

    // Handle input change
    const handleInputChange = async (value) => {
        console.log(value, "valueess");
        setUsername(value);
        if (value) {
            await handleSuggestion(value); // Ensure API call happens
        } else {
            setShowSuggestions(false);
            setFilteredUsernames([]);
        }
    };

    return (
        <div>
            <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '44px' }, mb: { xs: 10, sm: 10, md: 5 } }}>
                <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, color: '#007DCD', fontWeight: 600, mt: { md: 2, sm: "15px", xs: "10px" } }}>
                    <IconButton onClick={() => navigate(-1)}><ArrowBackIos sx={{ color: '#007DCD' }} /></IconButton>
                    Create / Link ABHA Address
                </Typography>
                <Paper elevation={3} sx={{ p: 4, mt: 1, display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ maxWidth: '450px', width: '100%' }}>
                        <Typography sx={{ fontStyle: 'inherit', fontSize: { xs: 16, sm: 18, md: 16 } }}>
                            Username
                        </Typography>
                        <Box sx={{ width: '100%', mt: 1 }}>
                            <Autocomplete
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#59D8FA',
                                        },
                                    },
                                    ".MuiInputBase-root": {
                                        borderRadius: '7px',
                                        backgroundColor: 'whitesmoke',
                                    },
                                }}
                                size="small"
                                freeSolo
                                options={filteredUsernames}
                                value={username}
                                onInputChange={(event, newValue) => {
                                    debouncedSearch1(newValue); // Debounced call to handleInputChange
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Enter username"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Typography sx={{ color: '#000000' }}>@sbx</Typography>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Grid mt={2} container justifyContent="center">
                            <Grid item>
                                <Button
                                    type="submit"
                                    sx={{ borderRadius: 3, mt: 2, width: "100%", bgcolor: '#9560D9' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handlecreatephr}
                                    startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                                    disabled={!username}
                                >
                                    Create New ABHA Address
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid mt={2} container justifyContent="center">
                            <Grid item>
                                <Button
                                    type="submit"
                                    sx={{ borderRadius: 3, mt: 2, width: "100%", borderColor: '#9560D9', color: '#9560D9' }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => navigate('/viewabhacard', { state: { jwttoken1: jwttoken } })}
                                >
                                    Existing ABHA Address
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </div>
    );
}

export default CreateAddressWithAbhaNo;
