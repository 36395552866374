import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Box, Button, Card, CircularProgress, FormControl, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowForward } from '@mui/icons-material';
import URL, { OtpPublicKey } from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaCard, getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { handleErrorResponse } from '../../../atoms/StatusError';
import { encryptData } from '../../../../utility/encryption';
import { handleRequestError, updateIdentifier, updateRelatedIdentifier, updateRelatedPersonData } from '../../../atoms/helperFunctions';
import { getpasswordsession } from '../../../../redux/Actions/phrActions';


function SelectVerification(props) {

    const auth = useSelector((state) => state.abha.authMethods)
    const authvalues = auth
    // console.log(auth, "autheeeelinking")
    const aadhartokenHin = useSelector((state) => state.abha.aadhartokenHin)
    const transactionId = useSelector((state) => state.abha.mobilelinktransactionId)
    const result = useSelector((state) => state.phraddress.mobiletransactionId)
    const switchprofiles = useSelector(stat => stat.medx.switchprofile?.response?.entry);

    const ibm = useSelector((state) => state.medx.ibmpdata)
    const phrAddress = useSelector((state) => state.abha.phrAddress)
    // console.log(aadhartokenHin, "result")
    // const Txnid = result?.transactionId
    // const Email = result.Email
    const [mobileOtp, setMobileOtp] = useState('')
    const [emailOtp, setEmailOtp] = useState('')
    const [password, setPassword] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [t, i18n] = useTranslation("global");
    const [message, setMessage] = useState(null);
    const [Txnid, settxd] = useState(null);
    const [otp, setOtp] = useState('');
    const [passwordvalue, setPaswordValue] = useState('');


    const [selectedOption, setSelectedOption] = useState("PASSWORD");
    //  const [selectedOption, setSelectedOption] = useState('');
    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertopen(true);
        setAlertmessage(message);
        setAlertstatus(status);
        setTimeout(() => {
            setAlertopen(false);
        }, 5000);
    };

    useEffect(() => {
        let passwordtnx1 = {
            authMethod: 'PASSWORD',
            healthid: phrAddress,
        };
        if (primaryId && token && passwordtnx1) {
            dispatch(getpasswordsession(primaryId, token, passwordtnx1))
        }
    }, [])
    const handleOptionChange = (e) => {
        const selectDropdown = e.target.value
        // console.log("eee", hello)

        setSelectedOption(e.target.value);
        let selectValue;
        const encryptphradd = encryptData(OtpPublicKey, phrAddress);
        let d = {
            authMethod: 'MOBILE_OTP',
            healthid: encryptphradd,
        };
        let d1 = {
            authMethod: 'EMAIL_OTP',
            healthid: encryptphradd,
        };
        let passwordtnx = {
            authMethod: 'PASSWORD',
            healthid: phrAddress,
        };

        let apiUrlSegment = '';

        if (selectDropdown === 'MOBILE_OTP') {
            selectValue = d;
            apiUrlSegment = 'auth/init';
        } else if (selectDropdown === 'EMAIL_OTP') {
            selectValue = d1;
            apiUrlSegment = 'auth/init';
        } else if (selectDropdown === 'PASSWORD') {
            showAlert('Enter your Password', 'success')
            if (primaryId && token && passwordtnx) {
                dispatch(getpasswordsession(primaryId, token, passwordtnx))
            }
        }
        if (selectDropdown === 'EMAIL_OTP' || selectDropdown === 'MOBILE_OTP') {
            axios.post(`${URL.abha}${apiUrlSegment}/${primaryId}`, selectValue, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`
                },
            }).then(res => {
                setMessage(res?.data?.message)
                // console.log('response', res)
                // if (settimer === true) {
                //     setTime(59);
                // }
                settxd(res.data.txnId || res.data.transactionId);
                showAlert(`OTP sent to the Registered ${selectDropdown}`, 'success')
            }).catch(err => {
                let errorMessage = handleRequestError(err);
                showAlert(errorMessage, 'error');
            }).finally(() => {
                // setLoading(false);
                setOtp('');
                setPaswordValue('');
            });
        }
        setOtp('');
        setPaswordValue('');
    }

    const handleLogin = async () => {
        try {
            setLoading(true);

            // Step 1: Encrypt OTP and Password
            const encryptOtp = encryptData(OtpPublicKey, mobileOtp);
            const encryptPassword = encryptData(OtpPublicKey, password);

            // Step 2: Prepare API data based on selectedOption
            const selectedData = getSelectedData(encryptOtp, encryptPassword);
            const apiUrlSegment = getApiUrlSegment(selectedOption);

            // Step 3: Make the API call for OTP / Password verification
            const response = await axios.post(
                `${URL.abha}${apiUrlSegment}/${primaryId}`,
                selectedData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        gatewayToken: 'medxperts',
                        // 'X-CSRF-TOKEN': `${csrf}`,
                    },
                }
            );
            console.log(response, 'response')
            // Step 4: Handle response
            if (response?.data?.authResult === 'failed') {
                showAlert(response?.data?.message || 'Password did not match, please try again', 'error');
                return;
            }

            // Step 5: Update patient data based on the response
            const updatedData = updatePatientData(response?.data?.users?.[0]);
            const updateRelated = updateRelatedIdentifier(ibm, switchprofiles, response?.data?.users?.[0].abhaAddress, response?.data?.users?.[0]?.fullName, 'ABHA')
            // console.log(updateRelated, 'updateRelated')
            await updatePatientDataInApi(updatedData);
            // Step 6: Conditional check to update related person if ibm.identifier is missing or invalid
            // if (!ibm?.identifier || !ibm?.identifier[0]?.system || ibm?.identifier[0]?.system !== 'https://patientMedxperts') {
            // If the identifier is missing or invalid, proceed with updating the related person
            await updateRelatedPersonData(updateRelated, updateRelated?.id);
            // }


            // Step 6: Finalize success
            showAlert(response?.data?.message || 'Verified Successfully!', 'success');
            dispatch(getPatientdatafromibm('Patient', primaryId, token));
            dispatch(getAbhaProfile(primaryId, token));
            dispatch(getAbhaCard(token, primaryId));

            // Navigate to Landing Page
            navigate('/');
        } catch (err) {
            console.log('Error during login process:', err);
            const errorMessage = handleRequestError(err);
            showAlert(errorMessage, 'error');
        } finally {
            setLoading(false);
        }
    };
    const getSelectedData = (encryptOtp, encryptPassword) => {
        switch (selectedOption) {
            case 'MOBILE_OTP':
                return {
                    authMethod: 'MOBILE_OTP',
                    otpValue: encryptOtp,
                    txnId: Txnid,
                };
            case 'EMAIL_OTP':
                return {
                    authMethod: 'EMAIL_OTP',
                    otpValue: encryptOtp,
                    txnId: Txnid,
                };
            case 'PASSWORD':
                return {
                    authMethod: 'PASSWORD',
                    password: encryptPassword,
                    abhaAddress: phrAddress,
                };
            default:
                return null;
        }
    };

    const getApiUrlSegment = (selectedOption) => {
        switch (selectedOption) {
            case 'MOBILE_OTP':
            case 'EMAIL_OTP':
            case 'PASSWORD':
                return 'new/confirmOtp';
            default:
                return '';
        }
    };

    const updatePatientData = (user) => {
        console.log('user', user)
        let updatedData = ibm;

        if (user?.abhaAddress) {
            updatedData = updateIdentifier(updatedData, user.abhaAddress, 'ABHA');
        }

        if (user?.abhaNumber) {
            updatedData = updateIdentifier(updatedData, user.abhaNumber, 'HIN');
        }
        console.log('updatedData', updatedData)
        return updatedData;
    };

    const updatePatientDataInApi = async (updatedData) => {
        try {
            const response = await axios.put(
                `${URL.cliniapi}Patient/${primaryId}`,
                updatedData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response) throw new Error('Failed to update patient data.');
        } catch (err) {
            throw new Error(`Error updating patient data: ${err.message}`);
        }
    };


    const handleMobileOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setMobileOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }
    const handleEmailOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setEmailOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }


    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: { xs: 1, sm: 2, md: 2 },
                    mt: { xs: "10px", sm: "15px", md: 2 },
                }}
            >
                {/* <IconButton onClick={() => props.setShow(3)}><ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} /></IconButton> */}
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: { xs: 17, sm: "17px", md: "17px" }, color: '#007DCD'
                    }}
                >
                    {t("selectvermethod")}
                </Typography>
            </Box>
            <Paper elevation={2} sx={{ mt: 1, p: 3, display: "flex", justifyContent: "center" }}>
                <Box alignItems={"center"}>
                    <Typography sx={{ fontWeight: 400 }}>
                        {t("phradd")}
                        <Typography
                            component={"span"}
                            sx={{ fontSize: { sm: 17, md: 17 }, fontWeight: 500 }}
                        >
                            {phrAddress}
                        </Typography>
                    </Typography>

                    <Box mt={1}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <Select value={selectedOption} onChange={handleOptionChange}>
                                {authvalues?.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            {option}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {selectedOption === "MOBILE_OTP" ||
                        selectedOption === "EMAIL_OTP" ?
                        (
                            <Box mt={2}>
                                {/* <TextInputField name="mobileOtp" placeholder="Enter Mobile OTP" value={mobileOtp} onChange={handleMobileOtp} /> */}
                                <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                        },
                                        ".MuiOutlinedInput-notchedOutline": {
                                            // border: props.border
                                        },
                                        width: "350px",
                                    }}
                                    placeholder={t("credentialsData.signupmobiledata.enterotp")}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleMobileOtp}
                                    value={mobileOtp}
                                    autoFocus
                                    autoComplete="off"
                                    type={"text"}
                                />
                            </Box>
                        ) : null}

                    {/* {selectedOption === "email" ? (
                    <Box mt={2}>
                        <TextInputField name="emailOtp" placeholder="Enter Email OTP" value={emailOtp} onChange={handleEmailOtp} />
                    </Box>
                ) : null} */}

                    {selectedOption === "PASSWORD" ? (
                        <Box mt={2}>
                            <TextField
                                sx={{
                                    ".MuiInputBase-root": {
                                        borderRadius: 2,
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                        // border: props.border
                                    },
                                    width: "350px",
                                }}
                                placeholder={t("credentialsData.signupmobiledata.enterpswd")}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                autoFocus
                                autoComplete="off"
                                type={"password"}
                            />{" "}
                        </Box>
                    ) : null}

                    {alertmessage && (
                        <Typography
                            sx={{
                                color: alertstatus === "success" ? "green" : "red",
                                mt: 1, fontSize: '14px'
                            }}
                        >
                            {alertmessage}
                        </Typography>
                    )}
                    <Box sx={{ textAlign: "center", mt: 4 }}>
                        <Button
                            variant="contained"
                            sx={{ width: "120px", borderRadius: 2 }}
                            onClick={handleLogin}
                            disabled={loading}
                            endIcon={
                                loading ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    <ArrowForward sx={{ fontWeight: "bold" }} />
                                )
                            }
                        >
                            {t("credentialsData.commondata.login")}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default SelectVerification