import React, { useEffect, useState } from "react";
import { Box, Button, Card, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material";
import { ArrowForwardIos, ReportProblem, SaveAltOutlined, VerifiedUser, WarningAmber } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import URL from "../../utility/apiurl";
import { localstore } from "../../localStore/localStore";
import axios from "axios";
import { useTranslation } from "react-i18next";
import AyushIcon from '../../assets-new/profile-icons/abhalogo.svg'
import nationalIcon from '../../assets-new/profile-icons/healthministry.cc00ac5f.svg';
import QuickshareIcon from '../../assets-new/profile-icons/quickshare.svg';
import consentsIcon from '../../assets-new/profile-icons/consents.svg'
import linkprovidersIcon from '../../assets-new/profile-icons/linkProviders.svg';
import editprofileIcon from '../../assets-new/profile-icons/editabhaprofile.svg';
import switchprofileIcon from '../../assets-new/profile-icons/switchprofile.svg';
import createprofileIcon from '../../assets-new/profile-icons/createprofile.svg';
import arrowrightIcon from '../../assets-new/profile-icons/arrow_right.svg';
import logoutIcon from '../../assets-new/profile-icons/logoutIcon.svg';
import { getAbhaMobile, getAbhaProfile, getFeedwithoutToken } from "../../redux/Actions/Actions";
import TokenPhrAddress from "../pages/Medxperts/Tokenexpiry/TokenPhr";
import UserService from "../../services/UserService";
import { OutlinedButton } from "../atoms/CommonButton";
import { getHealthLockersData, getHealthStatus } from "../../redux/Actions/consentActions";
import AbhaLogo from '../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { ErrorStatusMsg } from "../atoms/ResendOtp";
import CreateConsentPin from "../pages/Medxperts/ConsentSecurity/CreateConsentPin";
import abhalogo from '../../assets-new/abha-logo.svg'
import moment from "moment";

const CardDetails = (props) => {
    return (
        <Paper elevation={3} sx={{ p: 1 }}>
            <Grid container>
                <Grid item xs={2} md={2} mt={"3px"}>
                    <img src={props.icon} alt={props.alt} />
                </Grid>
                <Grid item xs={8.5} md={8.5}>
                    <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>{props.title}</Typography>
                    <Typography sx={{ fontSize: '11px', mt: '4px' }}>{props.paragraph}</Typography>
                </Grid>
                <Grid item xs={1.5} md={1.5} mt={1}>
                    <IconButton onClick={props.onClick}> <img src={arrowrightIcon} alt="right" /></IconButton>
                </Grid>
            </Grid>
        </Paper>
    )
}

const NavCards = (props) => {
    return (
        <Paper elevation={3} onClick={props.onClick} sx={{ p: "6px 10px", cursor: 'pointer' }}>
            <Grid container alignItems={'center'}>
                <Grid item xs={2} md={2} >
                    <img src={props.icon} alt={props.alt} />
                </Grid>
                <Grid item xs={10} md={10} >
                    <Typography sx={{ fontSize: '14px' }}> {props.title}</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}
function AbhaCard(props) {

    const [t, i18n] = useTranslation("global");
    // console.log(props, "props")
    const { ibmpdata, phdState, fixedid, selectedid, handleClose } = props;
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    const mobiledata = useSelector((state) => state.abha.mobileprofile)

    // console.log(mobiledata, "qqrrrrrrrrrrr")
    const navigate = useNavigate();
    const patientId = localstore.getPatientId();
    const primaryId = localstore.getPrimaryId();
    const [open, setOpen] = useState(false)
    const token = localstore.getToken();
    const dispatch = useDispatch();
    const [qrcode, setQrcode] = useState(null);
    const [alertstatus, setAlertstatus] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingprofile, setLoadingprofile] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false); // State variable for dialog open/close
    // console.log(qrcode, "qrcode")
    useEffect(() => {
        dispatch(getAbhaProfile(primaryId, token))
        dispatch(getAbhaMobile(primaryId, token))
    }, [primaryId, token])


    // useEffect(() => {
    //     if (abhadata && abhadata.validity) {
    //         const currentDate = new Date();
    //         const validityDate = new Date(abhadata.validity); // Assuming abhadata.validity is "yyyy-mm-dd" format

    //         if (validityDate <= currentDate) {
    //             setIsDialogOpen(true);
    //         } else {
    //             setIsDialogOpen(false); // Close the dialog if not expired
    //         }
    //     }
    // }, [abhadata.validity]);

    useEffect(() => {
        if (abhadata && abhadata.validity) {
            const currentDate = moment().unix();
            const targetDate = moment(abhadata.validity).unix();

            if (targetDate <= currentDate) {
                setTimeout(() => {
                    setIsDialogOpen(true);
                    // onOpen();
                }, 200);
            } else {
                setIsDialogOpen(false); // Close the dialog if not expired

            }
        }
    }, [abhadata.validity]);

    // console.log(qrcode, "qrcode")

    useEffect(() => {
        if (patientId && abhadata.AbhaAddress && token && primaryId) {
            // console.log(patientId, "paaaaaaaa")
            setLoading(true); // Set loading to true before starting the request

            axios
                .get(
                    `${URL.abhaQRCode}/${primaryId}/${patientId}/${abhadata.AbhaAddress}`,
                    {
                        headers: {
                            gatewayToken: "medxperts",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    // console.log("------>>>> ResponseQRCODE", res.data);
                    const qrcodeimage = res.data
                    setQrcode(qrcodeimage);
                    // dispatch(setAbhaQRCode(response.data.qrcode_url));
                })
                .catch((error) => console.log(error.message)).finally(() => {
                    setLoading(false)
                })

        }
    }, [abhadata]);

    const linkedAbhaHealthId = () => {
        navigate("/loginabha");
    };

    const handleDownload = async () => {
        setLoadingprofile(true)
        await axios
            .get(`${URL.consents}abha/download/profile/${primaryId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    gatewayToken: "medxperts",
                },
            })
            .then((res) => {
                // console.log(res, '140')
                const abhacard = res.data
                // const abhacard = btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                // const abhacardData = Buffer.from(response.data, 'binary').toString('base64');


                handleClose()
                navigate('/downloadabhacard', { state: { abhacard: abhacard } })
                // console.log(abhacard, "abhacardurll")
                // alert("downloaded successfully");
                setAlertstatus("success");
            }).catch(error => {
                setAlertstatus("error");
            })
            .finally(() => {
                setLoadingprofile(false);
            });
    }

    const handleCompleteKyc = () => {
        if (Object.keys(abhadata).length > 0 && abhadata.AbhaAddress === null) {
            navigate('/abhanumber', { LoginFlow: false })
            handleClose()

        } else {
            navigate('/verifykyc')
            handleClose()

        }

    }
    const logoutHandler = () => {
        navigate("/");
        localStorage.removeItem('userId')
        localStorage.removeItem('patientId')
        localStorage.removeItem('patientName')
        localStorage.removeItem('primaryId')
        localStorage.removeItem('primaryFixedid');
        localStorage.removeItem('kctoken')
        localStorage.removeItem('csrftoken')

        // dispatch(getFeedwithoutToken())
        window.location.reload(true);
    };
    const handleClickAddUser = () => {
        handleClose()
        navigate("/createprofile");

    };

    const handleClickSwitch = () => {
        handleClose()
        navigate("/switchprofile");
    };

    const handleConsentPin = () => {
        if (mobiledata?.id) {
            setOpen(!open)
        } else {
            alert('Please Register or login ABHA Address and later Set Pin');
        }
    }
    const handleHealthLocker = () => {
        if (mobiledata?.hasTransactionPin) {
            handleClose()
            navigate("/healthlocker");
        } else {
            alert('Please set Consent Pin and later Health Locker');
        }
    }

    const handlehealthlockerlist = () => {
        handleClose()
        navigate('/records/medicalrecords')
    }

    return (
        <Box>
            <Box p={'5px'}>

                <Paper elevation={3} sx={{
                    borderRadius: "5px 5px 5px 5px",
                    mt: 1,
                    bgcolor: '#F9FBFD',
                }}>
                    <Box sx={{
                        display: 'flex', bgcolor: "#067DCD",
                        alignItems: 'center', gap: "5px", p: '5px', justifyContent: 'space-between'
                    }}>
                        <img src={AyushIcon} alt="ayush" width={34} height={34} />
                        <Box sx={{ color: 'white' }}>
                            <Typography sx={{ fontWeight: 500, fontSize: '11px' }}>Ahushman Bharat National Health Identity</Typography>
                            <Typography sx={{ fontSize: '10px', textAlign: 'center', fontWeight: 400 }}>National Health Authority,Govt.of.Bharat</Typography>
                        </Box>
                        <img src={nationalIcon} alt="nation" />
                    </Box>
                    {(abhadata?.AbhaNumber || abhadata?.AbhaAddress) &&
                        ibmpdata?.identifier?.length > 0 ? (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: '5px', justifyContent: 'space-between' }}>
                                {
                                    abhadata?.Name?.length > 0 ? (
                                        <Typography
                                            sx={{ fontWeight: 700, ml: 1, mt: '2px', letterSpacing: 0, fontSize: "14px" }}
                                        >
                                            {abhadata?.Name}
                                        </Typography>
                                    ) : null

                                }
                                <Box sx={{ display: 'flex', alignItems: 'center', color: '#8014FF', pr: '10px' }}>
                                    <VerifiedUser sx={{ fontSize: '14px' }} />
                                    <Typography sx={{ fontSize: '13px' }}>Verified</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Grid container sx={{ p: '0px 0px 10px 5px' }}>
                                    <Grid item xs={3.8} sm={3.8} md={3.8} mt={1}>
                                        {/* <img src={`data:image/png;base64,${qrcode}`} alt="qrcode" width={100} height={100} /> */}
                                        {loading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>   <CircularProgress size={20} /> </div>
                                        ) : (
                                            <img src={`data:image/png;base64,${qrcode}`} alt="qrcode" width={100} height={100} />
                                        )}
                                    </Grid>
                                    <Grid item xs={8.2} sm={8.2} md={8.2} container mt={1}>
                                        <Grid item xs={5} sm={5} md={5}>
                                            <Typography sx={{ letterSpacing: 0 }} fontSize={13}>
                                                {t("abhanum")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7} sm={7} md={7}>
                                            {abhadata.AbhaNumber ? (
                                                <Typography
                                                    sx={{ letterSpacing: 0, fontWeight: 600, }}
                                                    fontSize={13}
                                                >
                                                    {abhadata.AbhaNumber}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    sx={{ letterSpacing: 0, fontWeight: 600, }}
                                                    fontSize={13}
                                                >
                                                    N/A
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={5} md={5}>
                                            <Typography
                                                sx={{ letterSpacing: 0 }}
                                                fontSize={13}
                                            >
                                                PHR Address
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7} sm={7} md={7}>
                                            {abhadata.AbhaAddress?.length > 17 ? (
                                                <Typography
                                                    sx={{
                                                        letterSpacing: 0,
                                                        fontWeight: 600,
                                                    }}
                                                    fontSize={13}
                                                >
                                                    {abhadata.AbhaAddress.slice(0, 17)}..
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        letterSpacing: 0,
                                                        fontWeight: 600,
                                                    }}
                                                    fontSize={13}
                                                >
                                                    {abhadata.AbhaAddress}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={5} sm={5} md={5}>
                                            <Typography
                                                sx={{ letterSpacing: 0, }}
                                                fontSize={13}
                                            >
                                                {t("gender")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5} sm={7} md={7}>
                                            {abhadata.Gender ? (
                                                <Typography
                                                    sx={{ letterSpacing: 0, fontWeight: 600, mt: "2px" }}
                                                    fontSize={13}
                                                >
                                                    {abhadata.Gender}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    sx={{ letterSpacing: 0, fontWeight: 600, }}
                                                    fontSize={12}
                                                >
                                                    N/A
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={5} sm={5} md={5}>
                                            <Typography
                                                sx={{ letterSpacing: 0, }}
                                                // variant="h6"
                                                fontSize={13}
                                            >
                                                Date of Birth
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7} sm={7} md={7}>
                                            {abhadata.DateofBirth ? (
                                                <Typography
                                                    sx={{ letterSpacing: 0, fontWeight: 600, }}
                                                    fontSize={13}
                                                >
                                                    {abhadata.DateofBirth}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    sx={{ letterSpacing: 0, fontWeight: 600, }}
                                                    fontSize={13}
                                                >
                                                    N/A
                                                </Typography>
                                            )}
                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Box>

                            {
                                abhadata.AbhaNumber ? null :
                                    <Box sx={{
                                        borderRadius: "0px 0px 5px 5px",
                                        bgcolor: '#FF0000'
                                    }}>
                                        <Box sx={{ p: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#FFFFFF', cursor: 'pointer' }} onClick={handleCompleteKyc}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <WarningAmber sx={{ fontSize: '17px' }} />
                                                <Typography sx={{ fontSize: '14px' }}>
                                                    {abhadata?.AbhaAddress === null ? "Connect ABHA Address" : "Complete KYC to create ABHA Number"}
                                                </Typography>
                                            </Box>
                                            <ArrowForwardIos />
                                            {/* <Button sx={{ bgcolor: '#0093C4', '&:hover': { bgcolor: '#0093C4' }, width: "85px", height: '25px', color: 'white', borderRadius: '15px' }}>
                                                <Typography sx={{ fontSize: '12px' }}
                                                    onClick={() => {
                                                        handleClose()
                                                        navigate({ pathname: "/verifykyc" })
                                                    }}
                                                >Verify KYC</Typography>
                                            </Button> */}
                                        </Box>
                                    </Box>

                            }

                            {
                                abhadata.AbhaAddress && abhadata.AbhaNumber && (
                                    <>
                                        <Box sx={{
                                            borderRadius: "0px 0px 5px 5px",
                                            bgcolor: '#1C9D5E', cursor: 'pointer'
                                        }}
                                            onClick={handleDownload}>
                                            <Box sx={{ p: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#FFFFFF' }}>
                                                <Typography sx={{ fontSize: '14px' }}>View / Download ABHA Card</Typography>
                                                {loadingprofile ? <CircularProgress size={20} color="inherit" /> : <ArrowForwardIos />}
                                            </Box>
                                        </Box>
                                    </>
                                )
                            }
                        </>
                    ) :
                        <Box sx={{ textAlign: 'center', p: '15px 10px' }}>
                            <Typography sx={{ fontWeight: 500 }}>Register New ABHA Address</Typography>
                            <Typography sx={{ mt: '10px', fontSize: '12px' }} component='p'> ABHA Address is you Personal Health Records Address(PHR) to link your health record,provide consent and share any of your health records with any chosen participating Healthcare Provider</Typography>
                            <Button sx={{ mt: '10px', width: '120px', bgcolor: '#0093C4', '&:hover': { bgcolor: '#0093C4' }, height: '25px' }} variant="contained" startIcon={<img src={AbhaLogo} width={17} height={17} />}
                                onClick={() => {
                                    handleClose()
                                    navigate("/connecthealthid")
                                }}
                            >Register</Button>
                        </Box>
                    }
                </Paper>

                <Box mt={2}>
                    <CardDetails icon={QuickshareIcon} title="Quick Share" paragraph="Registration and Appointment Booking at Hospitals and clinics is made easy" onClick={() => {
                        handleClose()
                        navigate('/quickshare')
                    }} />
                </Box>
                <Box mt={2}>
                    {mobiledata.hasTransactionPin ?
                        <CardDetails icon={consentsIcon} title="Consents Management" paragraph="See and manage who can view or update your medical Records" onClick={() => {
                            handleClose()
                            navigate('/consents')
                        }} /> :
                        <Paper elevation={3} sx={{ p: 1 }}>
                            <Grid container >
                                <Grid item xs={2} md={2} mt={"3px"}>
                                    <img src={consentsIcon} alt='link' />
                                </Grid>
                                <Grid item xs={7.5} sm={7.5} md={7.5}>
                                    <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>Consents</Typography>
                                    <Typography component='p' sx={{ fontSize: '11px' }}>See and manage who can view or update your medical Records</Typography>
                                </Grid>
                                <Grid item xs={2.5} sm={2.5} md={2.5} mt={"20px"}>
                                    {/* <OutlinedButton text="Setup Now" bgcolor="#FF0000" fontSize="11px" onClick={() => {
                                            handleClose()
                                            navigate('/healthlocker')
                                        }} /> */}

                                    <Button variant="outlined" sx={{ '&:hover': { borderColor: '#9560D9' }, borderColor: '#9560D9', color: '#9560D9' }}
                                        onClick={
                                            // handleClose()
                                            // navigate('/healthlocker')
                                            handleConsentPin
                                        }>
                                        <Typography sx={{ fontSize: "10px" }}>Set PIN</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    }
                </Box>

                <Box mt={2}>
                    {
                        props.healthstatus === "OK" ?
                            <CardDetails icon={linkprovidersIcon} title="Link Medical Records" paragraph="Select hospitals or clinics you visited, to Fetch your Medical Records" onClick={handlehealthlockerlist} />
                            :
                            <Paper elevation={3} sx={{ p: 1 }}>
                                <Grid container >
                                    <Grid item xs={2} sm={2} md={2} mt={"3px"}>
                                        <img src={linkprovidersIcon} alt='link' />
                                    </Grid>
                                    <Grid item xs={6.9} sm={6.9} md={6.9}>
                                        <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>Health Locker</Typography>
                                        <Typography component='p' sx={{ fontSize: '11px' }}>Health locker will link all your medical records and make accessible on click</Typography>
                                    </Grid>
                                    <Grid item xs={3.1} sm={3.1} md={3.1} mt={"20px"}>
                                        <Button variant="outlined" sx={{ borderRadius: '7px', '&:hover': { borderColor: '#ED0000' }, borderColor: '#ED0000', color: '#ED0000' }}
                                            onClick={handleHealthLocker}>
                                            <Typography sx={{ fontSize: "10px" }}>Setup Now</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                    }
                </Box>

                {/* <Box mt={1}>
                    <NavCards icon={editprofileIcon} title="EDIT ABHA Profile" onClick={() => {
                        handleClose()
                        navigate('/editprofile')
                    }} />
                </Box> */}

                <Grid container columnSpacing={2}>
                    {/* {mobiledata.hasTransactionPin && */}
                    <Grid item xs={6} sm={6} md={5.5} sx={{ p: "6px 10px", cursor: 'pointer', mt: 2 }} onClick={() => {
                        handleClose()
                        navigate('/profilesettings')
                    }}>
                        <Paper elevation={3} sx={{ textAlign: 'center', borderRadius: '10px', p: '10px', height: '125px' }}>
                            <img src={editprofileIcon} alt="abhalogo" width={30} height={30} style={{ marginTop: 1 }} />
                            <Typography sx={{ fontWeight: 600, fontSize: '14px', mt: 1 }}>Settings</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: '11px' }}>Edit and Manage You Profile details</Typography>

                        </Paper>
                    </Grid>
                    {/* } */}
                    <Grid item xs={6} sm={6} md={6.5} sx={{ p: "6px 10px", cursor: 'pointer', mt: 2 }} onClick={() => {
                        handleClose()
                        navigate('/healthlocker')
                    }}>
                        {
                            props.healthstatus === "OK" &&
                            <Paper elevation={3} sx={{ textAlign: 'center', borderRadius: '10px', p: '10px', height: '125px' }}>
                                <img src={abhalogo} alt="abhalogo" width={40} height={40} />
                                <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>health Locker</Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: '11px' }}>Manage health locker's Access to your Data</Typography>
                            </Paper>
                        }
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <NavCards icon={switchprofileIcon} title="Switch Profile" onClick={handleClickSwitch} />
                </Box>
                <Box mt={2}>
                    <NavCards icon={createprofileIcon} title="Create Profile" onClick={handleClickAddUser} />
                </Box>
                <Box mt={2}>
                    <NavCards icon={logoutIcon} title="Logout" onClick={logoutHandler} />
                </Box>
            </Box >

            <TokenPhrAddress open={isDialogOpen} setOpen={setIsDialogOpen} popoverclose={handleClose} />
            <CreateConsentPin open={open} handleClose={handleClose} mobiledata={mobiledata} />
        </Box >
    );
}

export default AbhaCard;