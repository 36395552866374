import React from 'react'
import axios from 'axios'
import { ArrowForward, Check, Link } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import URL from '../../../../utility/apiurl'
import { useHistory, useNavigate } from "react-router-dom";
import { useEffect } from 'react'
import { getAbhaCard, getAbhaProfile, getAbhaQRCode, getPatientdatafromibm } from '../../../../redux/Actions/Actions'
import { localstore } from '../../../../localStore/localStore'
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { useTranslation } from "react-i18next";
import { handleErrorResponse } from '../../../atoms/StatusError'
import { handleRequestError, updateIdentifier, updateRelatedIdentifier, updateRelatedPersonData } from '../../../atoms/helperFunctions'
import jwtDecode from 'jwt-decode'
import linkIcon from '../../../../assets/home/records_module/link-white.svg'




function LoginSelectPhr(props) {
    const phrsdetails = useSelector((state) => state.phraddress.loginselecphr)
    const switchprofiles = useSelector((state) => state.medx.switchprofile?.response?.entry);
    const phrs = phrsdetails?.phrs
    const transactionId = phrsdetails?.transactionId
    const XToken = phrsdetails?.XToken
    const abhaNumber = phrsdetails?.AbhaNumber

    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [abhatoken, setAbhatoken] = useState('')
    // console.log(phrs, "abmobileeeeeeeeeeee")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [phradd, setPhradd] = useState('');
    const token = localstore.getToken()
    const pid = localstore.getPatientId()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    // console.log(result, "result-------->>>")
    console.log(abhaNumber, "result-------->>>")
    // const transactionId = result.transactionId
    const mobileNumber = result.mobile
    let abhadata = useSelector(state => state.abha.abhaprofile);
    // console.log(abhadata, "abhadtaaaaaaaaaaaaaaaaaaaaaaaa")
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const [t, i18n] = useTranslation("global");

    const handleSelect = (e) => {
        // console.log(e, "eeeeeeeeeee")

        // if (phradd === Object.keys(e)[0]) {
        // setPhradd('');
        // } else {
        setPhradd(Object.keys(e)[0]);
        setErrorMessage('')
        // }
    }

    const showAlert = (message, status) => {
        setAlertopen(true);
        setAlertMessage(message);
        setAlertstatus(status);
        setTimeout(() => {
            setAlertopen(false);
        }, 5000);
    };

    const handleOnLink1Next = async () => {
        setLoading(true)
        if (phradd?.length > 0) {

            const data = {
                abhaAddress: phradd, "txnId": transactionId
            }
            let value = abhaNumber === true ? "hid" : "mobileEmail"
            await axios.post(`${URL.abha}login/${value}/confirm/otp/${primaryId}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    gatewayToken: "medxperts",
                    'T-token': `Bearer ${XToken}`
                }
            })
                .then(async res => {
                    showAlert('Successfully Linked !', 'success');
                    let patient = jwtDecode(res?.data?.token);
                    // console.log(patient, 'patient');

                    let updatedData = ibm;
                    if (phradd) {
                        updatedData = updateIdentifier(updatedData, phradd, 'ABHA');
                    }

                    if (patient?.healthIdNumber) {
                        updatedData = updateIdentifier(updatedData, patient?.healthIdNumber, 'HIN');
                    }
                    const updateRelated = updateRelatedIdentifier(ibm, switchprofiles, phradd, patient?.fullName, 'ABHA')
                    await axios
                        .put(`${URL.cliniapi}Patient/${primaryId}`, updatedData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        .then(async res => {
                            console.log('updateded', res)
                            console.log('updateRelated', updateRelated)
                            // if (!ibm?.identifier || !ibm?.identifier[0]?.system || ibm?.identifier[0]?.system !== 'https://patientMedxperts') {
                            // If the identifier is missing or invalid, proceed with updating the related person
                            await updateRelatedPersonData(updateRelated, updateRelated?.id);
                            // }

                            showAlert('Successfully Updated data', 'success');
                            dispatch(getPatientdatafromibm('Patient', primaryId, token));
                            dispatch(getAbhaProfile(primaryId, token,));
                            dispatch(getAbhaCard(token, primaryId))
                            dispatch(getAbhaQRCode(token, pid, phradd, primaryId))
                            navigate('/');
                            setLoading(false);
                        })
                        .catch(err => {
                            // console.log(err.message, 'error in update');
                            showAlert('Something Went Wrong in update', 'error');
                            setLoading(false);
                        });

                })
                .catch(err => {
                    // console.log('errorloginselctphrs', err)
                    let errorMessage = handleRequestError(err);
                    showAlert(errorMessage, 'error');
                    setLoading(false)
                });

        }
    }

    const handlelinked = () => {
        const isAlreadyLinked = phrs.some((e) => Object.keys(e)[0] === phradd && e[Object.keys(e)[0]] === 'available');
        if (isAlreadyLinked) {
            setErrorMessage('This PHR address is already linked choose another one');
        } else {
            setErrorMessage(''); // Clear the error message
        }
    }
    const handleClickOnMobi2 = () => {
        props.setShow(4)
    }

    // console.log(phradd, "phradd")
    return (
        <Box sx={{ mt: { xs: 4, sm: 2, md: 0 } }}>
            <Typography
                sx={{
                    fontStyle: "inherit",
                    fontSize: { xs: 16, sm: 16, md: 17 },
                    fontWeight: 600,
                    color: '#007DCD',
                    mt: 2
                }}
            >
                {t("existphr")}
            </Typography>
            <Card sx={{ p: 2, mt: { xs: 2, sm: 2, md: 2 } }}>


                <Box mt={2}>
                    {phrs.length > 0 ? (
                        phrs.map((e, i) => (
                            <Card
                                key={i}
                                sx={{
                                    borderRadius: 2,
                                    p: "5px 15px",
                                    mt: 1,
                                    bgcolor:
                                        e[Object.keys(e)[0]] === "available" ? "" : "#F5EDED",
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <Grid item xs={10.5} sm={11} md={11}>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "17px", sm: "18px", md: "19px" },
                                                color: "black",
                                            }}
                                        >
                                            {Object.keys(e)[0]}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1.5}
                                        sm={1}
                                        md={1}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        {e[Object.keys(e)[0]] === "available" ? (
                                            <Box
                                                sx={{
                                                    border: "1px solid #0118B4",
                                                    width: "25px",
                                                    height: "25px",
                                                    borderRadius: 5,
                                                }}
                                                onClick={() => handleSelect(e)}
                                            >
                                                {phradd === Object.keys(e)[0] && (
                                                    <Check
                                                        sx={{ color: "#0118B4" }}
                                                        name={t("credentialsData.createaccount.donebtn")}
                                                    />
                                                )}
                                            </Box>
                                        ) : (
                                            <Typography
                                                onClick={handlelinked}
                                                sx={{ fontSize: { sm: 12, md: 12 } }}
                                            >
                                                {t("linked")}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Card>
                        ))
                    ) : (
                        <Typography
                            sx={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "black",
                                textAlign: "center",
                                mt: 3,
                            }}
                        >
                            {t("nophraddavailable")}
                        </Typography>
                    )}
                </Box>
                {alertmessage && (
                    <Typography
                        sx={{ color: alertstatus === "success" ? "green" : "red", mt: 1 }}
                    >
                        {alertmessage}
                    </Typography>
                )}
                {errorMessage && (
                    <Typography color="red" sx={{ mt: 1, ml: 1 }}>
                        {errorMessage}
                    </Typography>
                )}


                <Box sx={{ textAlign: "center", mt: 4 }}>
                    {phrs?.length > 0 ? (

                        // <Button
                        //     sx={{ width: "120px" }}
                        //     onClick={handleOnLink1Next}
                        //     variant="contained"
                        //     disabled={!phradd}
                        //     endIcon={
                        //         loading ? (
                        //             <CircularProgress size={20} color="inherit" />
                        //         ) : (
                        //             <ArrowForward sx={{ fontWeight: "bold" }} />
                        //         )
                        //     }
                        // >
                        //     {/* {t("credentialsData.commondata.login")} */}
                        //     Link
                        // </Button>
                        <Button
                            sx={{ width: '120px', gap: 1 }}
                            onClick={handleOnLink1Next}
                            disabled={loading}
                            variant="contained"
                            startIcon={
                                loading ? <CircularProgress size={20} color="inherit" /> : <img src={linkIcon} alt="link" width={15} height={15} />
                            }
                        >
                            Link
                        </Button>
                    ) : <div> No Abha Address</div>}
                </Box>

                {/* <Box sx={{ mb: { xs: 10, sm: 10, md: 3 } }}>
                    
                    <Box sx={{ textAlign: "center", mt: 2 }}>
                        <Button
                            variant="outlined"
                            sx={{ borderRadius: 2 }}
                            onClick={() => navigate("/connecthealthid")}
                        >
                            {t("createnewabha")}
                        </Button>
                    </Box>
                </Box> */}
            </Card>
        </Box>
    );
}

export default LoginSelectPhr