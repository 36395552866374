import React from 'react'
import { getAbhaProfile, getPatientdatafromibm } from '../../redux/Actions/Actions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { localstore } from '../../localStore/localStore';
import { KeyboardArrowDown, KeyboardArrowUp, VerifiedUser, WarningAmber } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Paper, Popover, Typography, styled } from '@mui/material';
import AbhaCard from './AbhaCard';
import './Navbar.css'
import { getHealthStatus } from '../../redux/Actions/consentActions';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';


const Overlay = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adjust the color and opacity as needed
    zIndex: 1000, // Ensure it's above other elements
});
function Profile() {

    const ibm = useSelector((state) => state.medx.ibmpdata)
    // console.log(ibm, "ibmmmmmmm")
    const dispatch = useDispatch()
    const token = localstore.getToken()
    const fixedid = localstore.getPrimaryFixedId()
    const primaryId = localstore.getPrimaryId()
    const [phraddress, setPhraddress] = useState('');
    const [ibmpdata, setIbmpdata] = useState()
    const [loader, setLoader] = useState(false);
    const [phdState, setPhdState] = useState(null);
    const [abhaid, setAbhaId] = useState()
    const [age, setAge] = useState('')
    const [abhatoken, setAbhatoken] = useState('')
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    const [selectedLanguage, setSelectedLanguage] = useState("");

    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const healthstatus = useSelector((state) => state.consent.healthstatus)
    const navigate = useNavigate()
    const currentYear = new Date().getFullYear();
    const [gender, setGender] = useState('');


    const [abhaInfoTab, setAbhaInfoTab] = useState({
        isAbhaInfoOpen: false,
        createHidBannerOpen: false,
        firstName: '',
        lastName: '',
        age: null,
    });

    useEffect(() => {
        if (abhadata?.AbhaAddress && primaryId && token)
            dispatch(getHealthStatus(abhadata?.AbhaAddress, primaryId, token));
    }, [primaryId, token, abhadata?.AbhaAddress])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // setPopoverOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        // setPopoverOpen(false);
    };

    const open = Boolean(anchorEl);
    // console.log(token, "abhaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    // const [open, setOpen] = React.useState(false);

    const selectedLang = useSelector(
        (state) => state.language.selectedLanguage
    );
    const [primaryid, setPrimaryid] = useState()
    const [selectedid, setSelectedid] = useState()
    const [abhaage, setAbhaAge] = useState('');

    useEffect(() => {
        const storedPrimaryId = localStorage.getItem('primaryId');
        if (storedPrimaryId) {
            setPrimaryid(storedPrimaryId);
        }
    }, []);

    useEffect(() => {
        if (primaryid && token)
            dispatch(getAbhaProfile(primaryid, token))
    }, [primaryid, token])
    useEffect(() => {
        // console.log(fixedid, primaryid, token, '+++++++++++++++++++<<<<<<<<<<<<<<<<<<<');

        if (token && primaryid && fixedid) {
            dispatch(
                getPatientdatafromibm(
                    'Patient',
                    primaryid,
                    token,
                ),
            );
        }
        if (selectedid === fixedid) {
            setSelectedid(fixedid);
        }
    }, [token, primaryid, fixedid]);


    useEffect(() => {
        if (ibm && ibm.name?.[0]?.given && ibm.name?.[0]?.family) {
            setIbmpdata(ibm);
            setAbhaInfoTab((prev) => ({
                ...prev,
                firstName: ibm.name[0].given[0],
                lastName: ibm.name[0].family,
            }));
            const birthYear = ibm.birthDate?.split('-')[0];
            setAge(currentYear - Number(birthYear));
            setGender(ibm.gender);
        }
    }, [ibm, currentYear]);

    useEffect(() => {
        let abhabirthyear = abhadata?.DateofBirth?.split('-')[2];
        let abhabirthmonth = abhadata?.DateofBirth?.split('-')[1];
        let abhabirthdate = abhadata.DateofBirth?.split('-')[0];
        const dob = new Date(abhabirthyear, abhabirthmonth - 1, abhabirthdate);
        const today = new Date();
        let ageDiff = today.getFullYear() - dob.getFullYear();
        const m = today.getMonth() - dob.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
            ageDiff--;
        }

        if (!isNaN(ageDiff)) {
            setAbhaAge(ageDiff);
        } else {
            setAbhaAge(age)
        }
    }, [abhadata]);

    const { firstName, lastName } = abhaInfoTab

    // let d = new Date().getFullYear();
    // useEffect(() => {
    //     setIbmpdata(ibm)
    //     let birthyear = ibm.birthDate?.split('-')[0];
    //     setAge(Number(d) - Number(birthyear));
    // }, [ibm])
    // useEffect(() => {
    //     if (open) {
    //         // Navigate to 'abhaprofile' route when popover is opened in mobile view
    //         navigate('/abhaprofile');
    //     }
    // }, [open]);
    // useEffect(() => {
    //     // if (ibm) {
    //     // console.log("-------->>>>> effect ibm", ibm)
    //     setIbmpdata(ibm);
    //     let birthyear = ibm.birthDate?.split('-')[0];
    //     setAge(Number(d) - Number(birthyear));
    //     setSelectedid(ibm.id);
    //     if (ibm?.identifier) {
    //         let phd = ibm.identifier?.filter(e => {
    //             // if (e?.type?.coding[0]?.code === 'ACSN') {
    //             if (e?.type?.coding[0]?.code === 'ABHA') {
    //                 return e.value;
    //             }
    //         });
    //         setPhdState(phd);
    //         setPhraddress(phd?.length > 0 ? phd[0].value : '');
    //         let phid = ibm?.identifier?.filter(e => {
    //             if (e?.type?.coding[0]?.code === 'HIN') {
    //                 return e.value;
    //             }
    //         });
    //         setAbhaId(phid?.length > 0 ? phid[0].value : '');
    //     } else {
    //         setPhraddress('');
    //     }
    //     // }


    // }, [ibm]);
    return (

        <div style={{ position: "relative" }} >
            <div className="subtext">
            </div>
            <Button variant="outlined" sx={{
                border: '3px solid #59D8FA', // Add a border to create an outlined style
                "&:hover": { border: '3px solid #59D8FA' },
                borderRadius: "10px", // Adjust the border radius
                display: 'flex',
                gap: 3,
                marginTop: '5px',
                padding: '9px 16px',
                width: { md: "310px", sm: '280px', xs: '280px' },
                height: '55px',
            }}
                onClick={handleClick}
            >
                {
                    ibmpdata?.name?.length > 0 ?
                        (
                            <>
                                <Grid container>
                                    <Grid item xs={1.8} sm={1.8} md={1.8} mt={'2px'}>
                                        {ibmpdata?.photo && ibmpdata?.photo[0]?.url?.length > 0 ? (
                                            <Avatar
                                                sx={{ bgcolor: "gray", width: 38, height: 38 }}
                                            >
                                                <img
                                                    src={ibmpdata?.photo[0].url}
                                                    alt="Profile Picture"
                                                    style={{
                                                        objectFit: "cover",
                                                        width: "100%",
                                                        height: "100%",
                                                    }}
                                                />
                                            </Avatar>
                                        ) : (
                                            <Avatar
                                                sx={{ bgcolor: "gray", width: 38, height: 38 }}
                                            />
                                        )

                                        }
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9}>
                                        <Typography sx={{ fontSize: { xs: 12, md: '14px' }, fontWeight: 600, textAlign: 'left', ml: "10px", color: '#3D6596 ' }}>
                                            {ibmpdata?.gender ? (
                                                ibmpdata.gender.toLowerCase() === 'male'
                                                    ? 'Mr'
                                                    : ibmpdata.gender.toLowerCase() === 'female'
                                                        ? 'Ms'
                                                        : 'Mx' // You can replace 'Mx' with the appropriate title for transgender or other genders
                                            ) : (
                                                'Mr/Ms'
                                            )}{' '}
                                            <span style={{ textTransform: 'capitalize' }}>
                                                {abhadata?.Name ? abhadata?.Name.length > 12 ? abhadata.Name?.slice(0, 12) + '...' : abhadata.Name : (firstName ? firstName : '') + (lastName ? ' ' + lastName : '')}

                                                {/* {`${ibmpdata?.name?.[0]?.given?.[0]} ${ibmpdata?.name?.[0]?.family}`.slice(0, 12)}
                                                {`${ibmpdata?.name?.[0]?.given?.[0]} ${ibmpdata?.name?.[0]?.family}`.length > 12 ? '...' : ''} */}
                                            </span>   ({abhaage || age}Y/)
                                            {/* {ibmpdata?.gender && ibmpdata.gender.charAt(0).toUpperCase()}) */}
                                            {_.capitalize(gender.charAt(0))}
                                        </Typography>
                                        {abhadata?.AbhaAddress?.length > 0 ? (
                                            <Box sx={{
                                                display: 'flex', ml: "11px", gap: { xs: "6px", sm: "5px", md: "8px" }, alignItems: 'center', textAlign: 'center'
                                            }}>
                                                < VerifiedUser sx={{ fontSize: 13, color: "green" }} />
                                                <Typography sx={{
                                                    fontSize: { xs: 13, md: '14px' }, fontWeight: 500, color: '#5B7DA6', // You can adjust this width as per your design
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }} >
                                                    {abhadata?.AbhaAddress?.length > 20 ? `${abhadata?.AbhaAddress?.substring(0, 20)}...` : abhadata?.AbhaAddress}

                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Typography sx={{
                                                fontSize: "13px", fontWeight: 400,
                                                textAlign: 'left', color: '#5B7DA6', ml: "10px", display: 'flex', alignItems: 'center', gap: "5px", color: 'red'
                                            }} >
                                                <WarningAmber sx={{ fontSize: '15px' }} />  Connect ABHA Address
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={0.2} sm={0.2} md={0.2}></Grid>
                                    <Grid item xs={1} sm={1} md={1} mt={1} >
                                        <KeyboardArrowDown sx={{ bgcolor: '#9560D9', borderRadius: 5, color: 'white', width: 20, height: 20 }} />
                                    </Grid>
                                </Grid>
                            </>
                        ) : null
                    // <Box>
                    //     <Typography sx={{ color: '#205072', fontWeight: 600, fontSize: '14px' }}>Please Try Again Later</Typography>
                    //     {/* <Typography sx={{ color: '#205072', fontWeight: 400, fontSize: '12px' }}>Network Issue</Typography> */}
                    // </Box>
                }
            </Button>
            {open && <Overlay onClick={handleClose} />}

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorEl"
                anchorPosition={{ top: 10, left: 10 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '.MuiPopover-paper': {
                        borderRadius: "10px 10px 0px 0px"
                    },
                    display: { xs: 'none', md: 'block' }, // Show only in mobile view

                }}
            >
                <Box sx={{
                    width: '360px',
                    height: '100vh',
                    mb: 2,
                }}>
                    <Button variant="outlined" sx={{
                        border: '3px solid #59D8FA', // Add a border to create an outlined style
                        "&:hover": { border: '3px solid #59D8FA' },
                        borderRadius: "10px", // Adjust the border radius
                        display: { md: 'flex', xs: 'none', sm: 'none' },
                        gap: 3,

                        padding: '9px 16px',
                        width: "350px",
                        height: '55px',

                    }}
                        onClick={handleClose}
                    >
                        {
                            ibmpdata?.name?.length > 0 ?
                                (
                                    <>
                                        <Grid container>
                                            <Grid item xs={1.5} sm={1.5} md={1.5} mt={'2px'}>
                                                {ibmpdata?.photo && ibmpdata?.photo[0]?.url?.length > 0 ? (
                                                    <Avatar
                                                        sx={{ bgcolor: "gray", width: 38, height: 38 }}
                                                    >
                                                        <img
                                                            src={ibmpdata?.photo[0].url}
                                                            alt="Profile Picture"
                                                            style={{
                                                                objectFit: "cover",
                                                                width: "100%",
                                                                height: "100%",
                                                            }}
                                                        />
                                                    </Avatar>
                                                ) : (
                                                    <Avatar
                                                        sx={{ bgcolor: "gray", width: 38, height: 38 }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 600, textAlign: 'left', ml: "17px", color: '#3D6596' }}>
                                                    {ibmpdata?.gender ? (
                                                        ibmpdata.gender.toLowerCase() === 'male'
                                                            ? 'Mr'
                                                            : ibmpdata.gender.toLowerCase() === 'female'
                                                                ? 'Ms'
                                                                : 'Mx' // You can replace 'Mx' with the appropriate title for transgender or other genders
                                                    ) : (
                                                        'Mr/Ms'
                                                    )}{' '}
                                                    <span style={{ textTransform: 'capitalize' }}>
                                                        {/* {`${ibmpdata?.name?.[0]?.given?.[0]} ${ibmpdata?.name?.[0]?.family}`.slice(0, 15)}
                                                        {`${ibmpdata?.name?.[0]?.given?.[0]} ${ibmpdata?.name?.[0]?.family}`.length > 15 ? '...' : ''} */}
                                                        {abhadata?.Name ? abhadata?.Name.length > 15 ? abhadata.Name?.slice(0, 15) + '...' : abhadata.Name : (firstName ? firstName : '') + (lastName ? ' ' + lastName : '')}

                                                    </span> ({abhaage || age}Y/
                                                    {_.capitalize(gender.charAt(0))})

                                                    {/* {ibmpdata?.gender && ibmpdata.gender.charAt(0).toUpperCase()}) */}
                                                </Typography>



                                                {abhadata?.AbhaAddress?.length > 0 ? (
                                                    <Box sx={{
                                                        display: 'flex', ml: "17px", gap: "11px ", alignItems: 'center', textAlign: 'left' // Align the content to the left within the Box

                                                    }}>
                                                        < VerifiedUser sx={{ fontSize: 13, color: "green" }} />
                                                        <Typography sx={{
                                                            fontSize: '14px',
                                                            fontWeight: 500,
                                                            color: '#5B7DA6',
                                                            width: '190px', // You can adjust this width as per your design
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {abhadata?.AbhaAddress?.length > 25 ? `${abhadata?.AbhaAddress?.substring(0, 25)}...` : abhadata?.AbhaAddress}

                                                        </Typography>
                                                    </Box>

                                                ) : (
                                                    <Typography sx={{
                                                        fontSize: "13px", fontWeight: 400, textAlign: 'left', ml: "17px", display: 'flex', alignItems: 'center', gap: "5px", color: 'red'
                                                    }} >
                                                        <WarningAmber sx={{ fontSize: '15px' }} />  Connect ABHA Address
                                                    </Typography>

                                                )}
                                            </Grid>

                                            <Grid item xs={1.5} sm={1.5} md={1.5} mt={1} >
                                                <KeyboardArrowUp sx={{ bgcolor: '#9560D9', borderRadius: 5, color: 'white', width: 20, height: 20 }} />
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : null
                        }
                    </Button>
                    <AbhaCard
                        ibmpdata={ibmpdata}
                        fixedid={fixedid}
                        selectedid={selectedid}
                        phdState={phdState}
                        healthstatus={healthstatus}
                        handleClose={handleClose}
                        open={open} />
                </Box>
            </Popover>
        </div >

    )
}
export default Profile
