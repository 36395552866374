import { ArrowForward, CelebrationOutlined } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import URL from '../../../../utility/apiurl'
import { useState } from 'react'
import { localstore } from '../../../../localStore/localStore'
import axios from 'axios'

function PhrAddressScreen(props) {
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    // console.log(result, "hello")
    // const AbhaNumber = result.AbhaNumber
    const phrAddress = useSelector((state) => state.abha.phrAddress)
    const token = localstore.getToken()
    console.log(phrAddress, "phrrrrrrr")
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const primaryId = localstore.getPrimaryId()
    const handleloginPhr = () => {

        setLoading(true)
        if (phrAddress.length > 0) {
            setLoading(true)
            axios.get(`${URL.abha}login/search/authmodes/${primaryId}/${phrAddress}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'gatewayToken': 'medxperts',
                }
            }).then(res => {
                console.log(res, "authmethods")
                // setAlertMessage("")
                const updatedAuthMethods = res.data.authMethods.filter(method => method !== "AADHAAR_BIO" && method !== "DEMOGRAPHICS" && method !== "AADHAAR_OTP");

                dispatch({
                    type: "CREATE_AUTH_METHODS",
                    payload: updatedAuthMethods
                })
                dispatch({
                    type: "CREATE_PHRADDRESS",
                    payload: res.data.phrAddress
                })

                props.setShow(8)
            }).catch(err => {
                setAlertMessage(err.message)
                setAlertstatus("error")
            }).finally(() => {
                setLoading(false)
            })
        }

    };

    return (
        <Box>
            <Typography sx={{ fontSize: '17px', mt: { xs: 4, sm: 3, md: 2 }, fontWeight: 600 }}>PHR Address</Typography>

            <Card sx={{ p: 3, mt: 1, textAlign: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
                    <CelebrationOutlined sx={{ color: '#9560D9' }} />
                    <Typography sx={{ fontWeight: 500, fontSize: '17px' }}>Congratulations</Typography>
                </Box>
                <Typography sx={{ color: 'black', mt: 2, fontSize: 14 }}>
                    Your Ayshman Bharat Health Address (ABHA) is  created Successfully
                </Typography>

                <Box mt={5}>
                    <Typography sx={{ fontSize: 14 }}>
                        Your ABHA / PHR address is
                    </Typography>
                    <Typography sx={{ mt: 1, fontSize: { sm: 18, md: 17 }, fontWeight: 500 }}>
                        {phrAddress}
                    </Typography>

                    {alertmessage && (
                        <Typography sx={{ color: alertstatus === 'success' ? 'green' : 'red', mt: 1 }}>{alertmessage}</Typography>
                    )}
                    <Button sx={{ mt: 3, fontWeight: 'bold', width: '120px', borderRadius: 2 }} variant='contained'
                        onClick={handleloginPhr}
                        endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <ArrowForward sx={{ fontWeight: 'bold' }} />}
                    >Login</Button>
                </Box>
            </Card>
        </Box>
    )
}

export default PhrAddressScreen