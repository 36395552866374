import React, { useEffect } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import axios from 'axios';
import { useState } from 'react'
import { Box, Button, Card, CircularProgress, IconButton, Paper, Typography } from '@mui/material'
import URL, { MobileOrEmailpublicKey, OtpPublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { useTranslation } from "react-i18next";
import ResendOtp, { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';
import { encryptData } from '../../../../utility/encryption';
import { handleRequestError } from '../../../atoms/helperFunctions';

function EmailStep2(props) {
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    const [otp, setOtp] = useState('')
    const [time, setTime] = useState(59);
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const emaildetails = useSelector((state) => state.abha.sessionIdandMobilenumber)
    const emailDetails = useSelector((state) => state.phraddress.emailtransactionId)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [transactionid, settransactionid] = useState('')

    const publicKey = OtpPublicKey
    console.log(emailDetails, "emailllllllllllllll")
    const transactionId = emailDetails.transactionId
    // console.log(sessionId, "emailsessionId")
    const email = emailDetails.EmailAddress

    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }

    // Helper function to display alerts
    const showAlert = (message, status) => {
        setAlertmessage(message);
        setAlertstatus(status);

    };
    useEffect(() => {
        if (transactionid) {
            settransactionid(transactionid);
        } else {
            settransactionid(transactionId);
        }
    }, [])
    useEffect(() => {
        console.log(time, "<<<<<<<<<<<")
        if (time > 0) {
            let myInterval = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);

            return () => {
                clearInterval(myInterval);
            };
        }
        return () => { };

    }, [time]);

    const handleVerify = async () => {
        // props.setShow(4)
        // const encrypt = new JSEncrypt()
        // encrypt.setPublicKey(publicKey);

        // const encryptOtp = encrypt.encrypt(otp);
        const encodedMessage = encryptData(OtpPublicKey, otp);

        setLoading(true)
        let data = {
            // otp: encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
            otpValue: encodedMessage.replace(/\n/g, '').replace(/\r/g, ''),
            // mobileOrEmail: 'otp',
            txnId: transactionid,
        }
        await axios.post(`${URL.abha}email/validate/otp/${primaryId}`, data, {
            headers: {
                'gatewayToken': 'medxperts',
                'Authorization': `Bearer ${token}`,
            },

        }).then(res => {
            // console.log(res, "------------>verifyemailotp")
            // if (res.status === 200 || res.status === 201) {
            setAlert(true);
            setAlertmessage('OTP Verified Successfully!');
            setAlertstatus('success');
            if (res?.data?.length > 0) {
                props.setShow(3)
            } else {
                props.setShow(4)
            }
            // dispatch({
            //     type: "EMAILMAPPEDPHRADDRESS",
            //     payload: res.data
            // })
            dispatch({
                type: "EMAIL_REGISTRATION_PAGE",
                payload: {
                    mobile: undefined,
                    transactionId: transactionid,
                    authmethod: 'email',
                    EmailAddress1: email,
                }
            })
            dispatch({
                type: "EMAIL_SELECTPHR_PAGE",
                payload: {
                    transactionId: transactionid,
                    phrs: res.data,
                    mobile: undefined,
                    EmailAddress1: email,
                    authmethod: 'email',
                }
            })
            // }
        }).catch(err => {
            // console.log(err.message);
            if (err.response && err.response.data && err.response.status === 400) {
                const responseText = err.response.data.message;
                const responseArray = JSON.parse(responseText.split(': ')[1]);
                const errorMessage = responseArray[0].error.message;
                setAlertmessage(errorMessage);
                setAlertstatus('error');
            } else {
                setAlertmessage(err.message);
                setAlertstatus('error');
            }
        }).finally(() => {
            setLoading(false);
        });
        // setOtp('')

    }
    const handleResendOtp = async () => {
        setOtp('')
        settransactionid('')
        if (!(MobileOrEmailpublicKey && primaryId && token)) {
            showAlert('Something Went Wrong', 'error');
            setLoading(false);
            return;
        }
        const encodedMessage = encryptData(MobileOrEmailpublicKey, email);

        const payload = {
            email: encodedMessage,
        };
        try {
            const response = await axios.post(`${URL.abha}email/generate/otp/${primaryId}`, payload, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    // 'X-CSRF-TOKEN': `${csrf}`,
                },
            });

            // console.log('Emailstep1', response.data);
            if (response.status === 200 || response.status === 201) {
                settransactionid(response?.data?.txnId);
                showAlert(response.data.message || 'OTP sent to Email Address!', 'success');
                setTime(59);
            }
        } catch (error) {
            // console.log('emailstep1error', error)
            let errorMessage = handleRequestError(error);
            showAlert(errorMessage, 'error');
        } finally {
            setLoading(false);
        }
    }

    let formattedEmail = '';
    if (typeof email === 'string') {
        formattedEmail = email.slice(0);
    }
    return (
        <div>
            <Box sx={{ mt: { xs: "5px", sm: 1, md: 2 } }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: 1, sm: 2, md: 2 },
                    }}
                >
                    <IconButton onClick={() => {
                        props.setShow(1)
                        props.setAlertMessage('')
                    }}>
                        <ArrowBackIos sx={{ color: "#007DCD", cursor: "pointer" }} />
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: { xs: 15, sm: "16px", md: "18px" },
                            fontWeight: "500",
                            color: "#007DCD",
                        }}
                    >
                        {t("changeregisterdetails")}{" "}
                    </Typography>
                </Box>
            </Box>

            <Paper elevation={3} sx={{ p: 3, mt: 1, display: "flex", justifyContent: "center" }}>
                <Box mt={1} sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                }}>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: { xs: 14, md: 16 },
                                fontWeight: 600,
                                color: "black",
                            }}
                        >
                            {t("verifyotp")}
                        </Typography>

                        <Box mt={2}>
                            <TextInputField
                                width="350px"
                                placeholder={t("enterotphere")}
                                border="none"
                                type="text"
                                name="otp"
                                value={otp}
                                onChange={handleOnChangeOtp}
                            />
                        </Box>
                        <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} />
                    </Box>
                    {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )}


                    <Typography
                        sx={{
                            fontStyle: "inherit",
                            fontSize: { xs: 14, md: 14 },
                            color: "black",
                            textAlign: "center",
                            mt: 2,
                        }}
                    >
                        {t("entersendedotp")} {formattedEmail}
                    </Typography>
                    <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                        <Button
                            variant="contained"
                            sx={{ borderRadius: 2, width: "150px", mt: 1 }}
                            onClick={handleVerify}
                            disabled={loading || otp?.length < 6}
                            startIcon={
                                loading ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : null
                            }
                        >
                            {t("credentialsData.signupmobiledata.submitbtn")}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </div >
    );
}

export default EmailStep2