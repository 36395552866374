
import React, { useState } from 'react'
import './Navbar.css'
import Logo from '../../assets/home/medxpertspassion.svg'
import Menu from '../../assets/home/menu.svg'
import Iconsetting from '../../assets-new/Icon core-settings.svg'
import TelemedcineIcon from '../../assets-new/TELEMEDICINE-ICON.svg'
import NavButton from '../atoms/NavButton'
import { useTranslation } from "react-i18next"
import GridNavButton from '../atoms/GridButton'
import { AppBar, Box, Divider, Grid, Toolbar, useMediaQuery } from '@mui/material'
import Profile from './Profile'
import { localstore } from '../../localStore/localStore'
import { useLocation, useNavigate } from 'react-router-dom'
import Menubar from './Menubar'
import MobileAppbar from '../atoms/MobileAppbar'
import LoginHeader from '../atoms/LoginHeader'


const Navbar = () => {
    const [t, i18n] = useTranslation("global");
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryFixedId()
    const navigate = useNavigate()
    const location = useLocation()
    const isMobile = useMediaQuery('(max-width:900px)'); // Adjust the breakpoint as needed
    const [mobileview, setMobileView] = useState(false)


    return (
        <div>
            {/* For Mobile Navbar */}
            {!location?.pathname.includes('abhaprofile') &&

                <>
                    {primaryId ?
                        <AppBar position="fixed" sx={{ bgcolor: 'white', }}>
                            <Toolbar>

                                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                                {/* <Box onClick={() => navigate('/')} component="div">
                            <img src={Logo} alt="logo" width={isMobile ? '120px' : '175px'} height={isMobile ? '30px' : '36px'} />
                        </Box> */}
                                {/* <MobileAppbar /> */}
                                {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}> */}
                                {/* <Box >
                                <img src={TelemedcineIcon} alt="logo" width={isMobile ? '120px' : '175px'} height={isMobile ? '30px' : '36px'} />
                            </Box> */}
                                {/* <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/abhaprofile')}>
                                <img src={Iconsetting} alt="logo" width={isMobile ? '120px' : '175px'} height={isMobile ? '25px' : '30px'} />
                            </Box>
                        </Box> */}
                                {/* </Box> */}
                                <Grid container >
                                    <Grid item xs={9}>
                                        <MobileAppbar />
                                    </Grid>
                                    <Grid item xs={1.8}>
                                        <img src={TelemedcineIcon} alt="logo" width={isMobile ? '50px' : '75px'} height={isMobile ? '30px' : '36px'} />
                                    </Grid>
                                    <Grid item xs={1.2} sx={{ cursor: 'pointer' }} onClick={() => navigate('/abhaprofile')}>
                                        <img src={Iconsetting} alt="logo" width={isMobile ? '50px' : '75px'} height={isMobile ? '25px' : '30px'} />
                                    </Grid>
                                </Grid>
                            </Toolbar>

                        </AppBar> : <LoginHeader />

                    }
                </>
            }



            {/* FOR WEB NAVBAR */}
            <nav className="nav-desk">
                <AppBar sx={{ bgcolor: 'white', padding: '10px 56px 10px', }}>
                    <div className="navbar">

                        <div style={{ position: "relative" }}>
                            <div className="subtext"></div>
                            <GridNavButton variant="outlined"
                                text={t("navbar.talktoexpert")}
                                style={{ width: "200px", marginTop: '10px' }}
                                title='MedXpedia'
                                phoneNumber="Call 9 108 108 4934" />
                        </div>
                        <div className="desk-nav" onClick={() => navigate('/')}>
                            <img src={Logo} alt="logo" height={57} style={{ objectFit: 'fill', cursor: 'pointer' }} />
                        </div>

                        {token && primaryId ? <Profile /> :

                            <div style={{ position: "relative" }}>
                                <div className="subtext">
                                    {t("navbar.login")}
                                </div>
                                <NavButton
                                    variant="outlined"
                                    text={t("navbar.ehealthservices")}
                                    style={{ width: "247px", marginTop: '10px' }}
                                    onClick={() => navigate('/login')}
                                />

                            </div>}
                    </div>
                </AppBar>
            </nav>
            {
                !location?.pathname.includes('login') && !location?.pathname.includes('signup') && !location?.pathname.includes('forgotpassword') && !location?.pathname.includes('forgotusername') && !location?.pathname.includes('completeprofile') && !location?.pathname.includes('privacy') &&
                <Menubar />
            }
        </div >
    );
}

export default Navbar;

