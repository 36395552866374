//public keys for Encryption
export const PasswordpublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzornS8jW8Oyj0JepvFQJcY9HiI9LvWreg7JwVpt126bVykbbmQZQB6HNuEblHn5b29wxOhFiNAv+Jg9QDD8UQw7tpTOMpJHgm8VW6ziplwAEL4EEHSnekhJ3tBt5JmHoaBeM1jmAtKVfgkPl4ezoQdxMwgSj60btelfGgT0N4TcTdX4aULYa2PmMHRQmoU7xxg+VncPMGtes6siQ+yEC27Vop6z0IDP45JspvgM48wbJiIxfjqSgnir1grsKfQzN9QQdReeuBN1KU84KYcwBoMj8kIXdYtemRTZt3b4zzRX5FLLPY9b0Ad5mFauShut+Pez8Qbd04m3yS72mJnepAQIDAQAB
-----END PUBLIC KEY-----`
export const MobileOrEmailpublicKey = `-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqyIOhXijqd9/vQ1lrE/4H0iS2tb3Kgf60a85kUK0UHq4Ac1suf1nj9i0FIEnIbUDs5GqGjdZ7Kfjm+ip9iAuip8h+ZoBpA/N/EAXNZ2v/NVZktBeUA+qJbolJOXxKRsCkeZcgdo4QhPz/YfWOL4XVDrliOU3k8wRlVWvS7LO3TxyOu4rJ+IUrhF7q4Xy2VCcadenAJPe7A2XnHdqNu4sT6O9AyoYlj1XmGHXyJeR/Fv+xsryNJ/dLQBt7TkmPKGgzHhTkstI5q2ztrm4NsOyP3AfLfEvrQmtLZMqOZ65SuHJfKQONPuOfkUJkxQTQQvat+4qgGENYiAfh0PTTSBkwwIDAQAB-----END PUBLIC KEY-----`

export const OtpPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA7Zq7YKcjmccSBnR9CDHd6IX96V7D/a2XSMs+yCgejSe956mqjA/0Q9h+Xnx7ZZdwe2Tf2Jq/mWXa+gYdnta58otreXg/5oGnNV3Edlixz1Oc8tJg5bG4sIUCGZcbEQGSbm1iC+Fp1kS+YLVG4Su8KoRxcCvRJI2QkfqAruX3JoFjggOkv0TgWCo9z6NV6PPmPN3UsXyH3OPDi3Ewnvd64ngCUKPSBiIDwhLj2yYSShcxH8aWbrz00SJodBJzqgjvCfZuljBXXIN4Ngi/nzqEJ7woKQ1kNgWoHFZy7YL74PihW//4OlniSRoITX+7ChILIv2ezSmAdIjpNJ9Dg9XKcQIDAQAB
-----END PUBLIC KEY-----`

export const AadharPublicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO
4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9N
wgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u
68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN
8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1Aayf
Zp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7i
rDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkN
ergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG
0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXu
iwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3
o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAls
IzQpnSVDUVEzv17grVAw078CAwEAAQ==
-----END PUBLIC KEY-----`

const baseApi = `${process.env.REACT_APP_API_BASEURL}`
const api = `${process.env.REACT_APP_API_URL}v1/patient/`
const abha = `${process.env.REACT_APP_API_URL_ABHA}`
const elibapi = `${process.env.REACT_APP_API_ELIB_URL}`
const feedApi = `${process.env.REACT_APP_API_URL_PYI}`
const consentsApi = `${process.env.REACT_APP_API_URL_CONSENT_PIN}`
const otpapi = `${process.env.REACT_APP_API_URL_OTP}`
const cliniapi = `${process.env.REACT_APP_API_URL_CLINI}`
const socketapi = `${process.env.REACT_APP_API_NOTIFICATION}`

const URL = {
    medxpertsBaseApi: `${baseApi}`,
    socketurl: `${socketapi}`,
    checkMobile: `${api}findPatientsByPhoneNumber`,
    findPatients: `${api}findPatientsByUserName`,
    getPatientById: `${api}getPatientById`,
    addPatient: `${api}addPatient`,
    uploadfile: `${api}upload/file`,
    relatedPerson: `${api}`,
    patient: `${api}`,
    login: `${api}login`,

    // Upload Records

    addRecords: `${api}upload/medicalrecords`,
    medicalrecords: `${api}`,


    // Doctors
    suggestedDoctors: `${api}suggest/doctor/`,
    getDoctordata: `${api}list/doc/`,
    followDoctor: `${api}follow/doctor/`,
    followDoctorsCount: `${api}count/DoctorFollowed/`,
    doctorsFollowList: `${api}list/doctors/followed/`,
    unfollowDoctor: `${api}unfollow/doctor/`,

    //  Organisation
    suggestedOrganisations: `${api}suggest/organisation/`,
    getOrgdata: `${api}list/org/`,
    orgFollowList: `${api}list/organisation/followed/`,
    unfollowOrg: `${api}unfollow/organisation/`,
    followOrganisation: `${api}follow/organisation/`,
    followOrgCount: `${api}count/organisationFollowed/`,

    postById: `${feedApi}doctorpost`,
    addpoll: `${feedApi}addpollresponse`,
    deleteresponse: `${feedApi}deletepollresponsebyid`,

    feedApi: `${feedApi}`,
    patientfeed: `${feedApi}patientfeed`,
    feed: `${feedApi}feed`,
    post: `${feedApi}post/`,
    userFeed: `${feedApi}postsbyuserid/`,
    pageFeed: `${feedApi}postbypageid/`,
    // =============LIKES==========//
    addLikes: `${feedApi}addlikes`,
    deleteLikes: `${feedApi}deletelikesbyid/`,
    // =============COMMENT===========//
    comment: `${feedApi}addcomment`,
    cliniapi: `${cliniapi}`,
    // getUserData: `${api}Users`,
    // =======SEARCH COUNTRIES==============//
    // ============ABHA===========// 
    abha: `${abha}`,
    // ============ABHA QR-CODE===========// 
    abhaQRCode: `${abha}mobile/qrcode`,
    cliniapi: `${cliniapi}`,
    sendOtp: `${otpapi}signupotpformedxpertswithcaptcha`,
    verifyotp: `${otpapi}verifyOtp`,
    forgotuserOtp: `${otpapi}forgotusernameapiforneonataiandmedxperts`,
    sendUsername: `${otpapi}send_user_name_to_user`,
    resetOtp: `${otpapi}forgotpassword`,
    forgotUsername: `${api}forgotUsername`,
    resetPassword: `${api}resetPassword`,
    getCountries: `${elibapi}all_countries`,
    getCountrycode: `${elibapi}country_wise_details/`,

    // consent pin API's
    consents: `${consentsApi}`

}

export default URL;