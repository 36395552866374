import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Grid, Paper, Typography } from '@mui/material';
import { getAbhaMobile, getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import ConsentSecurity from '../ConsentSecurity/ConsentSecurity';
import EmailUpdate from './EmailUpdate';
import PhonenumUpdate from './PhonenumUpdate';
import AddressUpdate from './AddressUpdate';
import EditProfileCard from '../../../atoms/EditProfileCard';
import deleteIcon from "../../../../assets/home/healthprofile_assets/trash.svg";
import delinkIcon from "../../../../assets/home/healthprofile_assets/deletehealthid.svg";
import resetIcon from "../../../../assets/home/healthprofile_assets/reset.svg";
import venkatIcon from "../../../../assets-new/venkatreddy.jpg";
import DelinkHealthId from './DelinkHealthId';
import DeleteHealthId from './DeleteHealthId';
import UpdatePassword from './UpdatePassword';
import abhalogo from '../../../../assets-new/abha-logo.svg'
import imagelogo from '../../../../assets-new/venkatreddy.jpg'
import { AppSettingsAlt, ArrowBackIos, ArrowForwardIos, Delete, EditNotifications, Password, Translate } from '@mui/icons-material';
import { OutlinedButton } from '../../../atoms/CommonButton';
import NameUpdate from './NameUpdate';
import CreateConsentPin from '../ConsentSecurity/CreateConsentPin';
import ResetConsentPage from '../ConsentSecurity/ResetConsentPin';
import ProfileUpdate from './ProfileUpdate';
import { useNavigate } from 'react-router-dom';
import RequestDeleteProfile from './RequestDeleteProfile';


const PrefenceDetails = (props) => {
    return (

        <Paper elevation={3} sx={{ p: '6px 20px', mt: "15px", borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                {props.icon}
                <Box>
                    <Typography fontSize={14}>
                        {props.display}
                    </Typography>
                    <Typography fontSize={15} mt={'5px'}>
                        {props.title}
                    </Typography>
                </Box>
            </Box>
            <OutlinedButton text={props.text} borderColor="#785D9B" width="100px" height="32px" color="#000000" onClick={props.onClick} />
        </Paper>
    )
}
const ManageAccount = (props) => {
    return (
        <Grid container>
            <Grid item xs={3} sm={3} md={3} textAlign={'center'} sx={{ p: '0px 15px', mt: 2 }}>
                <img src={props.icon} alt="reset" />
                <Typography sx={{ color: props.color, fontSize: { xs: '12px', sm: '14px', sm: 16 } }}>{props.name}</Typography>
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // gap: 2,
                        '& > div': {
                            p: '10px 15px', // Equal padding for each child div
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            '&:hover': {
                                backgroundColor: '#f0f0f0',
                                cursor: 'pointer',
                            },
                        },
                    }}
                >
                    <div onClick={props.onClickButton1}>
                        <Typography fontSize={14}>{props.buttontype1}</Typography>
                        <ArrowForwardIos />
                    </div>
                    <Divider />
                    <div onClick={props.onClickButton2}>
                        <Typography fontSize={14}>{props.buttontype2}</Typography>
                        <ArrowForwardIos />
                    </div>
                </Box>
            </Grid>
        </Grid>
    );
};

function ProfileSettings() {

    const mobiledata = useSelector((state) => state.abha.mobileprofile)
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const primaryFixedid = localstore.getPrimaryFixedId()
    const navigate = useNavigate()
    // console.log(mobiledata, "mobiledata")
    // console.log(abhadata, "abhadata")
    const primaryId = localstore.getPrimaryId()
    const token = localstore.getToken()
    const dispatch = useDispatch()
    const [delinkopen, setDelinkopen] = useState(false)
    const [deleteopen, setDeleteopen] = useState(false)
    const [deleteprofile, setDeleteprofile] = useState(false)
    const [resetopen, setResetopen] = useState(false)
    const [createPin, setCreatePin] = useState(false)
    const [resetPin, setResetPin] = useState(false)
    const handleCloseConsentPin = () => {
        setCreatePin(false)
    }
    const handleCloseResetPin = () => {
        setResetPin(false)
    }
    const handleDelink = () => {
        if (abhadata?.AbhaNumber) {
            setDelinkopen(true)
        } else {
            alert('This user does not have a Health ID number.')
        }
    }
    useEffect(() => {
        if (token && primaryId) {
            dispatch(getAbhaMobile(primaryId, token));
            dispatch(getPatientdatafromibm('Patient', primaryId, token));
        } else {
            if (!ibm?.identifier || ibm?.identifier?.length === 0) {
                dispatch(getAbhaMobile(''));
            }
        }
    }, [token, primaryId]);

    const handleDelete = () => {
        if (abhadata?.AbhaNumber) {
            setDeleteopen(true)
        } else {
            alert('This user does not have a Health ID number.')
        }
    }
    const handleDeleteProfile = () => {
        setDeleteprofile(true)
    }
    // const nameParts = mobiledata?.fullName;

    // Extract first name and last name
    // const firstName = nameParts?.[0];
    // const lastName = nameParts?.slice(1).join(' ');

    const handleResetPin = () => {
        setResetPin(true)
    }
    const handleCreatePin = () => {
        if (mobiledata?.id) {
            setCreatePin(true)
        } else {
            alert('Please Register or login ABHA Address and later Set Pin');
        }
    }
    return (
        <Box sx={{ mt: { md: 8, xs: 9, sm: 9, lg: 8 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 100px', lg: '0px 250px' }, pt: { xs: '0px', sm: "0px", md: '0px', lg: '0px' }, mb: { xs: 10, sm: 10, md: 2, lg: 2 } }}>
            {/* <Typography sx={{ fontWeight: 600, ml: "2px" }}> Edit Abha Profile </Typography> */}
            <Box m={1}>
                <Typography sx={{ fontWeight: 600, ml: "2px", fontSize: '17px', display: 'flex', alignItems: 'center', gap: 1 }}><img src={abhalogo} alt="abhalogo" width={45} height={45} /> Profile Settings </Typography>
                <Grid container columnSpacing={3} mt={1}>
                    {/* <Grid item md={6.5} sm={6.5} xs={6.5}>
                        <ProfileUpdate />
                    </Grid>

                    <Grid item xs={5.5} sm={5.5} md={5.5} >
                        <Paper elevation={3} sx={{ p: { xs: '10px 10px', md: "15px 20px" }, borderRadius: '15px', }}>
                            <Box sx={{ textAlign: 'center', height: { xs: '190px', sm: '180px', md: '170px' } }}>
                                <Typography sx={{ fontWeight: 600, fontSize: { xs: '15px', sm: '16px', md: '16px' } }}>CONSENT PIN</Typography>
                                <Password sx={{ fontSize: '50px' }} />
                                <Typography sx={{ mt: { xs: 1, sm: 0, md: 1 }, fontSize: { xs: '12px', sm: '15px', md: 16 } }}>A Transaction PIN to verify its you doing it</Typography>
                                <Box sx={{ mt: { xs: 2, sm: "17px", md: "20px" } }}>
                                    {mobiledata.hasTransactionPin ?
                                        < OutlinedButton text="RESET" fontSize={{ xs: '12px', sm: '14px', md: '14px' }} borderColor="#ED0000" color="#FF0000" width="100px" height="30px" onClick={handleResetPin} /> :
                                        <OutlinedButton text="Set PIN" fontSize={{ xs: '12px', sm: '14px', md: '14px' }} borderColor="#785D9B" color="black" width="100px" height="30px" onClick={handleCreatePin} />}
                                </Box>
                            </Box>
                        </Paper>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} >
                        <ProfileUpdate mobiledata={mobiledata} />
                    </Grid>
                </Grid>


                <Box mt={2}>
                    <Typography fontWeight={500}>Manage Personal Details</Typography>
                    {/* <NameUpdate mobiledata={mobiledata} /> */}
                    <PhonenumUpdate mobiledata={mobiledata} />
                    <EmailUpdate mobiledata={mobiledata} />
                    <AddressUpdate mobiledata={mobiledata} />

                    <Grid container columnSpacing={3} mt={2}>
                        <Grid item xs={6.5} sm={6.5} md={6.5} lg={6.5}>
                            <Paper elevation={3} sx={{ p: '10px 20px', display: 'flex', alignItems: 'center', gap: 2, borderRadius: '10px' }}>
                                <Translate sx={{ fontSize: '40px', color: '#425F00' }} />
                                <Box sx={{ height: { xs: 90, sm: 50, md: 50 } }}>
                                    <Typography sx={{ fontSize: '14px', }}>Communication Language</Typography>
                                    <Typography sx={{ fontSize: '15px', }}>English, Hindi, Telugu</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5}>
                            <Paper elevation={3} sx={{ p: '10px 20px', display: 'flex', alignItems: 'center', gap: 2, borderRadius: '10px' }}>
                                <Translate sx={{ fontSize: '40px', color: 'blue' }} />
                                <Box sx={{ height: { xs: 90, sm: 50, md: 50 } }}>
                                    <Typography sx={{ fontSize: '14px' }}>App Language</Typography>
                                    <Typography sx={{ fontSize: '15px' }}>English</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={2}>
                    <Typography fontWeight={500}>Preferences</Typography>
                    <PrefenceDetails display="Notifications and Alerts" title="Default" text="Customise" icon={<EditNotifications sx={{ color: '#9560D9', fontSize: '40px' }} />} />
                    <PrefenceDetails display="Permission on this Device" title="Dark Mode,Location,Camera,..." text="Manage" icon={<AppSettingsAlt sx={{ color: '#001C67', fontSize: '40px' }} />} />
                </Box>

                <Box mt={2}>
                    <Typography fontWeight={500}>Manage Account</Typography>
                    <Paper elevation={3} sx={{ borderRadius: '10px', mt: 2 }}>
                        <ManageAccount icon={resetIcon} name="Reset Password" color="#1500B5" buttontype1={'Reset MedXperts App Password'} buttontype2={'Reset ABHA Address'} onClickButton1={() => setResetopen(!resetopen)} />
                        <Divider />
                        <ManageAccount icon={delinkIcon} name="Delink Health ID" color="#005F0F" buttontype1={'Delink ABHA Number and Address'} buttontype2={'Delink ABHA from MedXperts'} onClickButton1={handleDelink} />
                        <Divider />
                        <ManageAccount icon={deleteIcon} name="Delete Health ID" color="#E21717" buttontype1={'Permanent Delete ABHA Number'} buttontype2={'Delete MedXperts Profile'} onClickButton1={handleDelete}
                            // onClickButton2={handleDeleteProfile}
                            onClickButton2={() => navigate('/deleteprofile')}
                        />
                    </Paper>
                </Box>

                <Box sx={{ display: { xs: 'block', md: 'flex' }, alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                    <Typography fontWeight={500} fontSize={14}>About Us</Typography>
                    <Typography fontWeight={500} fontSize={14}>Data Security & Privacy</Typography>
                    <Typography fontWeight={500} fontSize={14}>Terms & Conditions</Typography>
                    <Typography fontWeight={500} color={'#0014FF'} fontSize={14}>Report Issue</Typography>
                    <Typography fontWeight={500} fontSize={14} >Made in India</Typography>
                </Box>

                <CreateConsentPin open={createPin} handleClose={handleCloseConsentPin} mobiledata={mobiledata} />
                <ResetConsentPage open={resetPin} handleClose={handleCloseResetPin} mobiledata={mobiledata} />
                <UpdatePassword open={resetopen} setOpen={setResetopen} />
                <DelinkHealthId open={delinkopen} setOpen={setDelinkopen} />
                <DeleteHealthId open={deleteopen} setOpen={setDeleteopen} />
                {/* <RequestDeleteProfile open={deleteprofile} setOpen={setDeleteprofile} /> */}
            </Box>

        </Box >
    );
}

export default ProfileSettings;
